import React, { useState,useContext  } from 'react';
import axios from "axios";
// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Grid';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';


import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Authentication } from '../../App'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// function createData(
//     name: string,
//     calories: number,
//     fat: number,
//     carbs: number,
//     protein: number,
// ) {
// return { name, calories, fat, carbs, protein };
// }

// const rows = [
// createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
// createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
// createData('Eclair', 262, 16.0, 24, 6.0),
// createData('Cupcake', 305, 3.7, 67, 4.3),
// createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


// class NewsTable extends Component {
    // ClassicEditor
    // .create( document.querySelector( '#editor' ), {
    //     plugins: [  ImageInsert ],
    //     toolbar: [ 'insertImage' ]
    // } )
    // .then( editor => {
	// 	console.log( 'Editor was initialized', editor );
	// } )
	// .catch( error => {
	// 	console.error( error.stack );
	// } );
// type Props = {
//     Contents: boolean;
// };
function NewsContents(props){
    // const authentication = useContext(Authentication)
// const NewsTable = (props: Props) => {
    class MyUploadAdapter {
        constructor( loader ) {
            this.loader = loader;
        }
        upload() {
            return this.loader.file
                .then( async(file) => {
                    var formData = new FormData();
                    // var url = 'http://localhost:5000/v1/recruiting/uploadimg';
                    var url = process.env.REACT_APP_API_OPENROOM_EX_URL + '/v1/recruiting/uploadimg';
                    formData.append('file', file)
                    let res = await axios.post(url,formData).then((response) => {
                        console.log(response.data,"newslist")
                        return response.data;
                        
                      });
                    // var res = await fetch(url, {
                //     method: 'post',
                //     // body: JSON.stringify(values),
                //     body: formData,
                // })
                // .then(r => r)
                //     .then(r => res = r)
                //     .catch(error => res =  error);
                if(res){
                    console.log(res.url,"ある？？？")
                    console.log(res,"ある？？？")
                    console.log(res.data,"ある？？？")
                    return {default:res.url};
                }
                });
        }
        abort() {
            if ( this.xhr ) {
                this.xhr.abort();
            }
        }
        // _initRequest( resolve, reject, file ) {
        //     const xhr = this.xhr = new XMLHttpRequest();
        //     xhr.open( 'POST', process.env.REACT_APP_API_OPENROOM_EX_URL+'/v1/recruiting/uploadimg', true );
        //     xhr.responseType = 'json';
        //     this._initListeners( resolve, reject, file )
        // }
    
        // // Initializes XMLHttpRequest listeners.
        // _initListeners( resolve, reject, file ) {
        //     const xhr = this.xhr;
        //     console.log(xhr,"最短のXHR")
        //     const loader = this.loader;
        //     const genericErrorText = `アップロード失敗: ${ file.name }.`;
        //     xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        //     xhr.addEventListener( 'abort', () => reject() );
        //     xhr.addEventListener( 'load', () => {
        //         const response = xhr.response;
        //         if ( !response || response.error ) {
        //             return reject( response && response.error ? response.error.message : genericErrorText );
        //         }
        //         resolve( {
        //             default: response.url
        //         } );
        //     } );
        //     if ( xhr.upload ) {
        //         xhr.upload.addEventListener( 'progress', evt => {
        //             if ( evt.lengthComputable ) {
        //                 loader.uploadTotal = evt.total;
        //                 loader.uploaded = evt.loaded;
        //             }
        //         } );
        //     }
        // }
        // _sendRequest( file ) {
        //     const data = new FormData();
        //     data.append( 'upload', file );
        //     this.xhr.send( data );
        // }
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loading, setLoading] = useState(false);//保存中になるボタン

    const [editnewstitle, setEditNewsTitle] = useState('');
    const [editnewsdate, setEditNewsDate] = useState('');
    const [valueCategory, setValueCategory] = useState(0);//カテゴリーのonChange
    const [valueWebOpen, setValueWebOpen] = useState(0);//非公開のonChange
    const [editContent, setEditContent] = useState('');//内容入れる
    const [editCreatedAt, setEditCreatedAt] = useState('');
    const [editCreatedUser, setEditCreatedUser] = useState('');
    const [editUpdatedAt, setEditUpdatedAt] = useState('');
    const [editUpdatedUser, setEditUpdatedUser] = useState('');


    const isDelete =async(data) =>{
        console.log(data)
        // var url = 'http://localhost:5000/v1/recruiting/getDelete/'+ props.Contents.id;
        var url = process.env.REACT_APP_API_OPENROOM_EX_URL + '/v1/recruiting/getDelete/' + props.Contents.id;
        let res = await axios.get(url).then((response) => {
            console.log("ssss")
            // setEditNewsTitle("")
            // setEditNewsDate("")
            // setValueCategory("")
            // setValueWebOpen("")
            // setEditContent("")
            // setEditCreatedAt("")
            // setEditCreatedUser( "")
            // setEditUpdatedAt("")
            // setEditUpdatedUser("")
            props.set_delete(true)
            handleClose()
            return response.data;
        });
        
    }



    // const [content, setContentDate] = useState();//非公開のonChange

    const newstitleEdit = (data) =>{//titleの入力を受け取る
        console.log(data)
        setEditNewsTitle(data)
        
        console.log(props.Contents)
    }

    const convertFormatForDateField = (date) => { //日付
        console.log(date)
        setEditNewsDate(date)
    };

    const handleChangeCategory = (event) => {//カテゴリーの入力を受け取る
        console.log(event.target.value,"category")
        setValueCategory(event.target.value);
    };
    const handleChangeWebOpen = (event) => {//webopenの入力を受け取る
        setValueWebOpen(event.target.value);
        console.log(event.target.value,editnewsdate,"aaaa")
        if(event.target.value == 1 && editnewsdate == ''){
            let date = new Date()
            var nYear = date.getFullYear();
            var nMonth = date.getMonth()+1;
            var nDate = date.getDate();
            setEditNewsDate(nYear+'-'+(nMonth < 10? +'0'+String(nMonth):nMonth)+'-'+(nDate < 10? +'0'+String(nDate):nDate))
            console.log("bbbb")
        //     var a = "<img src='" + "https://img.global-center.co.jp/v2/tatemono/2846068/1/1/520/780/1" + "' alt='' />";
        //     var b =  editContent + a;
        //    setEditContent(b)
        }else{
            setEditNewsDate('')
        }
    };
    function MyCustomUploadAdapterPlugin( editor ) {
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            // Configure the URL to the upload script in your back-end here!
            return new MyUploadAdapter( loader );
        };
    }
    // const ContentEdit = (event,editor) => {//webopenの入力を受け取る
    //     const content = editor.getData();
    //     console.log( { event, editor, content },"あいうえお" );
    //     console.log( content ,"内容" );
    //     setEditContent(editor.getData())
    // };


   

    const saveEditingShanaiData = async () => {
        setLoading(true);
        console.log(props.Contents.id)

        if(props.Contents.id){
            setLoading(true);

            let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/updataNewsContents";
            await axios.post(url,{
            data: {
                id: props.Contents.id,
                title: editnewstitle,
                opened_at: editnewsdate,
                category: valueCategory,
                web_open: valueWebOpen,
                contents: editContent,
                // created_at: editCreatedAt,
                // created_user: editCreatedUser,
                updated_at: editUpdatedAt,
                updated_user: editUpdatedUser,
            },
            }).then(async(abc) => {
            // setShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
            // setEditShanaiData(JSONparse(JSON.stringify(abc.data[0])));
            console.log(abc.data,"abc")
            await props.updateList(JSON.parse(JSON.stringify(abc.data)))
            let result = abc.data.filter( x =>{
                return x.id === props.Contents.id
            } )
            console.log(result[0],"result")

            props.Contents.title = result[0].title
            props.Contents.opened_at = result[0].opened_at
            props.Contents.category = result[0].category
            props.Contents.web_open = result[0].web_open 
            props.Contents.contents = result[0].contents
            props.Contents.created_at = result[0].created_at
            props.Contents.created_user = result[0].created_user
            props.Contents.updated_at = result[0].updated_at
            props.Contents.updated_user = result[0].updated_user

            setLoading(false)
            });
            
        }else{
            setLoading(true);

            let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/insertNewsContents";
            console.log(url)
            console.log({
                id:"",
                title: editnewstitle,
                opened_at: editnewsdate,
                category: valueCategory,
                web_open: valueWebOpen,
                contents: editContent,
                created_at: editCreatedAt,
                created_user: editCreatedUser,
                // updated_at: editUpdatedAt,
                // updated_user: editUpdatedUser,
            })
            await axios.post(url,{
            data: {
                id:"",
                title: editnewstitle,
                opened_at: editnewsdate,
                category: valueCategory,
                web_open: valueWebOpen,
                contents: editContent,
                created_at: editCreatedAt,
                created_user: editCreatedUser,
                // updated_at: editUpdatedAt,
                // updated_user: editUpdatedUser,
            },
            }).then(async (def) => {
                console.log(def)
            // setShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
            // setEditShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
            await props.updateList(def.data)
            let result = def.data.filter( x =>{
                return x.id === props.Contents.id
            } )
            result = result.length ? result : [def.data[0]]
            if(result[0]){
                props.Contents.title = result[0].title
                props.Contents.opened_at = result[0].opened_at
                props.Contents.category = result[0].category
                props.Contents.web_open = result[0].web_open 
                props.Contents.contents = result[0].contents
                props.Contents.created_at = result[0].created_at
                props.Contents.created_user = result[0].created_user
                props.Contents.updated_at = result[0].updated_at
                props.Contents.updated_user = result[0].updated_user
            }
            setLoading(false)
            });

        }

        // let saiyou_news = {
        //     id:'', 
        //     title:editnewstitle, 
        //     contents:editContent,
        //     web_open:valueWebOpen, 
        //     category:valueCategory, 
        //     opened_at:editnewsdate,
        // }
    }

    React.useEffect(() => {
        if(props.Contents){//編集で記事を読み取りしたとき これのせいで新しいのが反映されてない！？！？
            setEditNewsTitle(props.Contents.title === null ? '': props.Contents.title)
            setEditNewsDate(props.Contents.opened_at === null ?'':props.Contents.opened_at)
            setValueCategory(props.Contents.category === null ? '': props.Contents.category)
            setValueWebOpen(props.Contents.web_open === null ? '': props.Contents.web_open)
            setEditContent(props.Contents.contents === null ? '': props.Contents.contents)
            setEditCreatedAt(props.Contents.created_at === null ? '': props.Contents.created_at)
            setEditCreatedUser(props.Contents.created_user === null ? '': props.Contents.created_user)
            setEditUpdatedAt(props.Contents.updated_at === null ? '': props.Contents.updated_at)
            setEditUpdatedUser(props.Contents.updated_user === null ? '': props.Contents.updated_user)
        }else{
            
        }
      }, [props]);

    
    // render() {

        // console.log(this.props.mail_details.bodyUtf8);

    return (
        <div id="tcblog" style={{}}>
            <div className='titlebox'>
                <TextField
                    fullWidth
                    label="タイトル"
                    name='newsTitle'
                    id="outlined-start-adornment"
                    sx={{ m: 1 }}
                    value={editnewstitle}
                    onChange={(e) => {newstitleEdit(e.target.value)}}
                />
                <div className='top_buttons'>
                    <TextField  
                        style={{marginTop:"10px",width:"200px",}}
                        type="date"
                        label="公開日"
                        value={editnewsdate ? editnewsdate.substr(0, 10) : ""}
                        // value={editnewsdate}
                        onChange={(e) => convertFormatForDateField(e.target.value)}
                        // defaultValue="2022-01-01"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl style={{margin:"10px 0 10px 20px",textAlign:"left"}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">カテゴリー</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="category"
                            name="row-radio-buttons-group"
                            defaultValue='0'
                            onChange={handleChangeCategory}
                            value={valueCategory}
                        >
                            <FormControlLabel value='0' control={<Radio size="small"/>} label="採用イベント" />
                            <FormControlLabel value='1' control={<Radio size="small"/>} label="コラム" />
                        </RadioGroup>
                    </FormControl>

                    <FormControl style={{margin:"10px 0 10px 20px",textAlign:"left"}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">サイト</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="web_open"
                            name="row-radio-buttons-group"
                            defaultValue='0'
                            onChange={handleChangeWebOpen}
                            value={valueWebOpen}
                        >
                            <FormControlLabel value='0' control={<Radio size="small"/>} label="非公開" />
                            <FormControlLabel value='1' control={<Radio size="small"/>} label="公開" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <CKEditor
                editor={ ClassicEditor }
                data={editContent}
                config={{
                    extraPlugins: [ MyCustomUploadAdapterPlugin ],
                }}
                // value={editContent}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                } }
                // onChange={(event, editor) => {ContentEdit(event, editor)}}
                onChange={ ( event, editor ) => {
                    console.log( editor.getData() ,"内容" );
                    setEditContent(editor.getData());
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor ,"かきくけこ");
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor ,"さしすせそ");
                } }
            />

            <div className='savebox' style={{paddingBottom:'50px',}}>
                <Button onClick={handleOpen} variant="contained" style={{marginRight:'10px',}} color="error" sx={{width:'80px'}}>削除</Button>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="success" sx={{width:'80px'}}>保存</LoadingButton>
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {editnewstitle}を削除しますか？
                    </Typography>
                    <div style={{marginLeft:'auto',textAlign: 'right',paddingTop:"30px",}}>
                        <Button onClick={isDelete} variant="contained" style={{marginRight:'10px',}} color="error" >削除する</Button>
                        <Button onClick={handleClose} variant="contained" color="inherit" >キャンセル</Button>
                    </div>
                </Box>
            </Modal>
            
            <style jsx="true">{
                `
                .top_buttons{
                    text-align:left;
                }
                .ck-editor__editable {
                    min-height: 500px;
                }
                .savebox{
                    padding-top:20px;
                    text-align:right;
                }

                `
            }
            </style>
        </div>
    );
    // }
};
// root.render(<HelloMessage name="Taylor" />);
export default NewsContents;




