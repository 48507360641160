import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
// import AdUnitsIcon from '@mui/icons-material/AdUnits';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

const style = {
  width: '60px',
  maxWidth: 60,
  // bgcolor: 'background.paper',
};


const Soumu = () => (
    <List sx={style} component="nav" aria-label="mailbox folders">
          <ListItem button style={{ padding: "8px 5px" }}>
            <Link style={{ alignItems: 'center', width: '100%', display: 'flex',  flexFlow: 'column', decoration: 'none' ,textDecoration: 'none' }} to="/vendor" underline="none">
              <PeopleAltIcon style={{ width: '30px', height: '30px', color: '#fff' }} />
              <span style={{ paddingTop: '3px', fontSize: '10px', color: '#fff' }}>業者</span>
            </Link>
          </ListItem>
          <Divider />
          {/* <ListItem button divider style={{ padding: "8px 5px" }}>
            <Link style={{ alignItems: 'center', width: '100%', display: 'flex',  flexFlow: 'column', decoration: 'none' ,textDecoration: 'none' }} to="/RecruitEarnings" underline="none">
              <BarChartIcon size="large" style={{ width: '30px', height: '30px', color: '#fff' }} />
              <span style={{ paddingTop: '3px', fontSize: '10px', color: '#fff' }}>売上データ</span>
            </Link>
          </ListItem> */}
          {/* <ListItem button>
          <Link className='btnbox' to="/RecruitLine" underline="none">
            <AdUnitsIcon size="large" className='listicon'/>
            <span className='btntext'>LINE</span>
          </Link>
          </ListItem>
          <Divider /> */}
          {/* <ListItem button divider style={{ padding: "8px 5px" }}>
            <Link style={{ alignItems: 'center', width: '100%', display: 'flex', flexFlow: 'column', decoration: 'none' ,textDecoration: 'none' }} to="/RecruitNews" underline="none">
              <NewspaperIcon size="large" style={{ width: '30px', height: '30px', color: '#fff' }} />
              <span style={{ paddingTop: '3px', fontSize: '10px', color: '#fff' }}>NEWS</span>
            </Link>
          </ListItem> */}
        </List>
);

// const Kanri = () => (
//     <List sx={style} component="nav" aria-label="mailbox folders">
//         <ListItem button style={{ padding: "8px 5px" }}>
//             <Link style={{ alignItems: 'center', width: '100%', display: 'flex', flexFlow: 'column', decoration: 'none' ,textDecoration: 'none' }} to="/Reaction" underline="none">
//               <AnalyticsIcon size="large" style={{ width: '30px', height: '30px', color: '#fff' }} />
//               <span style={{ paddingTop: '3px', fontSize: '10px', color: '#fff' }}>反響管理</span>
//             </Link>
//           </ListItem>
//     </List>
// );
// const Bunseki = () => (
//     <List sx={style} component="nav" aria-label="mailbox folders">
//         <ListItem button divider style={{ padding: "8px 5px" }}>
//             <Link style={{ alignItems: 'center', width: '100%', display: 'flex', flexFlow: 'column', decoration: 'none' ,textDecoration: 'none' }} to="/Analysis" underline="none">
//               <PieChartOutlineIcon size="large" style={{ width: '30px', height: '30px', color: '#fff' }} />
//               <span style={{ paddingTop: '3px', fontSize: '10px', color: '#fff' }}>分析</span>
//             </Link>
//         </ListItem>
//     </List>
// );
function LeftComponents() {
    const location = useLocation();
  return (
    <div className="left" style={{
      width: '60px',
      height: '100%',
      margin: 0,
      padding: 0,
      position: 'fixed',
      top: '50px',
      zIndex: 1000,
      backgroundColor: '#6d94b5'
    }}>
      <div id="aside" >
        {
        // location.pathname.indexOf('Reaction') === -1 && location.pathname.indexOf('Analysis') === -1 ? //一旦パスで指定してる。
        <Soumu/>
        // :
        // location.pathname.indexOf('Analysis') === -1 ?
        // <Kanri/>:
        // <Bunseki/>
        }
      </div >
      {/* <Route path='/Recruit' component={Recruit} /> */}
      <style jsx="true">{
        `
      
        @media screen and (max-width: 768px) {
          .left{
            display:none;
          }
        }
     
       
        `
      }
      </style>
    </div >
  );
  // };
};

export default LeftComponents;
