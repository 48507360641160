import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';

// import logo from './logo.svg';
import './Css/ContentList.css';



type Post = {
  date: Date;
  userId: number;
  id: number;
  title: string;
  body: string;
};

function ContentList() {
  const [posts, setPosts] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();

  const [pageSize, setPageSize] = useState<number>(30);

  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  // const [checked, setChecked] = useState<Boolean>
  const defaultChecked = true;

  // 左サイドに固定されている列の幅
  var header_width1 = 0;
  var header_width2 = 0;
  var cell_width1 = 0;
  var cell_width2 = 0;
  

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(
        'https://jsonplaceholder.typicode.com/posts'
      );
      const data = await response.json();
      setPosts(data);
    };

    getPosts();
  }, []);

  // テーブルセルのカスタマイズ
  const columns = [
    // columnHelper.accessor((row) => `${row.userId} ${row.id}`, {id: 'WID'}),   //  複数列をまとめる場合
    columnHelper.accessor('id', {
      header: () => '掲載',
      enableResizing: false,  //  列幅固定
      size: 120,
      cell: (props) => (
        // <button onClick={() => update(props.row.original.id)}>
        <FormControlLabel
          value={props.getValue()}
          control={<Checkbox sx={{'&.Mui-checked': {color: '#F50057'}}} defaultChecked={defaultChecked} />}
          // control={<Checkbox sx={{'&.Mui-checked': {color: '#F50057'}}} checked={checked} defaultChecked={defaultChecked} onChange={(e) => {setChecked(e.target.value)}} />}
          label={props.getValue().toString()}
          labelPlacement='start'
        />
        
      ),
    }),
    columnHelper.accessor('date', {
      header: 'date',
      enableResizing: false,  //  列幅固定
      size: 90,
      cell: () => (
        <span style={{fontSize: 14}}>2024/01</span>
      ),
    }),
    columnHelper.accessor('userId', {
      header: 'User ID',
      enableResizing: false,  //  列幅固定
      size: 90,
    }),
    columnHelper.accessor('title', {
      header: '物件名',
      cell: (props) => props.getValue().toUpperCase(),
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      // enableSorting: false, // 列のソート無効
      size: 500,
      // maxSize: 900,
    }),
    columnHelper.accessor('body', {
      header: () => '住所',
      enableSorting: false, // 列のソート無効
      size: 700,
      // maxSize: 1000,
    }),
    // columnHelper.display({
    //   id: 'update',
    //   header: '更新',
    //   cell: (props) => (
    //     // <button onClick={() => update(props.row.original.id)}>
    //     <button>
    //       更新
    //     </button>
    //   ),
    // }),
    // columnHelper.display({
    //   id: 'delete',
    //   header: () => '削除',
    //   cell: (props) => (
    //     // <button onClick={() => delete(props.row.original.id)}>
    //     <button>
    //       削除
    //     </button>
    //   ),
    // }),


    // {
    //   accessorKey: 'userId', //  header: の設定がなかったら、これがヘッダーに表示される。
    //   header: 'User ID'     //  ヘッダー名の変更(関数、HTMLも利用可)
    // },
    // {
    //   accessorKey: 'id',
    //   header: () => <span>ID</span>
    // },
    // {
    //   accessorKey: 'title',
    //   header: 'Title',
    //   // cell: (props) =>props.getValue().toUpperCase(),
    // },
    // {
    //   accessorKey: 'body',
    //   header: 'Body'
    // },
  ];

  const table = useReactTable({
    data: posts,
    columns: columns,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    // initialState: {
    //   pagination: {
    //     pageSize: 30,   //  表示数はここで変更
    //   }
    // }
  });

  function headerLeft1(size) {
    header_width1 = size;
    return 0;
  }
  function headerLeft2(size) {
    header_width2 = size;
    return header_width1;
  }
  function headerLeft3() {
    return header_width1 + header_width2;
  }

  function cellLeft1(size) {
    cell_width1 = size;
    return 0;
  }
  function cellLeft2(size) {
    cell_width2 = size;
    return cell_width1;
  }
  function cellLeft3() {
    return cell_width1 + cell_width2;
  }

  return (
    <div style={{ width: 700, margin: '2em'}}>
      <div style={{ display: 'flex', marginBottom: '1em', justifyContent: 'right' }}>
        <div>表示件数</div>
        <select
          value={pageSize}
          onChange={(e) => {
            table.setPageSize(parseInt(e.target.value));
            setPageSize(parseInt(e.target.value));
          }}
        >
          <option value={10}>10</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <div style={{marginLeft: '1em'}}>page Count:{table.getPageCount()}</div>
      </div>
      <div style={{textAlign: 'right'}}><span style={{fontSize: 20, fontWeight: 'bold'}}>{posts.length}</span>件</div>
      <div style={{width: 700, overflow: 'auto'}}>
      <table className='contentList'
        // width={"100%"}
        style={{borderSpacing: 0, width: 'max-content', height: '300px'}}
      >
       <thead>
         {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                {...{
                  key: header.id,
                  // colSpan: header.colSpan,
                  style: {
                    fontSize: 14,
                    width: header.getSize(),  // 列幅を反映
                    // ***date列とuserId列とid列を左に固定***
                    position: (header.id === "id" || header.id === "date" || header.id === "userId") ? 'sticky' : 'relative',
                    left: header.id === "id" ? headerLeft1(header.getSize()) : header.id === "date" ? headerLeft2(header.getSize()) : header.id === "userId" ? headerLeft3() : '',
                    backgroundColor: (header.id === "id" || header.id === "date" || header.id === "userId") ? 'white' : 'none',
                    zIndex: (header.id === "id" || header.id === "date" || header.id === "userId") ? 2 : 1,
                    // *****************************
                  },
                }}
                  // key={header.id}
                  // // onClick={header.column.getToggleSortingHandler()} // 並び替えに必要
                  // onMouseDown={header.getResizeHandler()}
                  // onTouchStart={header.getResizeHandler()}
                  // style={{
                  //   // ***userId列とid列を左に固定***
                  //   position: (header.id === "userId" || header.id === "id") ? 'sticky' : 'unset',
                  //   left: header.id === "userId" ? 0 : header.id === "id" ? header.getSize() : '',
                  //   backgroundColor: 'white',
                  //   // *****************************
                  //   width: header.getSize(),  // 列幅を反映
                  //   border: '1px solid lightgray',
                  // }}
                  // // className={header.column.getIsResizing() ? "resizer is-resizing" : "resizer"}
                  // // className={header.column.getIsPinned() + 'pinned'}
                  // draggable={!table.getState().columnSizingInfo.isResizingColumn}
                >
                {/* {header.column.getIsResizing().toString()}
                {header.getSize()} */}

                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  
                  
                  <div
                    {...{
                      onDoubleClick: () => header.column.resetSize(),
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      className: header.column.getCanResize() ? `resizer ${
                        header.column.getIsResizing() ? 'isResizing' : ''
                      }` : 'noResize',
                    // className: `resizer ${
                      //   header.column.getIsResizing() ? 'isResizing' : ''
                      // }`,
                    }}
                  />
                  {header.column.getCanSort() ? <button onClick={header.column.getToggleSortingHandler()}>
                  sort
                  {{
                    asc: '▲',   // 昇順
                    desc: '▼',  // 降順
                    // resizer: header.column.getIsResizing() ? 'is-resizing' : '',
                  }
                    // 並び替えが行われてない場合
                    [header.column.getIsSorted() as string] ?? ''
                  }
                  </button> : "" }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {/* sliceで表示する行数の設定
          {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  {...{
                    key: cell.id,
                    style: {
                      width: cell.column.getSize(),
                      // ***userId列とid列を左に固定***
                      position: cell.id.includes("id") || cell.id.includes("date") || cell.id.includes("userId") ? 'sticky' : 'unset',
                      left: cell.id.includes("_id") ? cellLeft1(cell.column.getSize()) : cell.id.includes("_date") ? cellLeft2(cell.column.getSize()) : cell.id.includes("_userId") ? cellLeft3() : '',
                      backgroundColor: 'white',
                      // *****************************
                    },
                  }}
                >
                  {/* {'*****' + cell.column.getSize() + '　'}
                  {'*****'+cell.id} */}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <div style={{ display: 'flex', marginTop: '1em', marginBottom: '1em', alignItems: 'center', justifyContent: 'center' }}>
        <button
          // “Table API”のgetCanPreviousPageで前ページが存在するのかどうか。
          disabled={!table.getCanPreviousPage()}
          // Pagenation“Table API”のpreviousPage
          onClick={() => table.previousPage()}>前へ
        </button>
        {Array.from({ length: table.getPageCount() }, (_, i) => i).map(
        (index) => (
          <div
            key={index}
            style={{
              //***現在ページのスタイル***
              //pageIndex へのアクセスは table.getState().pagination.pageIndex を利用
              backgroundColor: table.getState().pagination.pageIndex === index ? 'cyan' : '',
              color: table.getState().pagination.pageIndex === index ? 'white' : 'black',
              //**************************
              padding: '0 0.5em 0 0.5em',
              margin: '0 0.2em 0 0.2em',
              cursor: 'pointer',
            }}
            onClick={() => table.setPageIndex(index)}
          >
            {index + 1}
          </div>
        )
        )}
        <button
          disabled={!table.getCanNextPage()}
          // “Table API”のgetCanNextPageで次ページが存在するのかどうか。
          onClick={() => table.nextPage()}>次へ
        </button>
      </div>
    </div>

    // <div style={{ margin: '2em' }}>
    //   <h1>Posts List</h1>
    //   <table>
    //     <thead>
    //       {table.getHeaderGroups().map((headerGroup) => (
    //         <tr key={headerGroup.id}>
    //           {headerGroup.headers.map((header) => (
    //             <th key={header.id}>
    //               {flexRender(
    //                 header.column.columnDef.header,
    //                 header.getContext()
    //               )}
    //             </th>
    //           ))}
    //         </tr>
    //       ))}
    //     </thead>
    //     <tbody>
    //       {/* sliceで表示する行数の設定
    //       {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
    //       {table.getRowModel().rows.map((row) => (
    //         <tr key={row.id}>
    //           {row.getVisibleCells().map((cell) => (
    //             <td key={cell.id}>
    //               {flexRender(cell.column.columnDef.cell, cell.getContext())}
    //             </td>
    //           ))}
    //         </tr>
    //       ))}
    //     </tbody>
    //   </table>
    // </div>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default ContentList;
