import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';

import SearchList from './SearchList.tsx';
import ContentList from './ContentList.tsx';
import { Container, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja'




function RemittanceStatement(){
  const today = new Date();
  const [Dates1, setDates1] = useState(today);
  const [Dates2, setDates2] = useState(today);
  const [Dates3, setDates3] = useState(today);

  return (
    <Box className="App" id="content">
      <Grid container columns={12} gap={2} direction="row" justifyContent="center" alignItems="stretch" className="container">
        <Grid item xs={12} className="tab-list">
          <Grid container xs={12} sx={{ml: 3, mt: 5, mb: 5}}>
            <Grid item xs={6} sx={{textAlign: 'left'}}>
              <TextField
                label="建物名"
                name="strong_points"
                fullWidth
                size='small'
              />
            </Grid>
            <Button variant="contained" sx={{backgroundColor: '#3F51B5', ml: 2}}>内容表示</Button>
            <Button variant="contained" sx={{backgroundColor: '#3F51B5', ml: 2}}>入金画面</Button>
            <Button variant="contained" sx={{backgroundColor: '#3F51B5', ml: 2}}>送金書出力</Button>
            <Button variant="contained" sx={{backgroundColor: '#3F51B5', ml: 2}}>請求書</Button>
          </Grid>
          <Container sx={{mt: 5, mb: 5, pr: 5, alignItems: 'center', display: 'flex'}}>
            <Box sx={{ display: 'flex' }}>
              {/* <Typography sx={{ mt: 'auto', mb: 'auto', width: 200 }}>登録日</Typography> */}
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                <DatePicker
                  // label="変更日"
                  value={Dates1}
                  onChange={(e) => { setDates1(e) }}
                  inputFormat='yyyy年MM月dd日'
                  mask='____年__月__日'
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </LocalizationProvider>
              <Typography sx={{alignSelf: 'center'}}>～</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                <DatePicker
                  // label="変更日"
                  value={Dates2}
                  onChange={(e) => { setDates2(e) }}
                  inputFormat='yyyy年MM月dd日'
                  mask='____年__月__日'
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </LocalizationProvider>
              <Button variant="contained" sx={{backgroundColor: '#5CB85C', ml: 2, mt: 2, mr: 5, mb: 2}}>CSV</Button>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                <DatePicker
                  label="送金日(印刷用)"
                  value={Dates3}
                  onChange={(e) => { setDates3(e) }}
                  inputFormat='yyyy年MM月dd日'
                  mask='____年__月__日'
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              {/* <Typography sx={{alignSelf: 'center'}}>備考：</Typography> */}
              <TextField
                id="outlined-multiline-static"
                label="備考"
                multiline
                rows={4}
                // defaultValue="Default Value"
                sx={{ml: 3}}
              />
            </Box>
          </Container>
        </Grid>

        <Grid item xs={12} className="tab-list" sx={{display: 'flex'}}>
          <SearchList/>
          <ContentList/>
        </Grid>

      </Grid>


      <style jsx="true">{
        `
        @media screen and (max-width: 768px){
          .css-quj9j6-MuiTable-root {
            min-width: 0;
          }
        }
        body {
          background: #E9F2F8;
          color: #292f33;
          font-family: 'Lucida Grande','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',Meiryo,メイリオ, sans-serif;
        }
        .GADbox{
          margin: 0 auto;
        }
        .top_box{
          text-align: left;
          padding-bottom:20px;
        }
        .tab-list {
          padding:20px !important;
          background-color: #fff;
          border-radius: 5px;
          margin:0;
        }
        .NewsTitle{
          text-align: left;
          padding-right: 20px;
          font-size:15px;
          font-weight: bold;
        }
        .Table{
          font-size:15px;
        }
        
        .container {
          padding: 0px 20px;
          max-width: 1260px;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 20px;
        }
        
        .news_table{
          background-color:#fff;
          paddnig: 15px !important;
        }

        .news_container{
          background-color:#fff;
          paddnig: 15px !important;
        }

        .css-n3lm0w-MuiContainer-root{
          padding-left: 10px;
          padding-right: 10px;
        }
        .css-hoosu5-MuiContainer-root {
          padding-left: 10px;
          padding-right: 10px;
        }

        `
      }
      </style>
    </Box>
  );
};

export default RemittanceStatement;
