import React from 'react';
import { useMsal } from '@azure/msal-react';
import { LogLevel } from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError, InteractionStatus, } from '@azure/msal-browser';

const initialize ={
    instance: 'https://homestation.b2clogin.com/',
    domain:'homestation.b2clogin.com',
    tenant: 'homestation.onmicrosoft.com',
    signInPolicy: 'B2C_1_homestation_signin',
    resetPolicy: 'B2C_1_homestation_password_reset',
    applicationId: '8e13a77e-99fd-432d-bed1-bc80e76c7900',
    cacheLocation: 'sessionStorage',
    scopes: ['https://homestation.onmicrosoft.com/api/user_impersonation'],
    redirectUri: process.env.REACT_APP_BASE_URL,
}
///globalcenterowners.b2clogin.com/globalcenterowners.onmicrosoft.com/b2c_1_owners_globalcenter/oauth2/v2.0/token 400 (B エラー
export const b2cPolicies = {
    names: {
        signUpSignIn: initialize.signInPolicy,
        forgotPassword: initialize.resetPolicy,
        // editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority:  `${initialize.instance}${initialize.tenant}/${initialize.signInPolicy}` ,
        },
        forgotPassword: {
            authority: `${initialize.instance}${initialize.tenant}/${initialize.resetPolicy}`,
        },
        // editProfile: {
        //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
        // },
    },
    authorityDomain: initialize.domain,
    scopes:initialize.scopes,
};
export const msalConfig = {
    auth: {
        clientId: initialize.applicationId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_BASE_URL,
        postLogoutRedirectUri: process.env.REACT_APP_BASE_URL,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
export const getAcount = () => {
    // const user = .getAcount(b2cPolicies.scopes);
};