import React, {createContext, useState, useContext} from 'react';
// import Layout from '../Layouts/layout';
// import LayoutMobile from '../Layouts/layoutMobile';
import { GetAccessToken } from '../../App'

// import '../styles/layout.css';
// import '../styles/top.css';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';

// // const url = `http://localhost:5000`
// const url = process.env.REACT_APP_API_BASE_URL

const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`

export const UserContext = createContext()

export const IndexComponents = ({ children }) => {

    const { instance, inProgress, accounts } = useMsal();
    const [user_detail, setUserDetail] = React.useState(false);
    const navigate = useNavigate();

    
    React.useEffect(() => {
    // if(accounts && accounts.find(a => a.environment == 'globalcenterowners.b2clogin.com')){
      getUserData()//ログイン情報
    // }
    }, []);  
  
    //通常ログイン時
    const getUserData = async () => {



      var token = await GetAccessToken({ instance, inProgress, accounts }); 

      await fetch(url+`/userdetail`, { 
        method: 'get', 
        // body: JSON.stringify(),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  
      })
      .then(response => response.json())
      .then(data => {
        // alert(data.length)
        setUserDetail(data)
        // console.log("userrrrrrrrrr",data)
        // console.log(data)
      })
      .catch(error => console.error(error));



  
      // var token = await GetAccessToken({ instance, inProgress, accounts }); 
      // if(token){
      //   // const f = await fetch(url + `/v2/tm/owner/detail`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
      //   // const d = await f.json()


      //   let url = "https://orooms.global-center.co.jp/v1/recruiting/user";

      //   // 内容
      //   var f = await fetch(url, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
      //   var d = await f.json()        
      //   // alert(d)
      //   setUserDetail(d)
      //   console.log("userrrrrrrrrr",d)
      //   // setuserSection(d.Tenpo_Name)





    
      //   // setUserDetail(d)
      //   // console.log("owner", d)


      // }
  }

  // const updateUserDetail = (newValue) => {
  //   setUserDetail(newValue);
  // };


    
    return (
      <UserContext.Provider value={{user_detail}}>
          {children}          
      </UserContext.Provider>
    );
}

// export const useAuth = () => {
//   return useContext(UserContext);
// };

