import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Recruit from './Components/Recruit/Index';
import RecruitNews from './Components/RecruitNews/Index';
import RecruitEarnings from './Components/RecruitEarnings/Index';
import RemittanceStatement from './Components/RemittanceStatement/Index';
import Header from './Components/Layout/HeaderComponents';
import Left from './Components/Layout/LeftComponents';
import Zero9 from './Components/Zero9/Index';
import ConvTkBk from './Components/ConvTkBk/Index';
import {IndexComponents} from './Components/Layout/IndexComponents';
import Vendor from './Components/Vendor/Index';
import Attention from './Components/Attention/Index';
import AttentionDetail from './Components/Attention/Detail';
// import Logout from "./pages/logout";
// import PdfView from "./pages/pdfView";
// import { Index } from "./pages/index";
// import { AuthProvider } from './AuthContext';

const Routers = () => {
    return (
        <Router>
                
                <IndexComponents>
                    <Header/>
                    {/* <div style={{display: "flex"}}>
                        <div> */}
                            <Left className="Left" width={60}/>
                        {/* </div>
                        <div style={{flex: 1, boxSizing: "content-box",}}> */}
                            <div style={{marginLeft:"60px", marginTop:"50px"}}>
                            <Routes>

                                <Route path="/" element={<Recruit />} />
                                <Route path="/RecruitNews" element={<RecruitNews />} />
                                <Route path="/RecruitEarnings" element={<RecruitEarnings />} />
                                <Route path="/RemittanceStatement" element={<RemittanceStatement />} />
                                <Route path="/Zero9" element={<Zero9 />} />
                                <Route path="/convtkbk" element={<ConvTkBk />} />
                                <Route path="/Vendor" element={<Vendor />} />
                                <Route path="/Attention" element={<Attention />} />
                                <Route path="/AttentionDetail/:id" element={<AttentionDetail />} />
                            </Routes>
                            </div>
                        {/* </div>
                    </div> */}
                </IndexComponents>
        </Router>
    )
}
export default Routers