import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment, { weekdays } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import ErrorIcon from '@mui/icons-material/Error';


// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };

type Post = {
  id: number;
  date: string;
  name: string;
  kana: string;
  birthday: string;
  remarks: string;
  tel1: string;
  tel2: string;
  mail: string;
  tenpo_name: string;
  nickname: string;
  nikkei_telecom: number;

  
};

function Grid(props) {
  // console.log(props,"props")
  const [posts, setPosts] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();


  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const [sorting, setSorting] = useState<SortingState>([]);


    // 左サイドに固定されている列の幅
    var header_width1 = 0;
    var header_width2 = 0;
    var header_width3 = 0;
    var header_width4 = 0;
    var header_width5 = 0;

    var cell_width1 = 0;
    var cell_width2 = 0;
    var cell_width3 = 0;
    var cell_width4 = 0;
    var cell_width5 = 0;
    var footer_width1 = 0;
    var footer_width2 = 0;
    var footer_width3 = 0;
    var footer_width4 = 0;
    var footer_width5 = 0;
  

  useEffect(() => {
    setPosts(props.data_list)
    // alert(props.data_list.length)
  }, [props.data_list]);

  


  // テーブルセルのカスタマイズ
  const columns = [
    columnHelper.accessor('nikkei_telecom', {
      header: '日経',
      id:'nikkei_telecom',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 50,
      cell: (props) => props.getValue() ? <ErrorIcon /> : "",
    }),
    columnHelper.accessor('date', {
      header: '対応日',
      id:'date',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 140,
      cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),

    // columnHelper.accessor((row) => `${row.Todoufuken}${row.Shikuchoson}${row.Choiki}${row.Other_Address}`, {
    //   header: () => '住所',
    //   id:'Address',
    //   enableSorting: false, // 列のソート無効
    //   size:350,
    //   // maxSize: 1000,
    // }),

    columnHelper.accessor('name', {
      header: '名前',
      id:'name',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 220,
      cell: (props) => (
        <div>
          <div style={{fontSize:12}}>{props.row.original.kana}</div>
          <div>{props.row.original.name}</div>
        </div>
      ),
    }),

    // columnHelper.accessor('kana', {
    //   header: 'カナ',
    //   id:'kana',
    //   // enableResizing: false, 
    //   enableSorting: true, // 列のソート無効
    //   size: 220,
    // }),
    columnHelper.accessor('birthday', {
      header: '生年月日',
      id:'birthday',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 140,
      cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    columnHelper.accessor('remarks', {
      header: '備考',
      id:'remarks',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 550,
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
      cell: (props) => (
        <>
          {/* <div style={{whiteSpace:"nowrap", width:"550px"}}> */}
          {props.row.original.remarks}
          {/* </div> */}
        </>
      ),
    }),

    columnHelper.accessor('tel1', {
      header: 'TEL1',
      id:'tel1',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 150,
      cell: (props) => (
        <div style={{width:150}}>
          <div>{props.row.original.tel1}</div>
          <div>{props.row.original.tel2}</div>
        </div>
      ),
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    // columnHelper.accessor('tel2', {
    //   header: 'TEL2',
    //   id:'tel2',
    //   // enableResizing: false, 
    //   enableSorting: true, // 列のソート無効
    //   size: 150,
    //   // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    // }),
    columnHelper.accessor('mail', {
      header: 'メール',
      id:'mail',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 200,
      cell: (props) => (
        <div style={{width:200}}>
          {props.row.original.mail}
        </div>
      ),
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    // columnHelper.accessor('tenpo_name', {
    //   header: '部署',
    //   id:'tenpo_name',
    //   // enableResizing: false, 
    //   enableSorting: true, // 列のソート無効
    //   size: 150,
    //   // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    // }),
    columnHelper.accessor('nickname', {
      header: '登録者',
      id:'nickname',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 150,
      cell: (props) => (
        <div>
          <div>{props.row.original.tenpo_name}</div>
          <div>{props.row.original.nickname}</div>
        </div>
      ),
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
  


  ];


  const table = useReactTable({
    data: posts,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    // getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    
  });

  function headerLeft1(size) {
    header_width1 = size;
    return 0;
  }
  function headerLeft2(size) {
    header_width2 = size;
    return header_width1;
  }
  function headerLeft3(size) {
    header_width3 = size;
    return header_width1 + header_width2;
  }
  function headerLeft4(size) {
    header_width4 = size;
    return header_width1 + header_width2 + header_width3;
  }
  function headerLeft5(size) {
    header_width5 = size;
    return header_width1 + header_width2 + header_width3 + header_width4;
  }
  function headerLeft6() {
    return header_width1 + header_width2 + header_width3 + header_width4 + header_width5;
  }

  function headerBaseLeft(size) {
    return 0;
  }

  function cellLeft1(size) {
    cell_width1 = size;
    return 0;
  }
  function cellLeft2(size) {
    cell_width2 = size;
    return cell_width1;
  }
  function cellLeft3(size) {
    cell_width3 = size;
    return cell_width1 + cell_width2;
  }
  function cellLeft4(size) {
    cell_width4 = size;
    return cell_width1 + cell_width2 + cell_width3;
  }
  function cellLeft5(size) {
    cell_width5 = size;
    return cell_width1 + cell_width2 + cell_width3 + cell_width4;
  }
  function cellLeft6() {
    return cell_width1 + cell_width2 + cell_width3 + cell_width4 + cell_width5;
  }

  return (
    <div>
      <div style={{textAlign:"right"}}><Chip label={(posts.length?posts.length:0) +"件"} variant="outlined" /></div>
      <div className='tablebox' style={{height: window.innerHeight-180}}>
        
        <div>
        <table className='vendor_list'
          style={{borderSpacing: 0, width:'max-content', }}
        >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th  colSpan={header.colSpan}
                  {...{
                    key: header.id,
                    style: {
                      width: header.getSize(),  // 列幅を反映
                      height:"20px",
                      left: header.id === "1_base_bk_name" ? headerBaseLeft(header.getSize()) : header.id === "bk_name" ? headerLeft1(header.getSize()) : header.id === "hy_no" ? headerLeft2(header.getSize()) : header.id === "kys_name" ? headerLeft3(header.getSize()) : header.id === "ky_date" ? headerLeft4(header.getSize()) : header.id === "kai_ymd" ? headerLeft5(header.getSize()) : header.id === "keirui_name" ? headerLeft6() : '',
                      backgroundColor: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 'white' : 'none',
                      zIndex: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name" || header.id === "base") ? 2 : 1,
                    },
                    
                  }}
                  >
                    
                    <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                      asc: ' ▲',   // 昇順
                      desc: ' ▼',  // 降順
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>

            {table.getRowModel().rows.map((rowValue, rowIndex) => (
              <tr 
                onClick={() => props.handleDetail(rowValue.original)}
                className={rowIndex % 2 === 0 ? "table-row" : 'table-row2'}
                
                {...{
                  key: rowValue.id,
                  style: {
                    
                  },
                }}
              >
                {rowValue.getVisibleCells().map((value, index) => (
                  <td
                  
                    {...{
                      key: value.id,
                      style: {
                        width: value.column.getSize(),
                        // textAlign: (value.id.includes("Tatemono_Name") || value.id.includes("Address"))?"left":"center",
                        // position: value.id.includes("bk_name") || value.id.includes("hy_no") || value.id.includes("kys_name") || value.id.includes("ky_date") || value.id.includes("kai_ymd") || value.id.includes("keirui_name") ? 'sticky' : 'unset',
                        // left: value.id.includes("_bk_name") ? cellLeft1(value.column.getSize()) : value.id.includes("_hy_no") ? cellLeft2(value.column.getSize()) : value.id.includes("_kys_name") ? cellLeft3(value.column.getSize()) : value.id.includes("_ky_date") ? cellLeft4(value.column.getSize()) : value.id.includes("_kai_ymd") ? cellLeft5(value.column.getSize()) : value.id.includes("keirui_name") ? cellLeft6() : '',
                        backgroundColor: table.getRowModel().rows[rowIndex].original.nikkei_telecom == 1 ? '#ffb8b8' : 'white',
                        
                      },

                    }}
                  >
                    {flexRender(value.column.columnDef.cell, value.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
        </div>
      </div>


    </div>


  );
}



export default Grid;
