import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Zero9Grid from './Grid.tsx';
// import Tatemono from './Tatemono';
import TatemonoList from './TatemonoList.tsx';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";


import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;

// const HttpsProxyAgent = require('https-proxy-agent');

// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };
// const url = process.env.REACT_APP_API_PM_URL
// const url = `http://10.0.2.2:8080`

const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Index(props) {
  const { instance, inProgress, accounts } = useMsal();

  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [bk_no, setBkNo] = useState("");
  const [bk_name, setBkName] = useState("");
  const [tatemono_id, setTatemonoId] = useState("");
  const [tatemono_name, setTatemonoName] = useState("");
  const [type, setType] = useState(0);
  
  // const [data_list, setDataList] = useState(null);
  const [tatemono_list, setTatemonoList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(false);//
  

  useEffect(() => {

    // handleSearch(moment().format("YYYY"))
    
  }, []);


  const getData = async () => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/convtkbklist`, { 
      method: 'post', 
      body: JSON.stringify({type:type, bk_no: bk_no, bk_name: bk_name, tatemono_id: tatemono_id, tatemono_name: tatemono_name}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setTatemonoList(data)
      console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }


  const handleSearch = async () => {

    getData()


  }



  return (
    <>

      <Box sx={{textAlign:"left", backgroundColor:"white", p:3}}>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>

            {/* <FormControl style={{margin:"10px 0 10px 20px",textAlign:"left"}}>
              <FormLabel id="demo-row-radio-buttons-group-label">カテゴリー</FormLabel>
              <RadioGroup
                  row
                  aria-labelledby="category"
                  name="row-radio-buttons-group"
                  defaultValue='0'
                  onChange={(e)=>setType(e.target.value)}
                  value={type}
              >
                  <FormControlLabel value='0' control={<Radio size="small"/>} label="賃貸革命ベース" />
                  <FormControlLabel value='1' control={<Radio size="small"/>} label="HSベース" />
              </RadioGroup>
            </FormControl> */}

            {type == 0 && <TextField
              name='bk_no'
              value={bk_no}
              onChange={(e) => setBkNo(e.target.value)}
              label="賃貸革命　物件ID"
              size="small"
              style={{marginLeft:5}}
              inputProps={{
                inputMode: 'numeric', // 数値のみ入力可能にする
                pattern: '[0-9]*'     // 正規表現で数値のみ許可
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  handleSearch()
                }
              }}
            />}
            {type == 0 && <TextField
              name='bk_name'
              value={bk_name}
              onChange={(e) => setBkName(e.target.value)}
              label="賃貸革命　物件名"
              size="small"
              style={{marginLeft:5}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch()
                }
              }}
            />}

            {type == 1 && <TextField
              name='tatemono_id'
              value={tatemono_id}
              onChange={(e) => setTatemonoId(e.target.value)}
              label="HS　物件ID"
              size="small"
              style={{marginLeft:5}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch()
                }
              }}
            />}

            {type == 1 && <TextField
              name='tatemono_name'
              value={tatemono_name}
              onChange={(e) => setTatemonoName(e.target.value)}
              label="HS　物件名"
              size="small"
              style={{marginLeft:5}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch()
                }
              }}
            />}

            <Button variant="contained" sx={{ml:2}} onClick={()=>handleSearch()}>検索</Button>
          </div>

        </div>
      </Box>

      {tatemono_list && <Box sx={{mt: 1, p:2, backgroundColor:"white"}}>
        {loading_disp ? <Loading />: tatemono_list.length > 0 ? <TatemonoList tatemono_list={tatemono_list} /> : "該当データがありません。"}
        
      </Box>}

      {/* {data_list && <Box sx={{mt: 1, p:2, backgroundColor:"white"}}>
        {loading_disp ? <Loading />: <Zero9Grid data_list={data_list} />}
        
      </Box>} */}
    </>


  );
}



export default Index;
