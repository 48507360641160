import React from 'react';
import {useParams,} from 'react-router-dom';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import VendorGrid from './Grid.tsx';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { purple, red, grey, blue, lightBlue, pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Dropzone from 'react-dropzone';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Divider from '@mui/material/Divider';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Chip from '@mui/material/Chip';
import {UserContext,} from '../Layout/IndexComponents'



import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;


const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Detail(props) {
  const { instance, inProgress, accounts } = useMsal();

  const { id } = useParams();

  const { user_detail } = useContext(UserContext);

  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [tatemono_name, setTatemonoName] = useState("");
  const [keiyakusya_name, setKeiyakusyaName] = useState("");
  
  const [data_list, setDataList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(true);//


  const [form, setForm] = useState({count:50, vendor_name:"", taxable:null, tantou_section_id: 0, annai: false});//検索フォーム
  const [section_list, setSectionList] = useState([]);//
  const [user_list, setUserList] = useState([]);//
  const [vendor_list, setVendorList] = useState([]);//
  const [vendor_list_count, setVendorListCount] = useState([]);//
  const [vendor_detail_disp, setVendorDetailDisp] = useState(false);//

  const [delete_disp, setDeleteDisp] = useState(false);//

  const [attention_detail, setAttentionDetail] = useState({id: 0 });//
  const [attention_doc_list, setAttentionDocList] = useState([]);//
  const [doc_delete_disp, setDocDeleteDisp] = useState(0);//
  const [base64, setBase64] = useState([]);
  const [mime_type, setMimeType] = useState([]);
  const [name, setName] = useState([]);
  const [file, setFile] = useState([]);

  const [disp_type, setDispType] = useState(0);//0：初期(新規)、1：window.open、2：dialog
  // const [text, setText] = useState(null);


  // useEffect(() => {

  //   getSectionListData()
  //   getUserListData()
    
  // }, [props.vendor_detail.id]);

  useEffect(() => {
    getSectionListData()
    getUserListData()
  }, [])

  //window.open
  useEffect(() => {

    if(id > 0){
      // alert(id)
      // setApplicationId(id) //アプリケーションIDをセット
      // getApplicationDetail(id) //詳細を取得
      // alert("id:"+id)
      getData(id)
      setDispType(1)
    }
    
  }, [id])

  //ポップアップ
  useEffect(() => {

    if(props.id > 0){
      // alert(id)
      // setApplicationId(id) //アプリケーションIDをセット
      // getApplicationDetail(id) //詳細を取得
      // alert("props.id:"+props.id)
      getData(props.id)
      setDispType(2)

    }else if(props.id == ""){
      //新規登録時
      setLoadingDisp(false)
      setAttentionDetail({id: "",  birthday:null, date: new Date(), kana: "", mail: "", name: "", nikkei_telecom: false, remarks: "", tel1: "", tel2: "", section_id: user_detail.am_section_id, user_id: user_detail.id })
    }

    
    
  }, [props.id])




  const getSectionListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/sectionlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setSectionList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }

  const getUserListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/userlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setUserList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }

  const getData = async (id) => {

    // setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/attentiondetail/` + id, { 
      method: 'get', 
      // body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      if(data.attentions){
        setAttentionDetail(data.attentions)
        setAttentionDocList(data.attention_docs)
      }else{
        setAttentionDetail({})
        setAttentionDocList([])
        setDeleteDisp(false)
      }

    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }


  // const handleSearch = async () => {

  //   getData()

  // }



  const handleDetail = async () => {


    setVendorDetailDisp(false)

  }

  const handleSearchZip = async () => {

    if(!props.vendor_detail.zip || props.vendor_detail.zip == ""){
      alert("郵便番号を入力してください")
      return
    }else{
      
    }
    // setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/adresssearch/` + props.vendor_detail.zip.replace(/-/g, ''), { 
      method: 'get', 
      // body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      if(data.length > 0){
        var newDetail = {...props.vendor_detail, address: data[0].todoufuken_name + data[0].shikuchouson_name + data[0].chouiki_name}
        props.setVendorDetail(newDetail)
      }


      // console.log(data)
    })
    .catch(error => console.error(error));

    // setLoadingDisp(false)
  }

  //詳細変更
  const onChangeAttentionDetailDate = async (value, name) => {
    console.log("aaaaaa",value)
    var newDetail = {...attention_detail, [name]: value == "Invalid Date" ? null : value}
    setAttentionDetail(newDetail)
  }
  const onChangeAttentionDetail = async (e) => {

    if(e.target.name == "nikkei_telecom"){
      var newDetail = {...attention_detail, [e.target.name]: e.target.checked}
      setAttentionDetail(newDetail)

    }else{
      var newDetail = {...attention_detail, [e.target.name]: e.target.value}
      setAttentionDetail(newDetail)
    }
  }
  //担当変更
  const onChangeAttentionDetailUserId = async (e, value) => {

      var newDetail = {...attention_detail, user_id: value ? value.id : 0}
      setAttentionDetail(newDetail)
  
  }

  const onDrop = (files) => {
    console.log(files)
    const file_data = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file_data)
    console.log(file_data.type)
    reader.onload = () => {

        let newFile = [...file]
        newFile.push(file_data)
        setFile(newFile)

        let newBase64 = [...base64]
        newBase64.push(reader.result)
        setBase64(newBase64)

        let newMimeType = [...mime_type]
        newMimeType.push(file_data.type)
        setMimeType(newMimeType)

        let newName = [...name]
        newName.push(file_data.name)
        setName(newName)

    }
};

const deleteFile = (idx) =>{

    setFile((prevDetail) => file.filter((value, index) => (index !== idx)));

    setBase64((prevDetail) => base64.filter((value, index) => (index !== idx)));

    setMimeType((prevDetail) => mime_type.filter((value, index) => (index !== idx)));

    setName((prevDetail) => name.filter((value, index) => (index !== idx)));
}

  //登録ボタン
  const onClickUpdate = async () => {

    if(attention_detail.id == ""){
      onClickInsertAttention()
    }else{
      onClickUpdateAttention()
      
    }

  }

  // //更新
  // const onClickUpdateAttention = async () => {

  //   // alert(room_id)
  //   var token = await GetAccessToken({ instance, inProgress, accounts }); 

  //   await fetch(url+`/attentionupdate`, { 
  //     method: 'post', 
  //     body: JSON.stringify(attention_detail),
  //     headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  //   })
  //   .then(response => response.json())
  //   .then(data => {
      

  //     if (disp_type == 2){
  //       props.setAttentionDetailDisp(false)
  //       props.changeAttentionList(data, attention_detail)

  //     }
      
  //   })
  //   .catch(error => console.error(error));
    
  // }

  //更新
  const onClickUpdateAttention = async() => {


      var formData = new FormData();
      file.forEach(e => {
          formData.append("files", e);
      });
      // alert(attention_detail.birthday)
      // formData.append("name", values.name);
      formData.append("id", attention_detail.id);
      formData.append("birthday", attention_detail.birthday ? (new Date(moment(attention_detail.birthday).format("YYYY-MM-DD"))).toISOString() : "");
      formData.append("date", attention_detail.date ? (new Date(moment(attention_detail.date).format("YYYY-MM-DD"))).toISOString()  : null);
      formData.append("kana", attention_detail.kana);
      formData.append("mail", attention_detail.mail);
      formData.append("name", attention_detail.name);
      formData.append("nikkeitelecom", attention_detail.nikkei_telecom);
      formData.append("remarks", attention_detail.remarks);
      formData.append("tel1", attention_detail.tel1);
      formData.append("tel2", attention_detail.tel2);
      formData.append("sectionid", attention_detail.section_id);
      formData.append("userid", attention_detail.user_id);


      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }


    var token = await GetAccessToken({ instance, inProgress, accounts }); 


      // var f = await fetch(url+`/attentionupdate`, { method: 'post', body: formData, headers: { 'Authorization': 'Bearer ' + token } })
      // var d = await f.json()


    await fetch(url+`/attentionupdate`, { 
      method: 'post', 
      body: formData, 
      headers: { 'Authorization': 'Bearer ' + token }
    })
    .then(response => response.json())
    .then(data => {
      if (disp_type == 2){
        props.setAttentionDetailDisp(false)
        props.changeAttentionList(data, attention_detail)

      }else{
        getData(id)
        setFile([])
        setBase64([])
        setMimeType([])
        setName([])
      }
    })



      // if(props.owner_chat_header_id == 0){
      //     //リストに追加
      //     props.addChatList(d.owner_chat_header_detail)
      // }

      // const newOwnerChatMessages = [...owner_chat_messages];

      // setOwnerChatMessages(newOwnerChatMessages.concat(d.message))
      // // setDetail(d)

      // setMessage("")
      // setMessageType(0)

      // setBase64([]);setMimeType([]);setName([]);setFile([])
      // setText(null)

      // scrollBottom()

      // setSending(false)

  };












  //新規
  const onClickInsertAttention = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    // var newAttentionDetail = {...attention_detail}
    // delete newAttentionDetail["id"]

    var formData = new FormData();
    file.forEach(e => {
        formData.append("files", e);
    });
    // formData.append("name", values.name);
    // alert(attention_detail.birthday ? "1": "2")

    formData.append("birthday", attention_detail.birthday ? (new Date(moment(attention_detail.birthday).format("YYYY-MM-DD"))).toISOString() : "");
    formData.append("date", attention_detail.date ? (new Date(moment(attention_detail.date).format("YYYY-MM-DD"))).toISOString()  : null);
    formData.append("kana", attention_detail.kana);
    formData.append("mail", attention_detail.mail);
    formData.append("name", attention_detail.name);
    formData.append("nikkeitelecom", attention_detail.nikkei_telecom);
    formData.append("remarks", attention_detail.remarks);
    formData.append("tel1", attention_detail.tel1);
    formData.append("tel2", attention_detail.tel2);
    formData.append("sectionid", attention_detail.section_id);
    formData.append("userid", attention_detail.user_id);



    await fetch(url+`/attentioninsert`, { 
      method: 'post', 
      body: formData, 
      headers: { 'Authorization': 'Bearer ' + token },

    })
    .then(response => response.json())
    .then(data => {
      
        props.setAttentionDetailDisp(false)
        props.insertAttentionList(data, attention_detail)
      
    })
    .catch(error => console.error(error));
    
  }  

  //削除ボタン
  const onClickDelete = async () => {


    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/attentiondelete`, { 
      method: 'post', 
      body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      

      if (disp_type == 2){
        props.setAttentionDetailDisp(false)
        props.deleteAttentionList(attention_detail)
        setDeleteDisp(false)

      }else{
        getData(id)
        setFile([])
        setBase64([])
        setMimeType([])
        setName([])
      }
      
    })
    .catch(error => console.error(error));

  }

  //ファイル削除ボタン
  const onClickDocDelete = async () => {

    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/attentiondocdelete/`+doc_delete_disp, { 
      method: 'get', 
      // body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      

      setAttentionDocList((prevDetail) => prevDetail.filter((value, index) => (value.id != doc_delete_disp)));
      setDocDeleteDisp(0)
      
    })
    .catch(error => console.error(error));

  }

  //ダウンロード
  const getAttentionDocFile = async (id, name) => {

    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    const download_url = process.env.REACT_APP_API_CRM_URL + `/v1/attentions/download/`; 
    // const download_url = `http://localhost:5000/v1/attentions/download/`; 

    // await fetch(url+`/attentiondocdownload/`+id, { 
    await fetch(download_url+id, { 
      method: 'get', 
      // body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token },

    })
    .then(response => response.blob())
    .then(data => {
      
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = name;
      // a.download = "10.xlsx";
      a.href = URL.createObjectURL(data);
      // ダウンロード開始
      a.click();

      // setAttentionDocList((prevDetail) => prevDetail.filter((value, index) => (value.id != doc_delete_disp)));
      // setDocDeleteDisp(0)
      
    })
    .catch(error => console.error(error));



      // const response = await fetch(`http://localhost:8080/attentiondocdownload/`+id, { 
      //     method: 'get', 
      //     // body: JSON.stringify(attention_detail),
      //     headers: { 'Authorization': 'Bearer ' + token },
    
      //   });
      // if (!response.ok) {
      //     throw new Error('Network response was not ok ' + response.statusText);
      // }

      // const blob = await response.blob();
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // a.download = 'test.png';
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);



  }


  return (
    <>
      <Container style={{background:"white", paddingTop:disp_type==1 ? 30: 0, paddingBottom:disp_type==1 ? 30: 0}}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          {loading_disp ? <Loading/> : disp_type == 1 && attention_detail && !attention_detail.id ? <div>データがありません。</div> : <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">

            <Grid item xs={12} style={{textAlign:"right"}}>
              <Button 
                variant="contained"  
                style={{marginRight: 20}} 
                startIcon={<SaveAltIcon/>}
                onClick={onClickUpdate}
                disabled = {(attention_detail.name == "" || attention_detail.kana == "" || attention_detail.remarks == "")}
                size="small"
              >登録</Button>

              {attention_detail.id > 0 ? <Button 
                variant="contained"  
                style={{marginRight: 20, backgroundColor:pink['500']}} 
                startIcon={<HighlightOffIcon/>}
                onClick={()=>setDeleteDisp(true)}
                size="small"
                
              >削除</Button>:""}

            </Grid>
            <Grid item xs={4}>
                {/* {moment(attention_detail.date).format("YYYY/MM/DD")} */}
                <DatePicker
                    label="対応日"
                    value={attention_detail.date ? new Date(moment(attention_detail.date).format("YYYY-MM-DD")) : null}
                    onChange={(e)=>onChangeAttentionDetailDate(e, "date")}
                    slotProps={{ textField: { size: 'small' } }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={8}>
              <FormGroup row>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={attention_detail.nikkei_telecom} 
                      onChange={onChangeAttentionDetail}
                      name="nikkei_telecom" />
                    } label="日経テレコン登録あり" />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                   <InputLabel size='small' id="From-select-label">部署</InputLabel>
                   <Select
                    labelId="From-select-label"
                    name="section_id"
                    value={attention_detail.section_id}
                    label="部署"
                    size="small"
                    onChange={onChangeAttentionDetail}
                  >
                    <MenuItem value={0}>-</MenuItem>
                    {section_list.length > 0 && section_list.map((value,index) => <MenuItem value={value.tenpo_no}>{value.tenpo_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={user_list}
                  getOptionLabel={(option) => option.user_name_all ? option.user_name_all : ""}
                  // value={props.vendor_detail.tantou_user_id}
                  value={attention_detail.user_id > 0 && user_list.length > 0 ? user_list.filter((a) => a.id === attention_detail.user_id)[0]: null}
                  onChange={onChangeAttentionDetailUserId}
                  // sx={{ width: 300 }}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="登録者" size="small" />}
                />
              </Grid>
              <Grid item xs={4}>
              </Grid>
              

            <Grid item xs={6}>
              <TextField
                name='name'
                value={attention_detail.name}
                onChange={onChangeAttentionDetail}
                label="名前"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required={true}
                error={!attention_detail.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='kana'
                value={attention_detail.kana}
                onChange={onChangeAttentionDetail}
                label="カナ"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required={true}
                error={!attention_detail.kana}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                size="small"
                  label="生年月日"
                  value={attention_detail.birthday ? new Date(moment(attention_detail.birthday).format("YYYY-MM-DD")) : null}
                  onChange={(e)=>onChangeAttentionDetailDate(e, "birthday")}
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='tel1'
                value={attention_detail.tel1}
                onChange={onChangeAttentionDetail}
                label="TEL1"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                // required={true}
                // error={!attention_detail.tel1}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='mail'
                value={attention_detail.mail}
                onChange={onChangeAttentionDetail}
                label="メール"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                // required={true}
                // error={!attention_detail.tel1}
              />
              
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='tel2'
                value={attention_detail.tel2}
                onChange={onChangeAttentionDetail}
                label="TEL2"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                // required={true}
                // error={!attention_detail.tel1}
              />
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={12}>
              {/* {attention_detail.remarks} */}
              <TextField
                name='remarks'
                onChange={onChangeAttentionDetail}
                label="内容"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required={true}
                multiline
                minRows={5}
                maxRows={10}
                value={attention_detail.remarks}
                error={!attention_detail.remarks}
              />
            </Grid>
            <Grid item xs={6}>

            {attention_doc_list.length > 0 ? <List sx={{ width: '100%', border:"1px solid #c4c4c4", padding:1, bgcolor: 'background.paper', maxHeight:window.innerHeight-680, overflow: 'auto', }}>

                  {attention_doc_list.map(function (value, index) {
                      return <>
                        <ListItem
                            style={{padding:0}}
                            key={index}
                            disableGutters
                            secondaryAction={<>
                            {/* <IconButton style={{padding:3}} aria-label="comment">
                                <FileDownloadIcon onClick={()=>{setDocDeleteDisp(value.id)}} />
                            </IconButton> */}
                            <IconButton style={{padding:3}} aria-label="comment">
                                <DeleteIcon onClick={()=>{setDocDeleteDisp(value.id)}} />
                            </IconButton>
                            </>

                            }
                        >
                          <ListItemText primary={<Chip onClick={()=>getAttentionDocFile(value.id, value.name)} label={value.name}  />} />
                        </ListItem>
                        {file.length > 1 && <Divider component="li" />}
                      </>
                  }, this)}

              </List>
            :""}

            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              
              <Box border={'2px dotted gray'} backgroundColor={'#f0f0f0'} fontSize={10} sx={{ textAlign: 'center' }}>


                <Dropzone
                    onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (

                        <div {...getRootProps({ className: 'dropzone' })}>

                            <input {...getInputProps()} />

                            <div style={{ height: 100 }}>
                                <p>ファイル添付追加　クリックまたはドラッグ&ドロップ</p>
                                <ul style={{ padding: 0, marginTop: 3 }}  >
                                </ul>
                            </div>

                        </div>

                    )}
                </Dropzone>


              </Box>
            </Grid>
            <Grid item xs={8}>

              {file.length > 0 ? <List sx={{ width: '100%',border:"1px solid #c4c4c4", padding:1, bgcolor: 'background.paper', maxHeight:window.innerHeight-680, overflow: 'auto', }}>
                  {file.map(function (value, index) {
                      return <>
                        <ListItem
                            key={index}
                            disableGutters
                            secondaryAction={
                            <IconButton aria-label="comment">
                                <DeleteIcon onClick={()=>{deleteFile(index)}} />
                            </IconButton>
                            }
                        >
                          <ListItemText primary={
                              (mime_type[index] === "image/jpeg" || mime_type[index] === "image/png") ? 
                              <img src={base64[index]} style={{ maxWidth: 250, maxHeight: 250 }} />
                              : 
                              <>{name[index]}</>
                          } />
                        </ListItem>
                        {file.length > 1 && <Divider component="li" />}
                      </>
                  }, this)}

              </List>:""}

            </Grid>
            
          </Grid>}
        </LocalizationProvider>
      </Container>

      <Dialog
        open={delete_disp}
        onClose={()=>setDeleteDisp(false)}
        // fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          本当に削除してよろしいですか？
        </DialogTitle>
        <Divider />
        
        <DialogActions>

          
          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:pink['600']}} onClick={onClickDelete} startIcon={<DeleteForeverIcon/>}>削除</Button>

          <Button variant="contained" style={{ marginLeft: 6, backgroundColor:grey['300'], color:"black"}} onClick={()=>setDeleteDisp(false)} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={doc_delete_disp}
        onClose={()=>setDocDeleteDisp(0)}
        // fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          削除した添付ファイルはません。<br/>
          本当に削除してよろしいですか？
        </DialogTitle>
        <Divider />
        
        <DialogActions>

          
          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:pink['600']}} onClick={onClickDocDelete} startIcon={<DeleteForeverIcon/>}>削除</Button>

          <Button variant="contained" style={{ marginLeft: 6, backgroundColor:grey['300'], color:"black"}} onClick={()=>setDocDeleteDisp(0)} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>
    </>
//     <>
//       {props.detail_loading_disp ? <Loading />:
//       <Box sx={{}}>
//         <Grid container style={{marginTop:-60}} spacing={{lg:5,xs:2,}} justifyContent="center">
//           <Grid item xs={6}>
//             <Typography variant="h6" gutterBottom>
//               【基本情報】
//             </Typography>

//             <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
//               <Grid item xs={12}>
//                 <TextField
//                   name='owner_name'
//                   value={props.vendor_detail.owner_name}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="業者名"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   required={true}
//                   error={!props.vendor_detail.owner_name}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='owner_name_kana'
//                   value={props.vendor_detail.owner_name_kana}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="ギョウシャメイカナ"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='h_daihyousha_name'
//                   value={props.vendor_detail.h_daihyousha_name}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="代表取締役"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   name='vendor_section'
//                   value={props.vendor_detail.vendor_section}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="取引先部署"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   name='vendor_user'
//                   value={props.vendor_detail.vendor_user}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="担当者"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='regular_holiday'
//                   value={props.vendor_detail.regular_holiday}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="定休日"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='business_hours'
//                   value={props.vendor_detail.business_hours}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="営業時間"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography variant="h6" gutterBottom>
//             【社内情報】
//             </Typography>
//             <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
//               <Grid item xs={6}>
//                 <FormControl fullWidth>
//                   <InputLabel size='small' id="From-select-label">インボイス課税区分</InputLabel>
//                   <Select
//                     labelId="From-select-label"
//                     name="taxable"
//                     value={props.vendor_detail.taxable}
//                     label="インボイス課税区分"
//                     size="small"
//                     onChange={(e)=>props.onChangeVendorDetail(e)}
//                   >
//                     {/* <MenuItem value={null}>-</MenuItem> */}
//                     <MenuItem value={0}>未確認(問い合わせ中)</MenuItem>
//                     <MenuItem value={1}>課税事業者</MenuItem>
//                     <MenuItem value={2}>免税事業者</MenuItem>
//                     <MenuItem value={3}>課税事業者で簡易課税制度</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={6}>
// 　
//               </Grid>
//               <Grid item xs={6}>
//                 <FormControl fullWidth>
//                   <InputLabel size='small' id="From-select-label">GL担当部署</InputLabel>
//                   <Select
//                     labelId="From-select-label"
//                     name="tantou_section_id"
//                     value={props.vendor_detail.tantou_section_id}
//                     label="GL担当部署"
//                     size="small"
//                     onChange={(e)=>props.onChangeVendorDetail(e)}
//                   >
//                     <MenuItem value={0}>-</MenuItem>
//                     {section_list.length > 0 && section_list.map((value,index) => <MenuItem value={value.tenpo_no}>{value.tenpo_name}</MenuItem>)}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={6}>

//                 <Autocomplete
//                   disablePortal
//                   id="combo-box-demo"
//                   options={user_list}
//                   getOptionLabel={(option) => option.user_name_all ? option.user_name_all : ""}
//                   // value={props.vendor_detail.tantou_user_id}
//                   value={props.vendor_detail.tantou_user_id > 0 && user_list.length > 0 ? user_list.filter((a) => a.id === props.vendor_detail.tantou_user_id)[0]: null}
//                   onChange={props.onChangeVendorDetailUserId}
//                   // sx={{ width: 300 }}
//                   fullWidth
//                   renderInput={(params) => <TextField {...params} label="GL担当者" size="small" />}
//                 />

//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='vendor_remarks'
//                   value={props.vendor_detail.vendor_remarks}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="備考"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   multiline
//                   maxRows={8}
//                   minRows={3}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>

//             </Grid>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography variant="h6" gutterBottom>
//             【連絡先情報】
//             </Typography>
//             <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
//               <Grid item xs={6}>
//                 <FormControl fullWidth>
//                   <InputLabel size='small' id="From-select-label">種別</InputLabel>
//                   <Select
//                     labelId="From-select-label"
//                     name="renrakusaki_shubetsu"
//                     value={props.vendor_detail.renrakusaki_shubetsu}
//                     label="種別"
//                     size="small"
//                     onChange={(e)=>props.onChangeVendorDetail(e)}
//                   >
//                     {/* <MenuItem value={null}>-</MenuItem> */}
//                     <MenuItem value={"自宅電話"}>自宅電話</MenuItem>
//                     <MenuItem value={"携帯電話"}>携帯電話</MenuItem>
//                     <MenuItem value={"電話"}>電話</MenuItem>
//                     <MenuItem value={"FAX"}>FAX</MenuItem>
//                     {/* <MenuItem value={"メール"}>メール</MenuItem> */}
//                     <MenuItem value={"電話&FAX"}>電話&FAX</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   name='tel_mail'
//                   value={props.vendor_detail.tel_mail}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="連絡先"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='mail'
//                   value={props.vendor_detail.mail}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="メール"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='renrakusaki_bikou'
//                   value={props.vendor_detail.renrakusaki_bikou}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="備考"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography variant="h6" gutterBottom>
//             【住所】
//             </Typography>
//             <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
//               <Grid item xs={6}>
                
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='zip'
//                   value={props.vendor_detail.zip}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="郵便番号"
//                   size="small"
//                   // fullWidth
//                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                   InputLabelProps={{ shrink: true }}
//                   required={true}
//                   error={!props.vendor_detail.zip}
//                   style={{width:150}}
//                 />
//                 <Button variant="contained" onClick={handleSearchZip} style={{backgroundColor:"#007bff", marginLeft:10}} size='small' color="primary" startIcon={<SearchIcon />} >検索</Button>
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name='address'
//                   value={props.vendor_detail.address}
//                   onChange={(e)=>props.onChangeVendorDetail(e)}
//                   label="住所"
//                   size="small"
//                   fullWidth
//                   InputLabelProps={{ shrink: true }}
//                   required={true}
//                   error={!props.vendor_detail.address}
//                   // style={{marginLeft:5}}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <FormControl fullWidth>
//                   <InputLabel size='small' id="From-select-label">種別</InputLabel>
//                   <Select
//                     labelId="From-select-label"
//                     name="address_shubetsu"
//                     value={props.vendor_detail.address_shubetsu}
//                     label="種別"
//                     size="small"
//                     onChange={(e)=>props.onChangeVendorDetail(e)}
//                   >
//                     {/* <MenuItem value={null}>-</MenuItem> */}
//                     <MenuItem value={"自宅"}>自宅</MenuItem>
//                     <MenuItem value={"事務所(本店)"}>事務所(本店)</MenuItem>
//                     <MenuItem value={"支店"}>支店</MenuItem>
//                     <MenuItem value={"その他"}>その他</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={6}>
//               <FormGroup row>
//                 <FormControlLabel control={
//                   <Checkbox 
//                     checked={props.vendor_detail.is_dm} 
//                     onChange={(e)=>props.onChangeVendorDetail(e)}
//                     name="is_dm" />
//                   } label="案内送付先" />
//                 </FormGroup>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Box>
//       }

      
//     </>


  );
}



export default Detail;
