import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import VendorGrid from './Grid.tsx';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { purple, red, grey, blue, lightBlue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import {UserContext,} from '../Layout/IndexComponents'

import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;


const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Index(props) {
  const { instance, inProgress, accounts } = useMsal();

  const { user_detail } = useContext(UserContext);

  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [tatemono_name, setTatemonoName] = useState("");
  const [keiyakusya_name, setKeiyakusyaName] = useState("");
  
  const [data_list, setDataList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(false);//


  const [form, setForm] = useState({count:50, vendor_name:"", taxable:null, tantou_section_id: 0, annai: false});//検索フォーム
  const [section_list, setSectionList] = useState([]);//
  const [user_list, setUserList] = useState([]);//
  const [vendor_list, setVendorList] = useState([]);//
  const [vendor_list_count, setVendorListCount] = useState([]);//
  const [vendor_detail_disp, setVendorDetailDisp] = useState(false);//


  const [vendor_detail, setVendorDetail] = useState({owner_id: 0 });//


  useEffect(() => {

    getSectionListData()
    getUserListData()
    
  }, [props.vendor_detail.id]);
  // useEffect(() => {

  //   setVendorDetail(props.vendor_detail)
    
  // }, [props.vendor_detail]);


  const getSectionListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/sectionlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setSectionList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }

  const getUserListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/userlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setUserList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }

  const getData = async () => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorlist`, { 
      method: 'post', 
      body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setVendorList(data.list)
      setVendorListCount(data.count)
      // console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }


  const handleSearch = async () => {

    getData()

  }



  const handleDetail = async () => {


    setVendorDetailDisp(false)

  }

  const handleSearchZip = async () => {

    if(!props.vendor_detail.zip || props.vendor_detail.zip == ""){
      alert("郵便番号を入力してください")
      return
    }else{
      
    }
    // setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/adresssearch/` + props.vendor_detail.zip.replace(/-/g, ''), { 
      method: 'get', 
      // body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      if(data.length > 0){
        var newDetail = {...props.vendor_detail, address: data[0].todoufuken_name + data[0].shikuchouson_name + data[0].chouiki_name}
        props.setVendorDetail(newDetail)
      }


      // console.log(data)
    })
    .catch(error => console.error(error));

    // setLoadingDisp(false)
  }

  return (
    <>
      {props.detail_loading_disp ? <Loading />:
      <Box sx={{}}>
        <Grid container style={{marginTop:-60}} spacing={{lg:5,xs:2,}} justifyContent="center">
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              【基本情報】
            </Typography>

            <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  name='owner_name'
                  value={props.vendor_detail.owner_name}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="業者名"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required={true}
                  error={!props.vendor_detail.owner_name}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='owner_name_kana'
                  value={props.vendor_detail.owner_name_kana}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="ギョウシャメイカナ"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='h_daihyousha_name'
                  value={props.vendor_detail.h_daihyousha_name}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="代表取締役"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='vendor_section'
                  value={props.vendor_detail.vendor_section}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="取引先部署"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='vendor_user'
                  value={props.vendor_detail.vendor_user}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="担当者"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='regular_holiday'
                  value={props.vendor_detail.regular_holiday}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="定休日"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='business_hours'
                  value={props.vendor_detail.business_hours}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="営業時間"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
            【社内情報】
            </Typography>
            <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size='small' id="From-select-label">インボイス課税区分</InputLabel>
                  <Select
                    labelId="From-select-label"
                    name="taxable"
                    value={props.vendor_detail.taxable}
                    label="インボイス課税区分"
                    size="small"
                    onChange={(e)=>props.onChangeVendorDetail(e)}
                  >
                    {/* <MenuItem value={null}>-</MenuItem> */}
                    <MenuItem value={0}>未確認(問い合わせ中)</MenuItem>
                    <MenuItem value={1}>課税事業者</MenuItem>
                    <MenuItem value={2}>免税事業者</MenuItem>
                    <MenuItem value={3}>課税事業者で簡易課税制度</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
　
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size='small' id="From-select-label">GL担当部署</InputLabel>
                  <Select
                    labelId="From-select-label"
                    name="tantou_section_id"
                    value={props.vendor_detail.tantou_section_id}
                    label="GL担当部署"
                    size="small"
                    onChange={(e)=>props.onChangeVendorDetail(e)}
                  >
                    <MenuItem value={0}>-</MenuItem>
                    {section_list.length > 0 && section_list.map((value,index) => <MenuItem value={value.tenpo_no}>{value.tenpo_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={user_list}
                  getOptionLabel={(option) => option.user_name_all ? option.user_name_all : ""}
                  // value={props.vendor_detail.tantou_user_id}
                  value={props.vendor_detail.tantou_user_id > 0 && user_list.length > 0 ? user_list.filter((a) => a.id === props.vendor_detail.tantou_user_id)[0]: null}
                  onChange={props.onChangeVendorDetailUserId}
                  // sx={{ width: 300 }}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="GL担当者" size="small" />}
                />

              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='vendor_remarks'
                  value={props.vendor_detail.vendor_remarks}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="備考"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  maxRows={8}
                  minRows={3}
                  // style={{marginLeft:5}}
                />
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
            【連絡先情報】
            </Typography>
            <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size='small' id="From-select-label">種別</InputLabel>
                  <Select
                    labelId="From-select-label"
                    name="renrakusaki_shubetsu"
                    value={props.vendor_detail.renrakusaki_shubetsu}
                    label="種別"
                    size="small"
                    onChange={(e)=>props.onChangeVendorDetail(e)}
                  >
                    {/* <MenuItem value={null}>-</MenuItem> */}
                    <MenuItem value={"自宅電話"}>自宅電話</MenuItem>
                    <MenuItem value={"携帯電話"}>携帯電話</MenuItem>
                    <MenuItem value={"電話"}>電話</MenuItem>
                    <MenuItem value={"FAX"}>FAX</MenuItem>
                    {/* <MenuItem value={"メール"}>メール</MenuItem> */}
                    <MenuItem value={"電話&FAX"}>電話&FAX</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='tel_mail'
                  value={props.vendor_detail.tel_mail}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="連絡先"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='mail'
                  value={props.vendor_detail.mail}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="メール"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='renrakusaki_bikou'
                  value={props.vendor_detail.renrakusaki_bikou}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="備考"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  // style={{marginLeft:5}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
            【住所】
            </Typography>
            <Grid container spacing={{lg:1,xs:1,}} justifyContent="center">
              <Grid item xs={6}>
                
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='zip'
                  value={props.vendor_detail.zip}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="郵便番号"
                  size="small"
                  // fullWidth
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  InputLabelProps={{ shrink: true }}
                  required={true}
                  error={!props.vendor_detail.zip}
                  style={{width:150}}
                />
                <Button variant="contained" onClick={handleSearchZip} style={{backgroundColor:"#007bff", marginLeft:10}} size='small' color="primary" startIcon={<SearchIcon />} >検索</Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='address'
                  value={props.vendor_detail.address}
                  onChange={(e)=>props.onChangeVendorDetail(e)}
                  label="住所"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required={true}
                  error={!props.vendor_detail.address}
                  // style={{marginLeft:5}}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size='small' id="From-select-label">種別</InputLabel>
                  <Select
                    labelId="From-select-label"
                    name="address_shubetsu"
                    value={props.vendor_detail.address_shubetsu}
                    label="種別"
                    size="small"
                    onChange={(e)=>props.onChangeVendorDetail(e)}
                  >
                    {/* <MenuItem value={null}>-</MenuItem> */}
                    <MenuItem value={"自宅"}>自宅</MenuItem>
                    <MenuItem value={"事務所(本店)"}>事務所(本店)</MenuItem>
                    <MenuItem value={"支店"}>支店</MenuItem>
                    <MenuItem value={"その他"}>その他</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              <FormGroup row>
                <FormControlLabel control={
                  <Checkbox 
                    checked={props.vendor_detail.is_dm} 
                    onChange={(e)=>props.onChangeVendorDetail(e)}
                    name="is_dm" />
                  } label="案内送付先" />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      }

      
    </>


  );
}



export default Index;
