import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import Loading from './Loading';
import RoomList from './RoomList';
import ShikichiParkingList from './ShikichiParkingList';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment, { weekdays } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { purple, red, grey } from '@mui/material/colors';



// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };

type Post = {

  bk_no: number;
  bk_name: string;
  bk_kana: string;

  post: string;
  add_cyo: string;
  add_banti: string;
  add_etc: string;

  
  tatemono_id: string;
  tatemono_name: string;
  brui_no: string;

  tatemono_kana: string;
  zip_1: string;
  zip_2: string;
  choiki: string;
  other_address: string;

};


const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function TatemonoList(props) {

  const { instance, inProgress, accounts } = useMsal();
  // console.log(props,"props")
  const [tatemono_list, setTatemonoList] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();


  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const [sorting, setSorting] = useState<SortingState>([]);
  


  const [delete_modal, setDeleteModal] = React.useState(false);
  const [delete_data, setDeleteData] = React.useState({bk_no : 0, brui_no: "", tatemono_id:""});

  const [new_modal, setNewModal] = React.useState(false);
  const [new_data, setNewData] = React.useState({bk_no : 0, brui_no: "", tatemono_id:"", tatemono_name:""});

  const [select_modal, setSelectModal] = React.useState(false);

  const [type, setType] = React.useState(0);
  const [tatemono_id, setTatemonoId] = useState("");
  const [tatemono_name, setTatemonoName] = useState("");
  const [tm_tatemono_list, setTmTatemonoList] = useState<Post[]>([]);
  const [tm_tatemono_list_state, setTmTatemonoListState] = useState(0);


  useEffect(() => {
    console.log(props.tm_tatemono_list)
    setTatemonoList(props.tm_tatemono_list)
    
  }, [props.tm_tatemono_list]);

  useEffect(() => {

    setTmTatemonoListState(props.tm_tatemono_list_state)
    
  }, [props.tm_tatemono_list_state]);

  const handleClickOpen = (bk_no, brui_no, tatemono_id) => {

    // var newDatail = {...tatemono_list.filter(a=>a.bk_no == bk_no && a.brui_no == brui_no && a.tatemono_id == tatemono_id)[0]}
    // setDetail(newDatail)

    // setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   setDeleteModal(false);
  //   setNewModal(false);
  //   setSelectModal(false);
  // };






  // テーブルセルのカスタマイズ
  const columns = [


    columnHelper.accessor((row) => ``, {
      header: () => '',
      id:'renkei',
      enableSorting: false, // 列のソート無効
      size:100,
      // maxSize: 1000,
      cell: (e) => (
        <Button variant="contained" sx={{}} onClick={()=>{props.onClickSetTm(e.row.original); props.setSelectModal(false)}} size="small">選択</Button>
      ),
    }),
    

    
    columnHelper.accessor('tatemono_id', {
      header: '物件ID',
      id:'tatemono_id',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 100,
      // maxSize: 900,
    }),
    columnHelper.accessor('tatemono_name', {
      header: '物件名',
      id:'tatemono_name',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 400,
      // maxSize: 900,
    }),
    columnHelper.accessor((row) => `${row.choiki}${row.other_address}`, {
      header: () => '住所',
      id:'Address',
      enableSorting: false, // 列のソート無効
      size:300,
      // maxSize: 1000,
    }),

  

  ];



  const table = useReactTable({
    data: tatemono_list,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    // getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    
  });

  
  return (
    <div>

      <div className='tablebox' style={{height: window.innerHeight-50-105-60-80}}>
        {tm_tatemono_list_state ? <Loading /> :
        
        <div>
          <table className='searchTmList'
            style={{borderSpacing: 0, width:'max-content',fontSize:"15px", }}
          >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th  colSpan={header.colSpan}
                    {...{
                      key: header.id,
                      // colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),  // 列幅を反映
                      },
                      
                    }}
                    >
                      
                      <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                        asc: ' ▲',   // 昇順
                        desc: ' ▼',  // 降順
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {/* sliceで表示する行数の設定
              {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
              {table.getRowModel().rows.map((rowValue, rowIndex) => (

              
                <tr 
                  {...{
                    key: rowValue.id,
                    style: {
                      // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                      // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f0'
                    },
                  }}
                >
                  {rowValue.getVisibleCells().map((value, index) => (
                    <td
                      {...{
                        key: value.id,
                        style: {
                          width: value.column.getSize(),
                        },
                      }}
                    >
                      {flexRender(value.column.columnDef.cell, value.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            
          </table>
        </div>}
      </div>



    </div>


  );
}



export default TatemonoList;
