import React, { useState,useContext  } from 'react';
import axios from "axios";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import LoadingButton from '@mui/lab/LoadingButton';

import * as recruting_api from './Api/recruting_api.ts';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import Recruit from './Components/Recruit/Index';
// import { Route } from 'react-router';
// import '/Components/Recruit/Css/GeneralAffairsData.css';

// import NewsTable from './NewsTable';

// const [region, setAge] = React.useState('');
// const handleChange = (event) => {
//   setAge(event.target.value);
// };

// class Recruit extends React.Component {
  
function Recruit(){
  const [shanaidata, setShanaiData] = useState();
  const [editshanaidata, setEditShanaiData] = useState();

  // 出身地に使ってる↓
  const [region, setregion] = useState(0);
  const [ChangeTab, setChangeTab] = useState(1);
  const [shussin, setshussin] = useState([]);
  const [isEditing, setIsEditing] = useState(false);//ボタンの出し分け
  const [newdata, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);//保存中になるボタン
  

  // 社内データ系
  
  const shanadataEdit = (data,num) =>{
    console.log(data,num)

    var obj = editshanaidata
    if(num === 1){
      obj.gender_man = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 2){
      obj.gender_woman = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 3){
      obj.tintai_gender_man = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 4){
      obj.tintai_gender_woman = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 5){
      obj.kinzoku_year = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 6){
      obj.kinzoku_month = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 7){
      obj.nenkankyujitsu = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 8){
      obj.heikinyukyu = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 9){
      obj.ikukyu_man = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    else if(num === 10){
      obj.ikukyu_woman = data
      setEditShanaiData(JSON.parse(JSON.stringify(obj)))
    }
    
  }
  const saveEditingShanaiData = () => {
    setLoading(true);

    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/updataShanaiData";
    axios.post(url,{
      tab: ChangeTab,
      data: editshanaidata,
    }).then((abc) => {
      setShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
      setEditShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
      setLoading(false)
    });
    // console.log(shanaidata)
  }

 //出身地　ボタン出し分け
  const editingHandler = () => {
    setIsEditing(true);
  }

  const stopEditingHandler = () => {
    setIsEditing(false);
  }

  //出身地データ保存
  const saveEditingHandler = () => {
    setLoading(true);//保存中になって3秒で元に戻る

    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/updataBirthplace";
    axios.post(url,{
      data: newdata,
    }).then((response) => {
      console.log(response)
      setshussin(response.data);
      var ary = response.data.filter((x)=> x.region_no === region)
      console.log(ary)
      setNewData(ary)
      setLoading(false)
    });
    
  }

  const indexData = (v) =>{
    setregion(v)
    var ary = shussin.filter((x)=> x.region_no === v)
    console.log(ary,"ary")
    setNewData(ary)
    // newdata 
  }

  const shussinedit = (data,index) =>{
    // alert()
    var ary = newdata
    console.log(ary)
    ary[index].people = data

    console.log(ary)
    setNewData(ary)
    console.log(data,index)
    // newdata 
  }

  React.useEffect(() => {
    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/getBirthplace";
    axios.get(url).then((response) => {
      console.log(response)
      setshussin(response.data);
    });

    
  }, []);
  React.useEffect(() => {
    let s_data = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/getShanaiData";
    axios.get(s_data).then((abc) => {
      setShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
      setEditShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
    });
    
  }, []);
  
  if (!shussin) return null;
  if (!shanaidata) return null;
  // function tabToggle(v) {
  //   console.log("aiueo")
  //   setChangeTab(ChangeTab + 1)
  //   // this.setState({ChangeTab: v}); 
  //   // console.log(this.state.ChangeTab)
  // }
  // function handleChange(e){
  //   setregion(region + 1)
  //   console.log(e)
  //   // console.log(this.state.region)
  //   // this.setState({region: e.target.value});
  // }
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ChangeTab: 1,
  //     region:0, 
  //     setAge:0,
  //   };
  //   this.tabToggle = this.tabToggle.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  // }
  
  
  // render() {
    // const { payload, error } =  call(recruting_api.Birthplace());


  return (
    <div className="App" id="content">
      <p className='GADtitle'>総務データ{ChangeTab}</p>
      <div className='GADbox'>
        <Grid container columns={12} gap={2} spacing={{lg:2,xs:1,}} direction="row" justifyContent="center" alignItems="stretch" className="container">
          <Grid item xs={12} lg={4} className="tab-list">
            <p className='tabtitle'>入力項目選択</p>
            <Grid container columns={{ xs: 10, lg: 12 }} gap={{ xs: 1, lg: 2 }}  direction="row" justifyContent="center" alignItems="container">
              {/* <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 1 ? " active":"")} onClick={() => {setChangeTab(1);setEditShanaiData(editshanaidata);console.log('first',first)}}>男女比</Grid> */}
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 1 ? " active":"")} onClick={() => {setChangeTab(1);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>男女比</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 2 ? " active":"")} onClick={() => {setChangeTab(2);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>賃貸　男女比</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 3 ? " active":"")} onClick={() => {setChangeTab(3);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>平均勤続年数</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 4 ? " active":"")} onClick={() => {setChangeTab(4);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>年間休日日数</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 5 ? " active":"")} onClick={() => {setChangeTab(5);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>平均有休休暇取得日数</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 6 ? " active":"")} onClick={() => {setChangeTab(6);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>育休・産休活用人数</Grid>
              <Grid item xs={3} lg={12}  p={1} className={"tab-item" + (ChangeTab === 7 ? " active":"")} onClick={() => {setChangeTab(7);setEditShanaiData(JSON.parse(JSON.stringify(shanaidata)))}}>出身地</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7} className="tab-container">
            <div className={"tab-content" + (ChangeTab === 1 ? " active":"")}>
              <p className='datetitle'>男女比</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    label="男性"
                    name='man'
                    value={editshanaidata.gender_man}
                    onChange={(e) => {shanadataEdit(e.target.value,1)}}
                    id="outlined-start-adornment first"
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
              </div>
              <div className='woman'>
                <TextField
                    label="女性"
                    name='woman'
                    value={editshanaidata.gender_woman}
                    onChange={(e) => {shanadataEdit(e.target.value,2)}}
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
              </div>
              <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 2 ? " active":"")}>
              <p className='datetitle'>賃貸　男女比</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    label="男性"
                    name='tintai_man'
                    value={editshanaidata.tintai_gender_man}
                    onChange={(e) => {shanadataEdit(e.target.value,3)}}
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <div className='woman'>
                <TextField
                    label="女性"
                    name='tintai_woman'
                    value={editshanaidata.tintai_gender_woman}
                    onChange={(e) => {shanadataEdit(e.target.value,4)}}
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 3 ? " active":"")}>
              <p className='datetitle'>平均勤続年数</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    name='year'
                    value={editshanaidata.kinzoku_year}
                    onChange={(e) => {shanadataEdit(e.target.value,5)}}
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '150px' }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">年</InputAdornment>,
                    }}
                  />
                  <TextField
                    name='month'
                    id="outlined-start-adornment"
                    value={editshanaidata.kinzoku_month}
                    onChange={(e) => {shanadataEdit(e.target.value,6)}}
                    sx={{ m: 1, width: '150px' }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">カ月</InputAdornment>,
                    }}
                  />
                </div>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 4 ? " active":"")}>
              <p className='datetitle'>年間休日日数</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    name='holiday'
                    id="outlined-start-adornment"
                    value={editshanaidata.nenkankyujitsu}
                    onChange={(e) => {shanadataEdit(e.target.value,7)}}
                    sx={{ m: 1, width: '150px' }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">日</InputAdornment>,
                    }}
                  />
                </div>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 5 ? " active":"")}>
              <p className='datetitle'>平均有休休暇取得日数</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    name='paid'
                    id="outlined-start-adornment"
                    value={editshanaidata.heikinyukyu}
                    onChange={(e) => {shanadataEdit(e.target.value,8)}}
                    sx={{ m: 1, width: '150px' }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">日</InputAdornment>,
                    }}
                  />
                </div>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 6 ? " active":"")}>
              <p className='datetitle'>育休・産休活用人数</p>
              <form className='post' method="post">
                <div className='man'>
                  <TextField
                    label="男性"
                    name='childcare_man'
                    id="outlined-start-adornment"
                    value={editshanaidata.ikukyu_man}
                    onChange={(e) => {shanadataEdit(e.target.value,9)}}
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">人</InputAdornment>,
                    }}
                  />
                </div>
                <div className='woman'>
                <TextField
                    label="女性"
                    name='childcare_woman'
                    id="outlined-start-adornment"
                    value={editshanaidata.ikukyu_woman}
                    onChange={(e) => {shanadataEdit(e.target.value,10)}}
                    sx={{ m: 1, width: '150px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputMode: 'numeric',pattern: "[0-9]*",
                      endAdornment: <InputAdornment position="end">人</InputAdornment>,
                    }}
                  />
                </div>
                <LoadingButton onClick={saveEditingShanaiData} variant="contained" loading={loading} color="warning" sx={{width:'80px'}}>登録</LoadingButton>
              </form>
            </div>

            <div className={"tab-content" + (ChangeTab === 7 ? " active":"")}>
              <p className='datetitle'>出身地</p>
              <div style={{paddingBottom:'20px'}}>
                <FormControl sx={{width:'200px',}} disabled={isEditing}>
                  <InputLabel id="From-select-label">地方</InputLabel>
                  <Select
                    labelId="From-select-label"
                    id="From-select"
                    value={region}
                    label="From"
                    onChange={(e)=>indexData(e.target.value)}
                  >
                    <MenuItem value={1}>北海道東北</MenuItem>
                    <MenuItem value={2}>関東</MenuItem>
                    <MenuItem value={3}>中部</MenuItem>
                    <MenuItem value={4}>近畿</MenuItem>
                    <MenuItem value={5}>中国</MenuItem>
                    <MenuItem value={6}>四国</MenuItem>
                    <MenuItem value={7}>九州沖縄</MenuItem>
                  </Select>
                </FormControl>
                {isEditing &&<LoadingButton onClick={saveEditingHandler} variant="contained" loading={loading} color="warning" sx={{width:'80px',marginLeft:'20px'}}>保存</LoadingButton>}
                {!isEditing && <Button onClick={editingHandler} variant="contained" color="primary" sx={{width:'80px',marginLeft:'20px'}}>編集</Button>}
                {isEditing && <Button onClick={stopEditingHandler} variant="contained" color="inherit" sx={{width:'80px',marginLeft:'10px'}}>閉じる</Button>}
              </div>
              
                  
              <table border="1">
              <tbody>
                <tr className='prefecture'>
                  <th>県名</th>
                  {newdata.map((data,index) => region === data.region_no ? <td key={index}>{data.prefecture_name}</td>:'')}
                  
                </tr>

                <tr className='persons'>
                  <th>人数</th>
                  {!isEditing && newdata.map((data,index) => region === data.region_no ? <td key={index}>{data.people}</td>:'')}

                  {/* 数字入力 */}
                  {isEditing && newdata.map((data,index) => region === data.region_no ? 
                  <td  key={index} className='data'>
                    <TextField
                      name='paid'
                      defaultValue={data.people}
                      onChange={(event) => shussinedit(event.target.value,index)}
                      id="outlined-start-adornment"
                    />
                  </td>:'')}
                </tr>
                </tbody>
              </table>
            </div>
            
          </Grid>
        </Grid>
      </div>
      {/* <Route path='/Recruit' component={Recruit} /> */}
      <style jsx="true">{
        `
        body {
          background: #E9F2F8;
          font-family: 'Lucida Grande','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',Meiryo,メイリオ, sans-serif;
          color: #292f33;
        }
        .GADbox{
          margin: 0 auto;
          padding-top:40px;
        }
        .GADtitle{
          width:calc(100% - 60px);
          text-align: left;
          margin:0;
          padding: 0px 30px;
          font-size:18px;
          font-weight: bold;
          border-radius: 5px;
        }
        .tabtitle{
          text-align: center;
          font-size:18px;
          font-weight: bold;
          margin: 10px 0 30px;
          padding-bottom: 10px;
          border-bottom: 1px solid #4682b4;
        }
        .container {
          padding: 0px 30px;
        }
        .tab-list {
          padding:20px !important;
          background-color: #fff;
          border-radius: 5px;
          margin:0;
        }
        .tab-list .tab-item {
          width: 100%;
          border-radius: 5px;
          cursor: pointer;
          list-style:none;
          border: 1px solid #333;
          font-size: 16px;
          text-align: center;
          color: #333;
          transition: 0.2s ease;
          cursor: pointer;
        }
        .tab-list .tab-item:hover {
          background: #333;
          color: #fff;
        }
        .tab-list .tab-item.active {
          color: #fff;
          background-color: #428bca;
          border-color: #357ebd;
        }
        .tab-container {
          padding: 20px !important;
          line-height: 2;
          background-color: #fff;
          border-radius: 5px;
        }
        .tab-container .tab-content {
            display: none;
        }
        .tab-container .tab-content.active {
          display: block;
        }


        .datetitle{
          font-size:18px;
          margin:0 0 30px;
          padding-bottom:10px;
          border-bottom: 1px solid #4682b4;
        }
        .man,.woman{
          margin-bottom:20px;
        }
        .man label{
          font-size:16px;
          padding-right:20px;
        }
        .woman label{
          font-size:16px;
          padding-right:20px;
        }
        .register{
          width: 80px;
          font-size: 16px;
          letter-spacing: 3px;
          color: #fff;
          background-color: #f0ad4e;
          border-color: #eea236;
        }

        .percent{
          width:100px;
          padding-left:50px;
        }

        table{
          border-collapse: collapse
        }
        table th,table td{
          width:80px;
        }

        `
      }
      </style>
    </div>
  );
    // };
};

export default Recruit;
