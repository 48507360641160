import React, { useState,useContext } from 'react';
import axios from "axios";
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from '@mui/material/InputAdornment';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';


// import LineSelect from './LineSelect';
// import LineTables from './LineTables';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  BarChart,
} from 'recharts';

import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  // width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const closeButtonStyle = {
  height: 0,
  textAlign: "right",
};




function Recruit(){
  const [tablev, setTableV] = useState(0);
  const [graphdatalist, setgraphdatalist] = useState([]);

  const [uriage, setUriage] = useState([]);
  const [eigyou_rieki, setEigyouRieki] = useState([]);
  const [kanri_kosu, setKanriKosu] = useState([]);
  const [nyukyoritsu, setNyukyoritsu] = useState([]);
  const [chukai, setChukai] = useState([]);
  const [raiten, setRaiten] = useState([]);

  
  const [tables, setTables] = useState([]);

  const [open, setOpen] = useState(false);
  const [chageComponents, ChengeComponents] = useState();
  const handleOpen = (v,type) => {
    setOpen(true);
    setTableV(v);
    ChengeComponents(type)
    console.log(type);
  }
  const handleClose = () => {
    setOpen(false)
    setIsEditing(false)
  };




  
  
  const [newtabledata, NewTableData] = useState([{id:'', year:'', uriage:'', eigyou_rieki:'', kanri_kosu:'', nyukyoritsu:'',chukai:'',raiten:'',}]);
  const [setnewdata, setNewTableData] = useState([]);

  const [henshudata, setHenshuData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);//ボタンの出し分け
  const [loading, setLoading] = useState(false);//保存中になるボタン
  //　ボタン出し分け
  const editingHandler = () => {
    setIsEditing(true);
  }

  const stopEditingHandler = () => {
    setIsEditing(false);
  }
  const HenshuTableEdit = (data,index) =>{
    console.log(data,index,"index")
    // var ary = henshudata
    var ary = graphdatalist
    console.log(ary,"any")
    if(tablev === '0'){
      ary[index].uriage = data
    }else if(tablev === '1'){
      ary[index].eigyou_rieki = data
    }else if(tablev === '2'){
      ary[index].kanri_kosu = data
    }else if(tablev === '3'){
      ary[index].nyukyoritsu = data
    }else if(tablev === '4'){
      ary[index].chukai = data
    }else if(tablev === '5'){
      ary[index].raiten = data
    }
    // ary[index].uriage = data
    // console.log(ary,"any")

    console.log(ary)
    setHenshuData(ary)
    console.log(ary,index,"any")
  }

  //編集データ保存
  const saveEditingHandler = () => {
    setLoading(true);//保存中になって3秒で元に戻る

    console.log(henshudata,"henshudata")
    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/updataUriageData";   
    // let url = "http://localhost:5000/v1/recruiting/updataUriageData";
    axios.post(url,{
      data: henshudata,
    }).then((response) => {
      console.log(response)
      console.log(response.data,"graph")
      setgraphdatalist(response.data)
      console.log(graphdatalist,"graphdatalist")

      var uriage_for =[]
      var rieki_for =[]
      var kosu_for =[]
      var nyukyo_for =[]
      var chukai_for =[]
      var raiten_for =[]

      for (let i = 0; i < response.data.length; ++i) {
          console.log(response.data[i],i);
          uriage_for[i] =
          {
            "name": response.data[i].year,
            "売上高": response.data[i].uriage,
            "table_name": "売上高",
            "value": response.data[i].uriage,
          }
          rieki_for[i] =
          {
            "name": response.data[i].year,
            "営業利益": response.data[i].eigyou_rieki,
            "table_name": "営業利益",
            "value": response.data[i].eigyou_rieki,
          }
          kosu_for[i] =
          {
            "name": response.data[i].year,
            "管理戸数": response.data[i].kanri_kosu,
            "table_name": "管理戸数",
            "value": response.data[i].kanri_kosu,
          }
          nyukyo_for[i] =
          {
            "name": response.data[i].year,
            "入居率": response.data[i].nyukyoritsu,
            "table_name": "入居率",
            "value": response.data[i].nyukyoritsu,
          }
          chukai_for[i] =
          {
            "name": response.data[i].year,
            "仲介件数": response.data[i].chukai,
            "table_name": "仲介件数",
            "value": response.data[i].chukai,
          }
          raiten_for[i] =
          {
            "name": response.data[i].year,
            "来店数": response.data[i].raiten,
            "table_name": "来店数",
            "value": response.data[i].raiten,
          }
        }
          
      setUriage(uriage_for)
      setEigyouRieki(rieki_for)
      setKanriKosu(kosu_for)
      setNyukyoritsu(nyukyo_for)
      setChukai(chukai_for)
      setRaiten(raiten_for)

      setTables([
        uriage_for,rieki_for,kosu_for,nyukyo_for,chukai_for,raiten_for
      ])
      setLoading(false)
      setIsEditing(false);
    });
    
  }


  const [year_value, setYear] = useState('');
  const last = graphdatalist.length - 1;
  const yearEdit = (data) =>{
    console.log(data,"year")
    setYear(data);
    var ary = newtabledata
    ary[0].year = data
    console.log(ary)
    setNewTableData(ary)
  }

  const dataEdit = (data) =>{
    var ary = newtabledata

    if(tablev === '0'){
      ary[0].uriage = data
    }else if(tablev === '1'){
      ary[0].eigyou_rieki = data
    }else if(tablev === '2'){
      ary[0].kanri_kosu = data
    }else if(tablev === '3'){
      ary[0].nyukyoritsu = data
    }else if(tablev === '4'){
      ary[0].chukai = data
    }else if(tablev === '5'){
      ary[0].raiten = data
    }
    console.log(ary)
    setNewTableData(ary)
  }
  

  //あたらしいデータ保存
  const saveNewDatas = () => {
    setLoading(true);//保存中になって3秒で元に戻る

    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/insertUriageData";   
    // let url = "http://localhost:5000/v1/recruiting/insertUriageData";
    axios.post(url,{
      data: {
        id:"",
        year: setnewdata[0].year,
        uriage: setnewdata[0].uriage,
        eigyou_rieki: setnewdata[0].eigyou_rieki,
        kanri_kosu: setnewdata[0].kanri_kosu,
        nyukyoritsu: setnewdata[0].nyukyoritsu,
        chukai: setnewdata[0].chukai,
        raiten: setnewdata[0].raiten,
    },
    }).then((response) => {
      console.log(response)
      console.log(response.data,"graph")
      setgraphdatalist(response.data)
      console.log(graphdatalist,"graphdatalist")

      var uriage_for =[]
      var rieki_for =[]
      var kosu_for =[]
      var nyukyo_for =[]
      var chukai_for =[]
      var raiten_for =[]

      for (let i = 0; i < response.data.length; ++i) {
          console.log(response.data[i],i);
          uriage_for[i] =
          {
            "name": response.data[i].year,
            "売上高": response.data[i].uriage,
            "table_name": "売上高",
            "value": response.data[i].uriage,
          }
          rieki_for[i] =
          {
            "name": response.data[i].year,
            "営業利益": response.data[i].eigyou_rieki,
            "table_name": "営業利益",
            "value": response.data[i].eigyou_rieki,
          }
          kosu_for[i] =
          {
            "name": response.data[i].year,
            "管理戸数": response.data[i].kanri_kosu,
            "table_name": "管理戸数",
            "value": response.data[i].kanri_kosu,
          }
          nyukyo_for[i] =
          {
            "name": response.data[i].year,
            "入居率": response.data[i].nyukyoritsu,
            "table_name": "入居率",
            "value": response.data[i].nyukyoritsu,
          }
          chukai_for[i] =
          {
            "name": response.data[i].year,
            "仲介件数": response.data[i].chukai,
            "table_name": "仲介件数",
            "value": response.data[i].chukai,
          }
          raiten_for[i] =
          {
            "name": response.data[i].year,
            "来店数": response.data[i].raiten,
            "table_name": "来店数",
            "value": response.data[i].raiten,
          }
        }
          
      setUriage(uriage_for)
      setEigyouRieki(rieki_for)
      setKanriKosu(kosu_for)
      setNyukyoritsu(nyukyo_for)
      setChukai(chukai_for)
      setRaiten(raiten_for)

      setTables([
        uriage_for,rieki_for,kosu_for,nyukyo_for,chukai_for,raiten_for
      ])
      setLoading(false)
    });
    
  }


  React.useEffect(() => {
    console.log("aaaaaaa", process.env.REACT_APP_API_OPENROOM_EX_URL)
    let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/getUriageData";
    // let url = "http://localhost:5000/v1/recruiting/getUriageData";
    console.log(url,"aaaaaaa")
    axios.get(url).then((response) => {
      console.log(response.data,"graph")
      setgraphdatalist(response.data)
      console.log(graphdatalist,"graphdatalist")

      var uriage_for =[]
      var rieki_for =[]
      var kosu_for =[]
      var nyukyo_for =[]
      var chukai_for =[]
      var raiten_for =[]

      for (let i = 0; i < response.data.length; ++i) {
          console.log(response.data[i],i);
          uriage_for[i] =
          {
            "name": response.data[i].year,
            "売上高": response.data[i].uriage,
            "table_name": "売上高",
            "value": response.data[i].uriage,
          }
          rieki_for[i] =
          {
            "name": response.data[i].year,
            "営業利益": response.data[i].eigyou_rieki,
            "table_name": "営業利益",
            "value": response.data[i].eigyou_rieki,
          }
          kosu_for[i] =
          {
            "name": response.data[i].year,
            "管理戸数": response.data[i].kanri_kosu,
            "table_name": "管理戸数",
            "value": response.data[i].kanri_kosu,
          }
          nyukyo_for[i] =
          {
            "name": response.data[i].year,
            "入居率": response.data[i].nyukyoritsu,
            "table_name": "入居率",
            "value": response.data[i].nyukyoritsu,
          }
          chukai_for[i] =
          {
            "name": response.data[i].year,
            "仲介件数": response.data[i].chukai,
            "table_name": "仲介件数",
            "value": response.data[i].chukai,
          }
          raiten_for[i] =
          {
            "name": response.data[i].year,
            "来店数": response.data[i].raiten,
            "table_name": "来店数",
            "value": response.data[i].raiten,
          }
        }
          
      console.log(nyukyo_for,"nyukyo_for")

      setUriage(uriage_for)
      setEigyouRieki(rieki_for)
      setKanriKosu(kosu_for)
      setNyukyoritsu(nyukyo_for)
      setChukai(chukai_for)
      setRaiten(raiten_for)

      setTables([
        uriage_for,rieki_for,kosu_for,nyukyo_for,chukai_for,raiten_for
      ])

    });
}, []);

const EditTable =({data}) => {
  console.log(tables[tablev])
   
  return <div>
  <p style={{fontSize:"16px",fontWeight: 'bold',}}>{tables[tablev][0].table_name}</p>
  <div className='henshu_btns'>
    {isEditing &&<LoadingButton onClick={saveEditingHandler} variant="contained" loading={loading} color="warning" sx={{width:'80px',marginLeft:'20px'}}>保存</LoadingButton>}
    {!isEditing && <Button onClick={editingHandler} variant="contained" color="primary" sx={{width:'80px',marginLeft:'20px'}}>編集</Button>}
    {isEditing && <Button onClick={stopEditingHandler} variant="contained" color="inherit" sx={{width:'80px',marginLeft:'10px'}}>閉じる</Button>}
  </div>
  <table className='pctable' border="1">
    <tbody>
      <tr>
        <th>年数</th>
        {tables[tablev].map((data,index) => <td key={index}>{data.name}</td>)}
        
      </tr>

      <tr>
        <th>{tables[tablev][0].table_name}</th>
        {!isEditing && tables[tablev].map((data,index) => <td key={index}>{data.value}</td>)}

        {/* 数字入力*/}
        {isEditing && tables[tablev].map((data,index) =>  
        <td key={index} className='data'>
          <TextField
            // autoFocus
            name='paid'
            defaultValue={data.value}
            onChange={(event) => HenshuTableEdit(event.target.value,index)}
            // onFocus={() => this.onFocusFieldFirstName()}
            // onBlur={() => this.onBlurField()}
            id="outlined-start-adornment"
          />
        </td>)} 
      </tr>
    </tbody>
  </table>

  <table className='sptable' border="1">
    <tbody>
      <tr>
        <th>年数</th>
        
        <th>{tables[tablev][0].table_name}</th>
      </tr>

      {tables[tablev].map((data,index) => <tr key={index}>
        <td key={index}>{data.name}</td>

        {!isEditing && <td key={index}>{data.value}</td>}
        {/* 数字入力*/}
        {isEditing &&  
        <td key={index} className='data'>
          <TextField
            name='paid'
            defaultValue={data.value || null}
            onChange={(event) => HenshuTableEdit(event.target.value,index)}
            id="outlined-start-adornment"
          />
        </td>} 
      </tr>)}
    </tbody>
  </table>
  </div>
}

const InsertDatas =({data}) => {
   
  return <div>
    <p class="edit_title" >{tables[tablev][0].table_name}
      {tablev === '0' &&<span> :百万円単位</span>}
      {tablev === '1' &&<span> :百万円単位</span>}
    </p>
    <hr/>
    <div className='formbox'>
      <FormControl  sx={{ m: 1, minWidth: 120 }} >
        <InputLabel id="demo-simple-select-label">年数</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year_value}
          label="年数"
          onChange={(event) => yearEdit(event.target.value)}
        >
          <MenuItem value={graphdatalist[last].year}>{graphdatalist[last].year}</MenuItem>
          <MenuItem value={graphdatalist[last].year+1}>{graphdatalist[last].year+1}</MenuItem>
          <MenuItem value={graphdatalist[last].year+2}>{graphdatalist[last].year+2}</MenuItem>
        </Select>
      </FormControl>

      <TextField sx={{minWidth: 200}} style={{marginLeft:'20px',}} id="standard-basic" 
        onChange={(event) => dataEdit(event.target.value)}
        label={tables[tablev][0].table_name} variant="standard" 
      />
    </div>
    <div className='editbutton'>
      <LoadingButton onClick={saveNewDatas} className='newsavebtn' variant="contained" loading={loading} color="warning" sx={{width:'80px',marginLeft:'20px'}}>保存</LoadingButton>
    </div>

  </div>
}



  return (
    <div className="App" id="content">
     <Grid container columns={10} gap={1}>
      
      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>売上高(百万円単位)</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={0} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={0} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={uriage}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="売上高" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>

      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>営業利益(百万円単位)</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={1} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={1} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={eigyou_rieki}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="営業利益" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>

      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>管理戸数</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={2} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={2} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={kanri_kosu}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="管理戸数" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>

      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>入居率</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={3} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={3} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={nyukyoritsu}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="入居率" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>

      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>不動産仲介件数</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={4} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={4} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={chukai}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="仲介件数" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>

      <Grid className='grid-items' item xs={10} lg={3} >
        <div className='headbox'>
          <span className='graphtitle'>来店数</span>
          <div className='henshubox'>
            <Button onClick={(e)=>handleOpen(e.target.value,1)} value={5} variant="contained" color="success" size="small">編集</Button>
            <Button onClick={(e)=>handleOpen(e.target.value,2)} value={5} style={{marginLeft:'10px',}} variant="contained" size="small">追加</Button>
          </div>
        </div>
        <hr/>
        <div className="graphbox">                         
          <BarChart
            width={500}
            height={250}
            data={raiten}
            style={{ fontSize:"14px" }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
            </XAxis>
            <Tooltip /> 
            <YAxis label={{ angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
            <Bar dataKey="来店数" fill="#82ca9d" barSize={20}>
              {/* <LabelList dataKey="uv" position="top" /> */}
            </Bar>
          </BarChart>
        </div>
      </Grid>
    </Grid>

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={closeButtonStyle}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {chageComponents === 1 && <EditTable />}
        {chageComponents === 2 && <InsertDatas />}
        
      </Box>
    </Modal>

    
  

    
      
      {/* <Route path='/Recruit' component={Recruit} /> */}
      <style jsx="true">{
        `
        body{
          background: #E9F2F8;
          font-family: 'Lucida Grande','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',Meiryo,メイリオ, sans-serif;
          color: #292f33;
        }
        .grid-items{
          background: #fff;
          padding:10px;
          border-radius: 5px
        }
        .css-1dfafci-MuiGrid-root{
          justify-content: center;
        }
        .headbox{
          padding-top:5px;
          text-align:left;
          display:flex;
        }
        .graphtitle{
          padding-left:10px;
          font-weight: bold;
        }
        hr {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba(0,0,0,.1);
        }
        .headbox .henshubox{
          width:150px;
          margin-left:auto;
        }
        .graphbox{
          overflow: auto;
        }
        .graphbox::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
      
         
        .graphbox::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius:5px;
        }

        table{
          border-collapse: collapse
        }
        table th,table td{
          width:100px;
          height: 50px;
          text-align:center;
        }

        .pctable{
          display:block;
        }
        .sptable{
          display:none;
        }
        @media screen and (max-width:768px) {
          .pctable{
            display:none;
          }
          .sptable{
            display:block;
            width:240px;
          }
          table th,table td{
            width:120px;
            height: 40px;
            text-align:center;
          }
        
        }

        .henshu_btns{
          text-align:right;
          margin-bottom:20px;
        }
        .edit_title{
          font-size:16px;
          font-weight: bold;
        }
        .formbox{
          width:100%;
          display: flex;
          align-items: center;
        }
        .editbutton{
          width:100%;
          text-align:right;
        }
        

        `
      }
      </style>
    </div>
  );
};

export default Recruit;
