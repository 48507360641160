import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Zero9Grid from './Grid.tsx';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";


import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;

// const HttpsProxyAgent = require('https-proxy-agent');

// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };
// const url = process.env.REACT_APP_API_PM_URL
// const url = `http://10.0.2.2:8080`

const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Index(props) {
  const { instance, inProgress, accounts } = useMsal();

  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [tatemono_name, setTatemonoName] = useState("");
  const [keiyakusya_name, setKeiyakusyaName] = useState("");
  
  const [data_list, setDataList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(false);//

  useEffect(() => {

    getYearList()
    handleSearch(moment().format("YYYY"))
    
  }, []);


  // async function fetchData(url) {
  //   fetch(url)
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
  //     console.log('Data from Go server:', data);
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  // }

  const getYearList = () => {
        
    let data = []
    for(let i=2023; i<=parseInt(moment().format("YYYY"))+1; i++){
        data.push(i)
    }
    setYearList(data)

  }

  const getData = async (nyukinbi) => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    const values = new URLSearchParams();
    values.append('start_date', moment(nyukinbi+"/01/01").startOf("year").format("YYYY-MM-DD"));
    values.append('end_date', moment(nyukinbi+"/01/01").endOf("year").format("YYYY-MM-DD"));
    values.append('tatemono_name', tatemono_name);
    values.append('keiyakusya_name', keiyakusya_name);
    await fetch(url+`/sqmeisai`, { 
      method: 'post', 
      body: values,
      headers: new Headers({
        'Authorization': 'Bearer ' + token, 
        'Accept': 'application/json',
    })
    })
    .then(response => response.json())
    .then(data => {
      setDataList(data)
      console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }


  const handleSearch = async (nyukinbi) => {

    getData(nyukinbi)

    // createHeader()

  //   try {
  //     const response = await fetch.get('http://localhost:8080/sqmeisai/1442');
  //     console.log(response.data);
  // } catch (error) {
  //     console.error('Error fetching data:', error);
  // }

  //   axios.get(`http://localhost:8080/sqmeisai/1442`)
  // .then(response => {
  //   console.log('Data from Go server:', response.data);
  // })
  // .catch(error => {
  //   console.error('Error fetching data:', error);
  // });


    // getData(10)
    // const externalEndpoint = `http://localhost:8080/sqmeisai/1442`;

    // try {
    //   // 外部エンドポイントからデータを取得
    //   const data = await fetchData(externalEndpoint);
  
    //   // 取得したデータを使って何かを行う
    //   console.log('Fetched data:', data);
    // } catch (error) {
    //   // エラーが発生した場合の処理
    //   console.error('An error occurred:', error.message);
    // }

    // if(Object.keys(searchText).indexOf('value') !== -1 && searchText.value){
    //     console.log(searchText.value)
    //     props.user_id(searchText.value)
    // }
    // var token = await GetAccessToken({ instance, inProgress, accounts })
    // const token = authentication.getAccessToken();
// alert(token)
// alert(url+`/sqmeisai/1442`)
    // const f = await fetch(`http://localhost:8080/sqmeisai/1442`)
      
    // let d = f.json()
    // console.log("bbb")
    // console.log(f)


// try {
//   const response = await axios.get(url+`/sqmeisai/1442`);
//   return response.data;
// } catch (error) {
//   console.error('Failed to fetch data:', error.message);
//   throw error;
// }

// axios({
//   method: 'get',
//   url: url+`/sqmeisai/1442`,
//   proxy: false,
//   // httpsAgent: new HttpsProxyAgent('http://your-proxy-address:port')
// })
// .then(response => {
//   alert(response.data.length)
//   console.log(response.data)
//   }
// )
// .catch(error => {
//   alert("err")
//   console.error('Error:', error)
//   }
// );



// const proxyAgent = new HttpsProxyAgent('http://your-proxy-address:port');
// fetch(url+`/sqmeisai/1442`, { agent: proxyAgent })
//   .then(response => response.json())
//   .then(data => console.log(data))
//   .catch(error => console.error('Error:', error));



    // alert(d.length)

  }

  const getExcel = async () => {

    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    const values = new URLSearchParams();
    values.append('start_date', moment(nyukin_date+"/01/01").startOf("year").format("YYYY-MM-DD"));
    values.append('end_date', moment(nyukin_date+"/01/01").endOf("year").format("YYYY-MM-DD"));
    values.append('tatemono_name', tatemono_name);
    values.append('keiyakusya_name', keiyakusya_name);
    await fetch(url+`/sqmeisaiexcel`, { 
      method: 'post', 
      body: values,
      headers: new Headers({
        'Authorization': 'Bearer ' + token, 
        'Accept': 'application/json',
    })
    })
    .then(response => response.blob())
    .then(images => {

      // // Then create a local URL for that image and print it 
      // bloburl_tmp = URL.createObjectURL(images)
      // // alert(bloburl)
      // console.log(bloburl_tmp)
      // setBloburl(bloburl_tmp)


      const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = "ゼロ９システム料振替分.xlsx";
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(images);
        // ダウンロード開始
        a.click();

  });
    // .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    // .catch(error => ({ error }));
    // .then(response => response.json())
    // .then(data => {
    //   setDataList(data)
    //   console.log(data)
    // })
    // .catch(error => console.error(error));

  }


  return (
    <>

      <Box sx={{textAlign:"left", backgroundColor:"white", p:3}}>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            <FormControl sx={{width:'200px',}}>
              <InputLabel id="From-select-label">年</InputLabel>
              <Select
                labelId="From-select-label"
                id="From-select"
                value={nyukin_date}
                label="From"
                onChange={(e)=>{setNyukinDate(e.target.value); handleSearch(e.target.value)}}
                size="small"
              >
                {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}

              </Select>
            </FormControl>

            <TextField
              name='tatemono_name'
              value={tatemono_name}
              onChange={(e) => setTatemonoName(e.target.value)}
              label="物件名"
              size="small"
              style={{marginLeft:5}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch(nyukin_date)
                }
              }}
            />

            <TextField
              name='keiyakusya_name'
              value={keiyakusya_name}
              onChange={(e) => setKeiyakusyaName(e.target.value)}
              label="契約者名"
              size="small"
              style={{marginLeft:5}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch(nyukin_date)
                }
              }}
            />

            <Button variant="contained" sx={{ml:2}} onClick={()=>handleSearch(nyukin_date)}>検索</Button>
            <Button variant="contained" sx={{ml:2}} onClick={getExcel}>excel</Button>
          </div>
          <div style={{textAlign: 'right',paddingBottom:"10px"}}>
            {data_list && <span style={{fontSize: 30, fontWeight: 'bold'}}>{data_list.length}件</span>}
          </div>

        </div>
        <div style={{fontSize:"13px"}}>※入金ベースの金額を表示</div>
      </Box>

      {data_list && <Box sx={{mt: 1, p:2, backgroundColor:"white"}}>
        {loading_disp ? <Loading />: <Zero9Grid data_list={data_list} />}
        
      </Box>}
      {/* <Grid item xs={12}>

        <Grid item xs={12}>
          
          <Grid container sx={{alignItems:"center"}}>
            <Grid item xs={12} sx={{textAlign: 'left'}}>
              <Typography sx={{ mt: 'auto', mb: 'auto', textAlign: 'left',fontSize:"14px" }}>建物名</Typography>
              <TextField
                // label="建物名"
                name="strong_points"
                // fullWidth
                size='small'
              />
              <Button variant="contained" sx={{backgroundColor: '#3F51B5', ml: 2}}>検索</Button>
            </Grid>
          </Grid>
        </Grid>


        <Zero9Grid />
      </Grid> */}
      

    </>


  );
}



export default Index;
