import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment, { weekdays } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };

type Post = {
  owner_id: number;
  owner_name: string;
  section_name: string;
  address: string;
  tel: string;
  mail: string;
  taxable: number;

  
};

function Grid(props) {
  // console.log(props,"props")
  const [posts, setPosts] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();


  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const [sorting, setSorting] = useState<SortingState>([]);


    // 左サイドに固定されている列の幅
    var header_width1 = 0;
    var header_width2 = 0;
    var header_width3 = 0;
    var header_width4 = 0;
    var header_width5 = 0;

    var cell_width1 = 0;
    var cell_width2 = 0;
    var cell_width3 = 0;
    var cell_width4 = 0;
    var cell_width5 = 0;
    var footer_width1 = 0;
    var footer_width2 = 0;
    var footer_width3 = 0;
    var footer_width4 = 0;
    var footer_width5 = 0;
  

  useEffect(() => {
    setPosts(props.data_list)
  }, [props.data_list]);

  


  // テーブルセルのカスタマイズ
  const columns = [

    columnHelper.accessor('owner_name', {
      header: '業者名',
      id:'owner_name',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 270,
      // cell: ({ row, column }) => (
      //   <div onClick={() => alert()}>
      //     {row.getValue()}
      //   </div>
      // )
      // cell: (row) => <div onClick={() => props.handleDetail(row.row.original.owner_id)}>{row.getValue() ? row.getValue() : "　　　"}</div>,
      // maxSize: 900,
    }),

    columnHelper.accessor('section_name', {
      header: 'GL担当部署',
      id:'section_name',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 120,
      
    }),

    columnHelper.accessor('address', {
      header: '住所',
      id:'address',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 300,
    }),
    columnHelper.accessor('tel', {
      header: 'TEL',
      id:'tel',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 160,
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    columnHelper.accessor('mail', {
      header: 'メールアドレス',
      id:'mail',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 250,
      // cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    columnHelper.accessor('taxable', {
      header: 'インボイス課税区分',
      id:'taxable',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 200,
      cell: (props) => (props.getValue() == 0 ? "未確認(問い合わせ中)" : props.getValue() == 1 ? "課税事業者" : props.getValue() == 2 ? "免税事業者" : props.getValue() == 3 ? "課税事業者で簡易課税制度" : "") ,
    }),

  


  ];


  const table = useReactTable({
    data: posts,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    // getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    
  });

  function headerLeft1(size) {
    header_width1 = size;
    return 0;
  }
  function headerLeft2(size) {
    header_width2 = size;
    return header_width1;
  }
  function headerLeft3(size) {
    header_width3 = size;
    return header_width1 + header_width2;
  }
  function headerLeft4(size) {
    header_width4 = size;
    return header_width1 + header_width2 + header_width3;
  }
  function headerLeft5(size) {
    header_width5 = size;
    return header_width1 + header_width2 + header_width3 + header_width4;
  }
  function headerLeft6() {
    return header_width1 + header_width2 + header_width3 + header_width4 + header_width5;
  }

  function headerBaseLeft(size) {
    return 0;
  }

  function cellLeft1(size) {
    cell_width1 = size;
    return 0;
  }
  function cellLeft2(size) {
    cell_width2 = size;
    return cell_width1;
  }
  function cellLeft3(size) {
    cell_width3 = size;
    return cell_width1 + cell_width2;
  }
  function cellLeft4(size) {
    cell_width4 = size;
    return cell_width1 + cell_width2 + cell_width3;
  }
  function cellLeft5(size) {
    cell_width5 = size;
    return cell_width1 + cell_width2 + cell_width3 + cell_width4;
  }
  function cellLeft6() {
    return cell_width1 + cell_width2 + cell_width3 + cell_width4 + cell_width5;
  }

  return (
    <div>

      <div className='tablebox' style={{height: window.innerHeight-70}}>
        <div>
        <table className='vendor_list'
          style={{borderSpacing: 0, width:'max-content',fontSize:"15px", }}
        >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th  colSpan={header.colSpan}
                  {...{
                    key: header.id,
                    // colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),  // 列幅を反映
                      // position: (header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date") ? 'sticky' : 'relative',
                      left: header.id === "1_base_bk_name" ? headerBaseLeft(header.getSize()) : header.id === "bk_name" ? headerLeft1(header.getSize()) : header.id === "hy_no" ? headerLeft2(header.getSize()) : header.id === "kys_name" ? headerLeft3(header.getSize()) : header.id === "ky_date" ? headerLeft4(header.getSize()) : header.id === "kai_ymd" ? headerLeft5(header.getSize()) : header.id === "keirui_name" ? headerLeft6() : '',
                      backgroundColor: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 'white' : 'none',
                      zIndex: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name" || header.id === "base") ? 2 : 1,
                    },
                    
                  }}
                  >

                    {/* {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                      
                    )} */}
                    
                    <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                      asc: ' ▲',   // 昇順
                      desc: ' ▼',  // 降順
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    
                    {/* <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        // onClick: () => header.column.getToggleSortingHandler(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }`,
                      }}
                    />
                    {header.column.getCanSort() ? <button onClick={header.column.getToggleSortingHandler()}>
              
                    {{
                      asc: '▲',   // 昇順
                      desc: '▼',  // 降順
                    }
                      // 並び替えが行われてない場合
                      [header.column.getIsSorted() as string] ?? ''
                    }
                    </button> : "" }
                     */}


                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* sliceで表示する行数の設定
            {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
            {table.getRowModel().rows.map((rowValue, rowIndex) => (

              



              <tr 
                onClick={() => props.handleDetail(rowValue.original.owner_id)}
                className={rowIndex % 2 === 0 ? "table-row" : 'table-row2'}
                
                {...{
                  key: rowValue.id,
                  style: {
                    // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                    // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f0'
                  },
                }}
              >
                {rowValue.getVisibleCells().map((value, index) => (
                  <td
                  
                    {...{
                      key: value.id,
                      style: {
                        width: value.column.getSize(),
                        textAlign: (value.id.includes("Tatemono_Name") || value.id.includes("Address"))?"left":"center",
                        position: value.id.includes("bk_name") || value.id.includes("hy_no") || value.id.includes("kys_name") || value.id.includes("ky_date") || value.id.includes("kai_ymd") || value.id.includes("keirui_name") ? 'sticky' : 'unset',
                        left: value.id.includes("_bk_name") ? cellLeft1(value.column.getSize()) : value.id.includes("_hy_no") ? cellLeft2(value.column.getSize()) : value.id.includes("_kys_name") ? cellLeft3(value.column.getSize()) : value.id.includes("_ky_date") ? cellLeft4(value.column.getSize()) : value.id.includes("_kai_ymd") ? cellLeft5(value.column.getSize()) : value.id.includes("keirui_name") ? cellLeft6() : '',
                        // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                        // backgroundColor:'white'
                        // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f5f5f5'
                      },

                    }}
                  >
                    {flexRender(value.column.columnDef.cell, value.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
        </div>
      </div>


    </div>


  );
}



export default Grid;
