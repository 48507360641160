import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import RoomList from './RoomList';
import ShikichiParkingList from './ShikichiParkingList';
import ParkingList from './ParkingList';
import TmList from './TmList.tsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment, { weekdays } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { purple, red, grey } from '@mui/material/colors';



// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };

type Post = {

  bk_no: number;
  bk_name: string;
  bk_kana: string;

  post: string;
  add_cyo: string;
  add_banti: string;
  add_etc: string;

  
  tatemono_id: string;
  tatemono_name: string;
  brui_no: string;

  tatemono_kana: string;
  zip_1: string;
  zip_2: string;
  choiki: string;
  other_address: string;

};

const initialDetail: Post = {
  bk_no: 0,
  bk_name: '',
  bk_kana: '',
  post: '',
  add_cyo: '',
  add_banti: '',
  add_etc: '',

  
  tatemono_id: '',
  tatemono_name: '',
  brui_no: '',

  tatemono_kana: '',
  zip_1: '',
  zip_2: '',
  choiki: '',
  other_address: '',
};


const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function TatemonoList(props) {

  const { instance, inProgress, accounts } = useMsal();
  // console.log(props,"props")
  const [tatemono_list, setTatemonoList] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();


  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const [sorting, setSorting] = useState<SortingState>([]);
  

  const [open, setOpen] = React.useState(false);
  const [detail, setDetail] = useState<Post>(initialDetail);
  

  const [delete_modal, setDeleteModal] = React.useState(false);
  const [delete_data, setDeleteData] = React.useState(initialDetail);

  const [new_modal, setNewModal] = React.useState(false);
  const [new_data, setNewData] = React.useState(initialDetail);

  const [select_modal, setSelectModal] = React.useState(false);

  const [type, setType] = React.useState(0);
  const [tatemono_id, setTatemonoId] = useState("");
  const [tatemono_name, setTatemonoName] = useState("");
  const [tm_tatemono_list, setTmTatemonoList] = useState<Post[]>([]);
  const [tm_tatemono_list_state, setTmTatemonoListState] = useState(0);


  useEffect(() => {
    console.log(props.tatemono_list)
    setTatemonoList(props.tatemono_list)
  }, [props.tatemono_list]);

  const handleClickOpen = (bk_no, brui_no, tatemono_id) => {

    var newDatail = {...tatemono_list.filter(a=>a.bk_no == bk_no && a.brui_no == brui_no && a.tatemono_id == tatemono_id)[0]}

    setDetail(newDatail)

    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   setDeleteModal(false);
  //   setNewModal(false);
  //   setSelectModal(false);
  // };


  const ConvBkDelete = async () => {
    var token = await GetAccessToken({ instance, inProgress, accounts }); 
// alert(detail.bk_no)
    await fetch(url+`/convtkbkdelete`, { 
      method: 'put', 
      body: JSON.stringify(delete_data),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setTatemonoList((prevDetail) => prevDetail.filter((value) => (!(value.bk_no == delete_data.bk_no && value.tatemono_id == delete_data.tatemono_id))));
      setDeleteModal(false)
      console.log(data)
    })
    .catch(error => console.error(error));
  }

  const handleSearch = async (name:any) => {

    setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+(type==0?`/convtktmtatemono`:`/convtktmparking`), { 
      method: 'post', 
      body: JSON.stringify({tatemono_id: tatemono_id, tatemono_name: name}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setTmTatemonoList(data)
      console.log(data)
    })
    .catch(error => console.error(error));

    setTmTatemonoListState(0)

  }

  //検索用の名前をセット
  const onClickSetName = async (name:any) => {

    setTatemonoName(name)
    handleSearch(name)

  }

  //HS側の結び付け用物件をセット
  const onClickSetTm = async (detail:any) => {

    // const [new_data, setNewData] = React.useState({bk_no : 0, brui_no: "", tatemono_id:"", tatemono_name:""});

    var newDetail = {...new_data, tatemono_id: (type==1 ? "SysP" : "") + detail.tatemono_id, tatemono_name: detail.tatemono_name, tatemono_kana: detail.tatemono_kana, zip_1: detail.zip_1, zip_2: detail.zip_2, choiki: detail.choiki, other_address: detail.other_address}

    setNewData(newDetail)
  }

  //新しい結び付をしようとしたとき　一覧から追加ボタン選択
  const onClickNewConvTk = async (detail:any) => {

    setNewData({...detail, tatemono_id:"", tatemono_name:"", tatemono_kana:"", zip_1:"", zip_2:"", choiki:"", other_address:""})

    setNewModal(true);
  }

  //TMを追加登録
  const onClickInsertConvTk = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/convtkbkinsert`, { 
      method: 'put', 
      body: JSON.stringify(new_data),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {

      // setTatemonoList((prevState) => ([ ...prevState, new_data ]).sort(function (a: any, b: any) {
      //   if (a.bk_name > b.bk_name) return 1;
      //   if (a.bk_name < b.bk_name) return -1;
      //   return 0;
      // }));
      setTatemonoList((prevState) => ([ ...prevState, new_data ]));

      console.log(data)

      setNewModal(false)
    })
    .catch(error => console.error(error));
  }

  //bkごと解除
  const onClickDeleteConvTk = async (detail:any) => {

    setDeleteData({...detail})

    setDeleteModal(true);
  }

  // テーブルセルのカスタマイズ
  const columns = [
    

    columnHelper.accessor((row) => `${row.add_cyo}${row.add_banti}`, {
      header: () => '部屋／区画',
      id:'renkei',
      enableSorting: false, // 列のソート無効
      size:100,
      // maxSize: 1000,
      cell: (props) => (
        props.row.original.tatemono_id != "" && <Button variant="contained" sx={{}} onClick={()=>handleClickOpen(props.row.original.bk_no, props.row.original.brui_no, props.row.original.tatemono_id)} size="small">連携</Button>
      ),
    }),
    


    // columnHelper.accessor('bk_no', {
    //   header: '連携',
    //   id:'bk_no',
    //   // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
    //   enableSorting: true, // 列のソート無効
    //   size: 100,
    //   // maxSize: 900,
    //   cell: (props) => (
    //     // <button onClick={() => update(props.row.original.id)}>
    //     <Button variant="contained" sx={{}} onClick={()=>handleClickOpen(props.getValue())} size="small">連携</Button>
        
    //   ),
    // }),
    
    columnHelper.accessor('bk_no', {
      header: '賃革 物件ID',
      id:'bk_no',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 100,
      // maxSize: 900,
    }),

    columnHelper.accessor('bk_name', {
      header: '賃革 物件名',
      id:'bk_name',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 380,
      // maxSize: 900,
    }),

    columnHelper.accessor((row) => `${row.add_cyo}${row.add_banti}${row.add_etc}`, {
      header: () => '賃革 住所',
      id:'Address',
      enableSorting: false, // 列のソート無効
      size:200,
      // maxSize: 1000,
    }),

    columnHelper.accessor((row) => `${row.add_cyo}${row.add_banti}${row.add_etc}`, {
      header: () => '連携先追加',
      id:'tuika',
      enableSorting: false, // 列のソート無効
      size:100,
      // maxSize: 1000,
      cell: (props) => (
        <IconButton size="small" onClick={()=>{onClickNewConvTk(props.row.original)}}><AddCircleIcon /></IconButton>
        // <Button startIcon={<AddCircleIcon />} variant="contained" sx={{}} onClick={()=>{onClickNewConvTk(props.row.original)}} size="small">追加</Button>
      ),
    }),
    
    columnHelper.accessor((row) => row.tatemono_id.includes("SysP") ? "P" : "建物", {
      header: () => '分類',
      id:'bunrui',
      enableSorting: false, // 列のソート無効
      size:60,
      // maxSize: 1000,
      // cell: (props) => (
      //   <Button startIcon={<AddCircleIcon />} variant="contained" sx={{}} onClick={()=>{onClickNewConvTk(props.row.original)}} size="small">追加</Button>
      // ),
    }),

    columnHelper.accessor('tatemono_id', {
      header: 'HS物件ID',
      id:'tatemono_id',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 100,
      // maxSize: 900,
    }),
    columnHelper.accessor('tatemono_name', {
      header: 'HS物件名',
      id:'tatemono_name',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 380,
      // maxSize: 900,
    }),

    // columnHelper.accessor('brui_no', {
    //   header: 'brui_no',
    //   id:'brui_no',
    //   // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
    //   enableSorting: true, // 列のソート無効
    //   size: 50,
    //   // maxSize: 900,
    // }),
    

    columnHelper.accessor((row) => `${row.add_cyo}${row.add_banti}`, {
      header: () => '連携解除',
      id:'kaijyo',
      enableSorting: false, // 列のソート無効
      size:100,
      // maxSize: 1000,
      cell: (props) => (
        props.row.original.tatemono_id != "" ? 
        // <Button variant="contained" sx={{}} color="error" onClick={()=>{onClickDeleteConvTk(props.row.original)}} size="small">解除</Button> 
        <IconButton size="small" color="error" onClick={()=>{onClickDeleteConvTk(props.row.original)}}><RemoveCircleOutlineIcon /></IconButton>
        : 
        ""
      ),
    }),
  ];



  const table = useReactTable({
    data: tatemono_list,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    // getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    
  });

  
  return (
    <div>

      <div className='tablebox' style={{height: window.innerHeight-50-105-60}}>
        <div>
        <table className='searchList'
          style={{borderSpacing: 0, width:'max-content',fontSize:"15px", }}
        >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th colSpan={header.colSpan}
                  {...{
                    key: header.id,
                    // colSpan: header.colSpan,
                    style: {
                      // color:"white",
                      backgroundColor: header.id == "tatemono_id" || header.id == "tatemono_name" || header.id == "bunrui" || header.id == "tuika" || header.id == "kaijyo" ? "#4e94cd" : "",
                      width: header.getSize(),  // 列幅を反映
                      // position: (header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date") ? 'sticky' : 'relative',
                      // left: header.id === "1_base_bk_name" ? headerBaseLeft(header.getSize()) : header.id === "bk_name" ? headerLeft1(header.getSize()) : header.id === "hy_no" ? headerLeft2(header.getSize()) : header.id === "kys_name" ? headerLeft3(header.getSize()) : header.id === "ky_date" ? headerLeft4(header.getSize()) : header.id === "kai_ymd" ? headerLeft5(header.getSize()) : header.id === "keirui_name" ? headerLeft6() : '',
                      // backgroundColor: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 'white' : 'none',
                      // zIndex: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name" || header.id === "base") ? 2 : 1,
                    },
                    
                  }}
                  >

                    {/* {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                      
                    )} */}
                    
                    <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          // onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {{
                      asc: ' ▲',   // 昇順
                      desc: ' ▼',  // 降順
                        }[header.column.getIsSorted() as string] ?? null} */}
                      </div>
                    
                    {/* <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        // onClick: () => header.column.getToggleSortingHandler(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }`,
                      }}
                    />
                    {header.column.getCanSort() ? <button onClick={header.column.getToggleSortingHandler()}>
              
                    {{
                      asc: '▲',   // 昇順
                      desc: '▼',  // 降順
                    }
                      // 並び替えが行われてない場合
                      [header.column.getIsSorted() as string] ?? ''
                    }
                    </button> : "" }
                     */}


                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* sliceで表示する行数の設定
            {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
            {table.getRowModel().rows.map((rowValue, rowIndex) => (

              



              <tr 
                {...{
                  key: rowValue.id,
                  style: {
                    // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                    // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f0'
                  },
                }}
              >
                {rowValue.getVisibleCells().map((value, index) => {
                  console.log("aaaaaaaaa")
                  // console.log(rowValue.getVisibleCells().filter(a=>a.id.includes("tatemono_id"))[0].row.original.tatemono_id.includes("SysP"))
                  // console.log(index)
                  // console.log(rowIndex > 0 ? table.getRowModel().rows[rowIndex-1].original.bk_no : "")
                  // console.log(rowIndex > 0 ? table.getRowModel().rows[rowIndex].original.bk_no : "")
                  return(
                  <td
                    {...{
                      key: value.id,
                      style: {
                        width: value.column.getSize(),
                        backgroundColor: value.column.id == "tatemono_id" || value.column.id == "tatemono_name" || value.column.id == "bunrui" || value.column.id == "kaijyo" ? 
                        rowValue.getVisibleCells().filter(a=>a.id.includes("tatemono_id"))[0].row.original.tatemono_id.includes("SysP") ? "#deffdc": "#fffadc"
                        :"",
                        // textAlign: (value.id.includes("Tatemono_Name") || value.id.includes("Address"))?"left":"center",
                        // position: value.id.includes("bk_name") || value.id.includes("hy_no") || value.id.includes("kys_name") || value.id.includes("ky_date") || value.id.includes("kai_ymd") || value.id.includes("keirui_name") ? 'sticky' : 'unset',
                        // left: value.id.includes("_bk_name") ? cellLeft1(value.column.getSize()) : value.id.includes("_hy_no") ? cellLeft2(value.column.getSize()) : value.id.includes("_kys_name") ? cellLeft3(value.column.getSize()) : value.id.includes("_ky_date") ? cellLeft4(value.column.getSize()) : value.id.includes("_kai_ymd") ? cellLeft5(value.column.getSize()) : value.id.includes("keirui_name") ? cellLeft6() : '',
                        // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                        // backgroundColor:'white'
                        // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#e2f0f3'
                      },
                    }}
                  >
                    {value.column.id == 'tuika' ? rowIndex == 0 || (rowIndex > 0 && table.getRowModel().rows[rowIndex-1].original.bk_no != table.getRowModel().rows[rowIndex].original.bk_no) ? flexRender(value.column.columnDef.cell, value.getContext()) : "" : flexRender(value.column.columnDef.cell, value.getContext())}


                    {/* console.log(rowIndex > 0 ? table.getRowModel().rows[rowIndex-1].original.bk_no : "")
                  console.log(rowIndex > 0 ? table.getRowModel().rows[rowIndex].original.bk_no : "") */}


                  </td>
                )})}
              </tr>
            ))}
          </tbody>
          
        </table>
        </div>
      </div>


      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              {detail.brui_no && parseInt(detail.brui_no) == 19 ? <ParkingList detail={detail} /> :
              detail.tatemono_id && detail.tatemono_id.includes("SysP") ? <ShikichiParkingList detail={detail} /> :
              <RoomList detail={detail} />}
            </Box>

              
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>

      <Dialog
        open={delete_modal}
        onClose={()=>setDeleteModal(false)}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          {/* {tatemono_list.filter(a=>a.bk_no == delete_data.bk_no && a.brui_no == delete_data.brui_no && a.tatemono_id == delete_data.tatemono_id)[0].bk_name}　⇔　{tatemono_list.filter(a=>a.bk_no == delete_data.bk_no && a.brui_no == delete_data.brui_no && a.tatemono_id == delete_data.tatemono_id)[0].tatemono_name} */}
          <div>部屋または区画の結び付けも解除されます。</div>
          <div>本当に解除してよろしいですか？</div>
          <table className='conv_table'>
            {/* <caption>Sample Table</caption> */}
            <colgroup style={{ width: "20%" }} />
            <colgroup style={{ width: "40%" }} />
            <colgroup style={{ width: "40%" }} />
            <thead>
              <tr>
                <th ></th>
                <th style={{backgroundColor:"#df3f5a", color:"white" }}>賃貸革命</th>
                <th style={{backgroundColor:"#4682b4", color:"white" }}>HomeStation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='conv_table_title'>物件ID</td>
                <td>{delete_data.bk_no}</td>
                <td>{delete_data.tatemono_id}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>物件名</td>
                <td>{delete_data.bk_name}</td>
                <td>{delete_data.tatemono_name}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>物件カナ</td>
                <td>{delete_data.bk_kana}</td>
                <td>{delete_data.tatemono_kana}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>住所</td>
                <td>{delete_data.post} {delete_data.add_cyo}{delete_data.add_banti}{delete_data.add_etc}</td>
                <td>{delete_data.zip_1}{delete_data.zip_2} {delete_data.choiki}{delete_data.other_address}</td>
              </tr>

            </tbody>
          </table>

        </DialogContent>
        <DialogActions>

          <Button variant="contained" style={{marginRight: 10, backgroundColor:"#f50057"}} onClick={ConvBkDelete} startIcon={<DeleteForeverIcon/>}>解除</Button>

          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} onClick={(e) => { setDeleteModal(false) }} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={new_modal}
        onClose={()=>setNewModal(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          連携追加
        </DialogTitle>
        <DialogContent>

          {/* <Box>
            {new_data.bk_no > 0 && "賃貸革命 ：　"+tatemono_list.filter(a=>a.bk_no == new_data.bk_no)[0].bk_name}
          </Box>

          <Box>
            {new_data.tatemono_id != "" && "HS ：　"+new_data.tatemono_name}
          </Box> */}



          <table className='conv_table'>
            {/* <caption>Sample Table</caption> */}
            <colgroup style={{ width: "20%" }} />
            <colgroup style={{ width: "40%" }} />
            <colgroup style={{ width: "40%" }} />
            <thead>
              <tr>
                <th ></th>
                <th style={{backgroundColor:"#df3f5a", color:"white" }}>賃貸革命</th>
                <th style={{backgroundColor:"#4682b4", color:"white" }}>HomeStation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='conv_table_title'>物件ID</td>
                <td>{new_data.bk_no}</td>
                <td>{new_data.tatemono_id}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>物件名</td>
                <td>{new_data.bk_name}</td>
                <td>{new_data.tatemono_name}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>物件カナ</td>
                <td>{new_data.bk_kana}</td>
                <td>{new_data.tatemono_kana}</td>
              </tr>
              <tr>
                <td className='conv_table_title'>住所</td>
                <td>{new_data.post} {new_data.add_cyo}{new_data.add_banti}{new_data.add_etc}</td>
                <td>{new_data.zip_1}{new_data.zip_2} {new_data.choiki}{new_data.other_address}</td>
              </tr>

            </tbody>
          </table>


          {tatemono_list.filter(a=>a.bk_no == new_data.bk_no && a.tatemono_id == new_data.tatemono_id).length > 0 && <Alert severity="error" style={{marginTop:10}}>
            すでに結び付けされています！
          </Alert>}


          <Box sx={{mt:2}}>
            <Button variant="contained" style={{ marginLeft: 3}} onClick={(e) => { setSelectModal(true);onClickSetName(tatemono_list.filter(a=>a.bk_no == new_data.bk_no)[0].bk_name) }} >物件／駐車場 選択</Button>
          </Box>

        </DialogContent>
        <DialogActions>
        {/* tatemono_list.filter(a=>a.bk_no == new_data.bk_no)[0].bk_name */}
          <Button 
            variant="contained" 
            disabled={new_data.tatemono_id == "" || (tatemono_list.filter(a=>a.bk_no == new_data.bk_no && a.tatemono_id == new_data.tatemono_id).length > 0)} 
            style={{marginRight: 10}} 
            startIcon={<AddCircleIcon/>}
            onClick={onClickInsertConvTk}
          >追加</Button>

          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} onClick={(e) => { setNewModal(false) }} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>


      <Dialog
        open={select_modal}
        onClose={()=>setSelectModal(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          建物／駐車場 選択
        </DialogTitle>
        <DialogContent style={{paddingTop:10}}>

          <FormControl style={{margin:"10px 0 10px 20px",textAlign:"left"}}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label">カテゴリー</FormLabel> */}
            <RadioGroup
                row
                aria-labelledby="category"
                name="row-radio-buttons-group"
                onChange={(e)=>setType(parseInt(e.target.value))}
                value={type}
            >
                <FormControlLabel value={0} control={<Radio size="small"/>} label="建物" />
                <FormControlLabel value={1} control={<Radio size="small"/>} label="駐車場" />
            </RadioGroup>
          </FormControl>

          <TextField
            name='tatemono_id'
            value={tatemono_id}
            onChange={(e) => setTatemonoId(e.target.value)}
            label={type == 0 ? "HS　物件ID" : "HS　駐車場ID"}
            size="small"
            style={{marginLeft:5}}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  handleSearch(tatemono_name)
              }
            }}
          />

          <TextField
            name='tatemono_name'
            value={tatemono_name}
            onChange={(e) => setTatemonoName(e.target.value)}
            label={type == 0 ? "HS　物件名" : "HS　駐車場名"}
            size="small"
            style={{marginLeft:5}}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  handleSearch(tatemono_name)
              }
            }}
          />

          <Button variant="contained" sx={{ml:2}} onClick={()=>handleSearch(tatemono_name)}>検索</Button>

          <TmList tm_tatemono_list={tm_tatemono_list} tm_tatemono_list_state={tm_tatemono_list_state} onClickSetTm={onClickSetTm} setSelectModal={setSelectModal}/>
          
              
        </DialogContent>
      </Dialog>
    </div>


  );
}



export default TatemonoList;
