import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import './Css/LayoutHeader.css'
import "./Css/LayoutHeader.css";
// import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import {UserContext,} from './IndexComponents'

import axios from "axios";
import Link from '@mui/material/Link';

import styled from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const MenuLi = styled.li`
    display: inline-block;
    
`;




function HeaderComponents() {
  const { instance, inProgress, accounts } = useMsal();
  const handlelogout = () => {
    // authentication.signOut()
  }
  const { user_detail } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const [userName, setuserName] = useState("");
  const [userSection, setuserSection] = useState("");
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  React.useEffect(() => {
    if(inProgress === 'none'){
        console.log("aaaaaaa")
        async function getUser(){
            let url = "https://orooms.global-center.co.jp/v1/recruiting/user";
            var token = await GetAccessToken({ instance, inProgress, accounts })
            // 内容
            var f = await fetch(url, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            var d = await f.json()        
            setuserName(d.user_name_all)
            setuserSection(d.Tenpo_Name)
        } 
        if(accounts[0]){
            // getUser();
        }
    }
    // setuserName(response.data.user_name_all)
    // setuserSection(response.data.Tenpo_Name)
  },[inProgress]);



  // 社内データ系
  // React.useEffect(() => {
  //   let url = "http://localhost:5000/v1/recruiting/getBirthplace";
  //   axios.get(url).then((response) => {
  //     console.log(response)
  //     setshussin(response.data);
  //   });


  // }, []);
  // React.useEffect(() => {authentication
  //   let s_data = "http://localhost:5000/v1/recruiting/getShanaiData";
  //   axios.get(s_data).then((abc) => {
  //     setShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
  //     setEditShanaiData(JSON.parse(JSON.stringify(abc.data[0])));
  //   });

  // }, []);




  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{ minHeight: 50, background: '#4682b4',zIndex: '100', top:0, left:0 }} >
        <Toolbar variant="dense">
          <Link href="/" underline="none">
            <img style={{
              width: '200px',
              height: '35px',
            }} className='logo' src='https://www.homestation.jp/manage/assets/img/common/homestation.svg' alt="homestaion_logo" />
          </Link>
          {/* <Typography variant="h6" color="inherit" component="div"> */}
          <Box className="userbox" sx={{ flexGrow: 0, marginLeft: 'auto' }}>
            <MenuLi className="user" style={{ paddingRight: '20px', fontSize: '14px' }}>
              ログイン中：　{user_detail.Tenpo_Name}　{user_detail.user_name_all}
            </MenuLi>

            <Tooltip title="ログアウトする">
              <IconButton onClick={handlelogout} sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <LogoutIcon className='usericon' size='large' style={{
                  width: '25px', height: '25px', color: '#fff'
                }} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box className="sp_btns" sx={{ marginLeft: 'auto', }}>
            <div className='user_btn'>
              <IconButton
                size="large"
                id="demo-positioned-button2"
                aria-controls={open2 ? 'demo-positioned-menu2' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
                onClick={handleClick2}
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <AccountCircleIcon className='user_icon' />
              </IconButton>
              <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button2"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >

                <MenuItem onClick={handleClose2}>ログイン中<br />{userSection}　{userName}</MenuItem>
                <Divider />
                <Link href="#" color="inherit" underline="none">
                  <MenuItem onClick={handlelogout}>ログアウト</MenuItem>
                </Link>
              </Menu>
            </div>
            <div className='hamburger'>
              <IconButton
                size="large"
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon className='hamburger_icon' />
              </IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Link href="/" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>総務データ</MenuItem>
                </Link>
                <Link href="/RecruitEarnings" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>売上データ</MenuItem>
                </Link>
                {/* <Link href="/RecruitLine" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>LINE</MenuItem>
                </Link> */}
                <Link href="/RecruitNews" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>NEWS</MenuItem>
                </Link>
                {/* <Link href="#" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>ログアウト</MenuItem>
                </Link> */}
              </Menu>
            </div>
          </Box>
          {/* </Typography> */}
        </Toolbar>
      </AppBar>
    </Box>

  );
  // };
};

export default HeaderComponents;
