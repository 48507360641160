import React, { useState } from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import FormControl from '@mui/material/FormControl';
// import InputAdornment from '@mui/material/InputAdornment';
// import FormHelperText from '@mui/material/FormHelperText';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import NewsTable from './NewsTable';
import NewsContents from './NewsContents';


function Recruit(){
  const [stateProp, setStateProp] = useState({id:'', title:'', contents:'', web_open:0, category:0, opened_at:'',});
  const updateState = (saiyou_news) => setStateProp(saiyou_news);

  const [stateList, setList] = useState();
  const updateList = (up) => {console.log(up);setList(up)};

  const [set_delete, setDelete] = useState();
  const updateDelete = (d) => {console.log(d,"d");setDelete(d)};


  return (
    <div className="App" id="content">
      <Grid container columns={10} gap={2} spacing={{lg:2,xs:1,}} direction="row" justifyContent="center" alignItems="stretch" className="container">
        <Grid item xs={12} lg={3} style={{width:'400px'}} className="news_table">
          <NewsTable toggleState={updateState} List={stateList} Delete={set_delete}/>
        </Grid>
        <Grid item xs={12} lg={6.5} className="news_container">
          <NewsContents Contents={stateProp} updateList={updateList} set_delete={updateDelete}/>
        </Grid>
      </Grid>
      
      {/* <Route path='/Recruit' component={Recruit} /> */}
      <style jsx="true">{
        `
       
        body {
          color: #292f33;
          font-family: 'Lucida Grande','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',Meiryo,メイリオ, sans-serif;
        }
        .top_box{
          text-align: left;
          padding-bottom:20px;
        }
        .NewsTitle{
          text-align: left;
          padding-right: 20px;
          font-size:15px;
          font-weight: bold;
        }
        .Table{
          font-size:15px;
        }
        
        .container {
          padding: 0px 20px;
        }
        
        .news_table{
          background-color:#fff;
          paddnig: 15px !important;
        }

        .news_container{
          background-color:#fff;
          paddnig: 15px !important;
        }

        `
      }
      </style>
    </div>
  );
};

export default Recruit;
