import React, { useState,useContext } from 'react';
import axios from "axios";
// import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import WebIcon from '@mui/icons-material/Web';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {Table, Column, Cell} from 'fixed-data-table-2';
import "fixed-data-table-2/dist/fixed-data-table.css";
import { Button } from '@mui/material';
import moment from 'moment';
// export const Authentication = React.createContext()
// function createData(
//     name: string,
//     calories: number,
//     fat: number,
//     carbs: number,
//     protein: number,
// ) {
// return { name, calories, fat, carbs, protein };
// }

// const rows = [
// createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
// createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
// createData('Eclair', 262, 16.0, 24, 6.0),
// createData('Cupcake', 305, 3.7, 67, 4.3),
// createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];
// type Props = {
//     toggleState: () => void;
// };

function NewsTable(props){
// const NewsTable = (props: Props) => {
    const [newslist, setnewslist] = useState({});

    const handleClick = () => {
        // if (props.toggleState) {
        // props.toggleState();
        // }
        console.log()
        let saiyou_news = {id:'', title:'', contents:'', web_open:0, category:0, opened_at:'',}
        props.toggleState(saiyou_news)
        // change_saiyou_news(saiyou_news)
    };


    const editClick = (data) => {
        // if (props.toggleState) {
        // props.toggleState();
        // }
        console.log(data,"data",moment().format("YYYY.MM.DD"))
        props.toggleState(data)
    };

    
    

    // const change_saiyou_news = (data) =>{
    //     console.log(data)
    // }

    React.useEffect(() => {
        console.log("aaaaaaa")
        let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/getNewsList";
        // let url = "http://localhost:5000/v1/recruiting/getNewsList";
        console.log(url,"aaaaaaa")
        axios.get(url).then((response) => {
          console.log(response.data,"newslist")
          setnewslist(response.data);
        });
    }, []);

    React.useEffect(() => {
        console.log("aaaaaaa")
        let url = process.env.REACT_APP_API_OPENROOM_EX_URL+"/v1/recruiting/getNewsList";
        // let url = "http://localhost:5000/v1/recruiting/getNewsList";
        console.log(url,"aaaaaaa")
        axios.get(url).then((response) => {
          console.log(response.data,"newslist")
          setnewslist(response.data);
        }).then(()=>{
            let saiyou_news = {id:'', title:'', contents:'', web_open:0, category:0, opened_at:'',}
            props.toggleState(saiyou_news)
        })
    }, [props.Delete]);

    React.useEffect(() => {
        console.log(props)
        if(props.List){//編集で記事を読み取りしたとき
            console.log(props.List,"List")
            setnewslist(props.List);
        }else{
            
        }
      }, [props]);
      

    return (
        <div id="tcblog" style={{}}>
            <div className='top_box'>
                <span className='NewsTitle'>採用サイト News</span>
                <Button onClick={handleClick} className='addition' variant="contained" size="small">追加</Button>
            </div>
            

            {newslist.length?<Table className='Table' rowHeight={35} rowsCount={newslist.length} width={350} height={500} headerHeight={40}>
                <Column header={<Cell>web</Cell>} 
                    cell={({rowIndex, ...props}) => ( <Cell className='iconbox' {...props}><IconButton disabled={newslist[rowIndex].web_open === 0} 
                    href={"https://www.global-center.co.jp/recruiting/news/detail/" + newslist[rowIndex].id} target="_blank"
                    aria-label="edit" size="small"><WebIcon /></IconButton></Cell>)} width={50}
                />
                <Column header={<Cell>編集</Cell>} 
                    cell={({rowIndex, ...props}) => ( <Cell className='iconbox' {...props}><IconButton id={newslist[rowIndex].id} 
                    onClick={() => editClick(newslist[rowIndex])}
                    aria-label="edit" size="small"><ModeEditOutlineIcon /></IconButton></Cell>)} width={50}
                />
                <Column header={<Cell>タイトル</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{newslist[rowIndex].title}</Cell>)} width={250}/>
                <Column header={<Cell>カテゴリー</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{newslist[rowIndex].category === 0? "採用イベント": "コラム"}</Cell>)} width={120}/>
                <Column header={<Cell>公開</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{newslist[rowIndex].web_open === 0? "非公開": "公開"}</Cell>)} width={80}/>
                <Column header={<Cell>公開日</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{!newslist[rowIndex].opened_at ?null:moment(newslist[rowIndex].opened_at).format("YYYY.MM.DD")}</Cell>)} width={150}/>
            </Table>:""}
            

            <style jsx="true">{
                `
                .Table{
                    text-align:left;
                    font-size:16px;
                }
                .iconbox {
                    text-align:center;
                }

                .iconbox .public_fixedDataTableCell_cellContent{
                    padding:0;
                }

                `
            }
            </style>
        </div>
    );
    
};
// root.render(<HelloMessage name="Taylor" />);
export default NewsTable;




