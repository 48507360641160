import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import AttentionGrid from './Grid.tsx';
import AttentionDetail from './Detail';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { purple, red, grey, blue, lightBlue,pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Divider from '@mui/material/Divider';
import {UserContext,} from '../Layout/IndexComponents'

import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;

// const HttpsProxyAgent = require('https-proxy-agent');

// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };
// const url = process.env.REACT_APP_API_PM_URL
// const url = `http://10.0.2.2:8080`

const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Index(props) {
  const { instance, inProgress, accounts } = useMsal();

  const { user_detail } = useContext(UserContext);


  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [tatemono_name, setTatemonoName] = useState("");
  const [keiyakusya_name, setKeiyakusyaName] = useState("");
  
  const [data_list, setDataList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(false);//


  const [form, setForm] = useState({name:"", tel:"", mail: ""});//検索フォーム
  const [attention_list, setAttentionList] = useState([]);//


  const [section_list, setSectionList] = useState([]);//
  const [user_list, setUserList] = useState([]);//
  const [vendor_list, setVendorList] = useState([]);//
  const [vendor_list_count, setVendorListCount] = useState([]);//

  const [detail_loading_disp, setDetailLoadingDisp] = useState(false);//
  const [delete_disp, setDeleteDisp] = useState(false);//



  const [attention_detail_disp, setAttentionDetailDisp] = useState(false);//
  const [attention_detail, setAttentionDetail] = useState({owner_id: "", taxable:0 });//

  const headers = [
    { label: "No", key: "id" },
    { label: "対応日", key: "date" },
    { label: "名前", key: "name" },
    { label: "カナ", key: "kana" },
    { label: "生年月日", key: "birthday" },
    { label: "備考", key: "remarks" },
    { label: "TEL1", key: "tel1" },
    { label: "TEL2", key: "tel2" },
    { label: "メール", key: "maril" },
    { label: "部署", key: "tenpo_name" },
    { label: "登録者", key: "nickname" },
    { label: "日経テレコン", key: "nikkei_telecom" },


  
  ];

  useEffect(() => {

    getSectionListData()
    getUserListData()
    handleSearch(form)
    
  }, []);

  const handleChange = async (e) => {

    if(e.target.name == "nikkei_telecom"){
      var newDetail = {...form, [e.target.name]: e.target.checked}
      setForm(newDetail)
      handleSearch(newDetail)

    }else{

      var newDetail = {...form, [e.target.name]: e.target.value}
      setForm(newDetail)
    }

  }
  const handleChangeSearch = async (e) => {

    if(e.target.name == "annai"){
      var newDetail = {...form, [e.target.name]: e.target.checked}
      setForm(newDetail)
      handleSearch(newDetail)

    }else{
      var newDetail = {...form, [e.target.name]: e.target.value}
      setForm(newDetail)
      handleSearch(newDetail)
    }

    
  }

  const getSectionListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/sectionlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setSectionList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }

  const getUserListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/userlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setUserList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }



  const getData = async (form) => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/attentionlist`, { 
      method: 'post', 
      body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setAttentionList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }

  //検索
  const handleSearch = async (form) => {

    getData(form)

  }


  //新規
  const handleNewDetail = async () => {

    setAttentionDetail({id: "",  birthday:null, date: new Date(), kana: "", mail: "", name: "", nikkei_telecom: false, remarks: "", tel1: "", tel2: "", section_id: user_detail.am_section_id, user_id: user_detail.id })
    setAttentionDetailDisp(true)

  }

  //詳細取得
  const handleDetail = async (attention) => {

    // getDetailData(owner_id)
    console.log("attention", attention)
    setAttentionDetail(attention)
    setAttentionDetailDisp(true)


  }


  //使わない
  const getDetailData = async (owner_id) => {

    setDetailLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendordetail/`+owner_id, { 
      method: 'get', 
      // body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {

      var detail = data.tm_owner[0]
      if(data.t_owner_renrakusaki.length > 0){
        for(let i=0; i < data.t_owner_renrakusaki.length; i++){
          if(data.t_owner_renrakusaki[i].shubetsu == "メール"){
            detail.mail = data.t_owner_renrakusaki[i].tel_mail
          }else{
            detail.renrakusaki_shubetsu = data.t_owner_renrakusaki[i].shubetsu
            detail.tel_mail = data.t_owner_renrakusaki[i].tel_mail
            detail.renrakusaki_bikou = data.t_owner_renrakusaki[i].bikou
          }
        }
      }
      if(data.t_owner_address.length > 0){
        detail.zip = data.t_owner_address[0].zip_1 != "" && data.t_owner_address[0].zip_2 != "" ? data.t_owner_address[0].zip_1 + "-" + data.t_owner_address[0].zip_2 : ""
        detail.address = data.t_owner_address[0].todoufuken + data.t_owner_address[0].shikuchoson + data.t_owner_address[0].choiki + data.t_owner_address[0].other_address
        detail.address_shubetsu = data.t_owner_address[0].shubetsu
        detail.is_dm = data.t_owner_address[0].is_dm
      }

      setAttentionDetail(detail)
      setAttentionDetailDisp(true)


      // console.log("detaill", detail)
    })
    .catch(error => console.error(error));

    setDetailLoadingDisp(false)
  }

  //詳細変更
  const onChangeVendorDetail = async (e) => {
// alert(e.target.value)

      var value = e.target.value
      if(e.target.name == "zip"){
        value = outoZip(value)
      }
      
      if(e.target.name == "is_dm"){
        var newDetail = {...attention_detail, [e.target.name]: e.target.checked}
        setAttentionDetail(newDetail)
      }else{
        var newDetail = {...attention_detail, [e.target.name]: value}
        setAttentionDetail(newDetail)
      }


  }
  //郵便番号検索
  const outoZip = (value) => {
    // alert(value)
    // 入力された値からハイフンを除去 全角除去
    const formattedPostalCode = value.replace(/-/g, '').replace(/[^\d-]/g, '');
    
    // ハイフンを挿入した形式にフォーマット
    let formattedValue = '';
    if (formattedPostalCode.length > 3) {
      formattedValue = `${formattedPostalCode.slice(0, 3)}-${formattedPostalCode.slice(3)}`;
    } else {
      formattedValue = formattedPostalCode;
    }

    // フォーマットされた郵便番号をセット
    return formattedValue;
  };
  //担当変更
  const onChangeVendorDetailUserId = async (e, value) => {

  // alert(value.id)
        var newDetail = {...attention_detail, tantou_user_id: value ? value.id : 0}
        setAttentionDetail(newDetail)

  }
  
  //登録ボタン
  const onClickUpdate = async () => {

    if(attention_detail.owner_id == ""){
      onClickInsertTmOwner()
    }else{
      onClickUpdateTmOwner()
    }

  }
  //新規追加
  const onClickInsertTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorinsert`, { 
      method: 'post', 
      body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      // setTatemonoList((prevState) => ([ ...prevState, new_data ]).sort(function (a: any, b: any) {
      //   if (a.bk_name > b.bk_name) return 1;
      //   if (a.bk_name < b.bk_name) return -1;
      //   return 0;
      // }));
      var insertdata = data
      // console.log("insertdata:", data)
   
      insertdata.section_name = attention_detail.tantou_section_id > 0 ? section_list.filter(a=>a.tenpo_no == attention_detail.tantou_section_id)[0].tenpo_name : ""
      insertdata.address = attention_detail.address
      insertdata.tel = (attention_detail.renrakusaki_shubetsu == "自宅電話" || attention_detail.renrakusaki_shubetsu == "携帯電話" || attention_detail.renrakusaki_shubetsu == "電話" || attention_detail.renrakusaki_shubetsu == "電話&FAX") ? attention_detail.tel_mail : ""
      // insertdata.mail = (vendor_detail.renrakusaki_shubetsu == "メール") ? vendor_detail.tel_mail :""
      insertdata.mail = attention_detail.mail


      setVendorList((prevState) => ([ insertdata, ...prevState ]));

      // console.log("vendorinsert",data)

      setAttentionDetailDisp(false)
    })
    .catch(error => console.error(error));
    

  }
  //更新
  const onClickUpdateTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorupdate`, { 
      method: 'post', 
      body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      var updatedata = data
      // console.log("insertdata:", data)
   
      updatedata.section_name = attention_detail.tantou_section_id > 0 ? section_list.filter(a=>a.tenpo_no == attention_detail.tantou_section_id)[0].tenpo_name : ""
      updatedata.address = attention_detail.address
      updatedata.tel = (attention_detail.renrakusaki_shubetsu == "自宅電話" || attention_detail.renrakusaki_shubetsu == "携帯電話" || attention_detail.renrakusaki_shubetsu == "電話" || attention_detail.renrakusaki_shubetsu == "電話&FAX") ? attention_detail.tel_mail : ""
      // updatedata.mail = (attention_detail.renrakusaki_shubetsu == "メール") ? vendor_detail.tel_mail :""
      updatedata.mail = attention_detail.mail

      // setVendorList((prevState) => ([ insertdata, ...prevState ]));

      setVendorList((prevDetail) => prevDetail.map((value, index) => (value.owner_id == attention_detail.owner_id ? updatedata : value)));

      // console.log("vendorinsert",data)

      setAttentionDetailDisp(false)
    })
    .catch(error => console.error(error));
    

  }

  //削除
  const onClickDeleteTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendordelete`, { 
      method: 'post', 
      body: JSON.stringify(attention_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      setVendorList((prevDetail) => prevDetail.filter((value, index) => (value.owner_id != attention_detail.owner_id)));

      setAttentionDetailDisp(false)
      setDeleteDisp(false)
    })
    .catch(error => console.error(error));
    

  }

  //詳細が変更されたときリストを修正
  const changeAttentionList = async (data, detail) => {
    // console.log(data, data)
    // console.log(detail, detail)
      var updatedata = data
   
      updatedata.tenpo_name = detail.section_id > 0 ? section_list.filter(a=>a.tenpo_no == detail.section_id)[0].tenpo_name : ""
      updatedata.nickname = detail.user_id > 0 ? user_list.filter(a=>a.id == detail.user_id)[0].user_name_all : ""



      setAttentionList((prevDetail) => prevDetail.map((value, index) => (value.id == updatedata.id ? updatedata : value)));



      // setAttentionList(data)
  }
  //新規登録されたときリストを修正
  const insertAttentionList = async (data, detail) => {
    // console.log(data, data)
    // console.log(detail, detail)
      var updatedata = data
   
      updatedata.tenpo_name = detail.section_id > 0 ? section_list.filter(a=>a.tenpo_no == detail.section_id)[0].tenpo_name : ""
      updatedata.nickname = detail.user_id > 0 ? user_list.filter(a=>a.id == detail.user_id)[0].user_name_all : ""

      setAttentionList((prevState) => ([ updatedata, ...prevState ]));

      // setAttentionList(data)
  }
  //削除されたときリストを修正
  const deleteAttentionList = async (detail) => {

      setAttentionList((prevDetail) => prevDetail.filter((value, index) => (value.id != detail.id)));

      // setAttentionList(data)
  }


  return (
    <>
    <div>
      <div>
        <Box sx={{backgroundColor:"white", p:2, width:window.innerWidth - 100}}>
          <TextField
                name='name'
                value={form.name}
                onChange={handleChange}
                label="名前"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{width:300}}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch(form)
                  }
                }}
          />
          <TextField
                name='tel'
                value={form.tel}
                onChange={handleChange}
                label="TEL"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{width:300, marginLeft:5}}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch(form)
                  }
                }}
          />
          <TextField
                name='mail'
                value={form.mail}
                onChange={handleChange}
                label="メール "
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{width:300, marginLeft:5}}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    handleSearch(form)
                  }
                }}
          />
          <FormGroup row style={{display: "inline-block", marginLeft:10}}>
              <FormControlLabel control={
                <Checkbox 
                  checked={form.nikkei_telecom} 
                  onChange={handleChange}
                  name="nikkei_telecom" />
                } label="日経テレコン登録あり" />
          </FormGroup>
          <Button 
            variant="contained"  
            style={{marginLeft: 20, marginTop:-5}} 
            // startIcon={<SaveAltIcon/>}
            onClick={()=>handleSearch(form)}
            size="small"
          >検索</Button>

          <CSVLink className="btn"
            filename={"信用情報.csv"}
            style={{ marginLeft: "5px" }} headers={headers} data={attention_list.length > 0 ? attention_list.map(row => ({...row, 
              nikkei_telecom: row.nikkei_telecom ? "あり" : "なし",
              }),this):""} >
            CSV
          </CSVLink>
          <Button 
            variant="contained"  
            style={{float:"right"}} 
            // startIcon={<SaveAltIcon/>}
            onClick={handleNewDetail}
            size="small"
            color="error"
          >新規登録</Button>
        </Box>
        {attention_list && <Box sx={{backgroundColor:"white", p:2, width:window.innerWidth - 100, paddingTop:0}}>
          {loading_disp ? <Loading />: <AttentionGrid data_list={attention_list} handleDetail={handleDetail} changeAttentionList={changeAttentionList} />}
        </Box>}

      </div>
    </div>

      <Dialog
        open={attention_detail_disp}
        // onClose={()=>{setAttentionDetailDisp(false);setAttentionDetail({owner_id: "", taxable:0 })}}
        fullWidth={true}
        maxWidth="xl"
      >

        <DialogTitle id="alert-dialog-title">
          詳細
          <IconButton aria-label="close" style={{position:'absolute', right:10, top:15}} onClick={()=>{setAttentionDetailDisp(false);}} >
                            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogActions>

        {/* {!detail_loading_disp && <Button 
          variant="contained"  
          style={{marginRight: 20}} 
          startIcon={<SaveAltIcon/>}
          onClick={onClickUpdate}
          disabled = {(attention_detail.owner_name == "" || attention_detail.owner_name == undefined)}
          size="small"
        >登録</Button>}

        {!detail_loading_disp && attention_detail.owner_id > 0 && <Button 
          variant="contained"  
          style={{marginRight: 20, backgroundColor:pink['500']}} 
          startIcon={<HighlightOffIcon/>}
          onClick={()=>setDeleteDisp(true)}
          size="small"
          
        >削除</Button>} */}

        {/* <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} >閉じる</Button> */}

        </DialogActions>
        <DialogContent >
          <AttentionDetail 
            id={attention_detail.id} 
            onChangeVendorDetail={onChangeVendorDetail} 
            onChangeVendorDetailUserId={onChangeVendorDetailUserId} 
            setAttentionDetail={setAttentionDetail} 
            setAttentionDetailDisp={setAttentionDetailDisp} 
            detail_loading_disp={detail_loading_disp} 
            changeAttentionList={changeAttentionList} 
            insertAttentionList={insertAttentionList}
            deleteAttentionList={deleteAttentionList}
          />

        </DialogContent>
        <DialogActions>

          {/* <Button 
            variant="contained" 
            disabled={new_data.tatemono_id == "" || (tatemono_list.filter(a=>a.bk_no == new_data.bk_no && a.tatemono_id == new_data.tatemono_id).length > 0)} 
            style={{marginRight: 10}} 
            startIcon={<AddCircleIcon/>}
            onClick={onClickInsertConvTk}
          >追加</Button>

          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} onClick={(e) => { setNewModal(false) }} startIcon={<HighlightOffIcon/>}>閉じる</Button> */}

        </DialogActions>
      </Dialog>

      <Dialog
        open={delete_disp}
        onClose={()=>setDeleteDisp(false)}
        // fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          本当に削除してよろしいですか？
        </DialogTitle>
        <Divider />
        
        <DialogActions>

          
          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:pink['600']}} onClick={onClickDeleteTmOwner} startIcon={<DeleteForeverIcon/>}>削除</Button>

          <Button variant="contained" style={{ marginLeft: 6, backgroundColor:grey['300'], color:"black"}} onClick={()=>setDeleteDisp(false)} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>
      

      
    </>


  );
}



export default Index;
