import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import VendorGrid from './Grid.tsx';
import VendorDetail from './Detail';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Loading from './Loading';
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { purple, red, grey, blue, lightBlue,pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Divider from '@mui/material/Divider';
import {UserContext,} from '../Layout/IndexComponents'

import ja from 'date-fns/locale/ja'

// import logo from './logo.svg';
import './Css/SearchList.css';

const authentication = require('../../react-azure-adb2c2.js').default;

// const HttpsProxyAgent = require('https-proxy-agent');

// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };
// const url = process.env.REACT_APP_API_PM_URL
// const url = `http://10.0.2.2:8080`

const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`


function Index(props) {
  const { instance, inProgress, accounts } = useMsal();

  const { user_detail } = useContext(UserContext);


  const [year_list, setYearList] = useState([]);//

  const [nyukin_date, setNyukinDate] = useState(parseInt(moment().format("YYYY")));

  const [tatemono_name, setTatemonoName] = useState("");
  const [keiyakusya_name, setKeiyakusyaName] = useState("");
  
  const [data_list, setDataList] = useState(null);
  const [loading_disp, setLoadingDisp] = useState(false);//


  const [form, setForm] = useState({count:50, vendor_name:"", taxable:null, tantou_section_id: 0, annai: false});//検索フォーム
  const [section_list, setSectionList] = useState([]);//
  const [vendor_list, setVendorList] = useState([]);//
  const [vendor_list_count, setVendorListCount] = useState([]);//
  const [vendor_detail_disp, setVendorDetailDisp] = useState(false);//
  const [vendor_detail, setVendorDetail] = useState({owner_id: "", taxable:0 });//
  const [detail_loading_disp, setDetailLoadingDisp] = useState(false);//
  const [delete_disp, setDeleteDisp] = useState(false);//

  useEffect(() => {

    getSectionListData()
    handleSearch(form)
    
  }, []);

  const handleChange = async (e) => {

    if(e.target.name == "annai"){
      var newDetail = {...form, [e.target.name]: e.target.checked}
      setForm(newDetail)
    }else{
      var newDetail = {...form, [e.target.name]: e.target.value}
      setForm(newDetail)
    }
  }
  const handleChangeSearch = async (e) => {

    if(e.target.name == "annai"){
      var newDetail = {...form, [e.target.name]: e.target.checked}
      setForm(newDetail)
      handleSearch(newDetail)

    }else{
      var newDetail = {...form, [e.target.name]: e.target.value}
      setForm(newDetail)
      handleSearch(newDetail)
    }

    
  }

  const getSectionListData = async () => {

    // setTmTatemonoListState(1)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/sectionlist`, { 
      method: 'get', 
      // body: JSON.stringify(),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      // alert(data.length)
      setSectionList(data)
      // console.log(data)
    })
    .catch(error => console.error(error));

    // setTmTatemonoListState(0)

  }



  const getData = async (form) => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorlist`, { 
      method: 'post', 
      body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setVendorList(data.list)
      setVendorListCount(data.count ? data.count : 0)
      // console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }

  //検索
  const handleSearch = async (form) => {

    getData(form)

  }

  const getExcel = async () => {

    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    const values = new URLSearchParams();
    values.append('start_date', moment(nyukin_date+"/01/01").startOf("year").format("YYYY-MM-DD"));
    values.append('end_date', moment(nyukin_date+"/01/01").endOf("year").format("YYYY-MM-DD"));
    values.append('tatemono_name', tatemono_name);
    values.append('keiyakusya_name', keiyakusya_name);
    await fetch(url+`/sqmeisaiexcel`, { 
      method: 'post', 
      body: values,
      headers: new Headers({
        'Authorization': 'Bearer ' + token, 
        'Accept': 'application/json',
    })
    })
    .then(response => response.blob())
    .then(images => {

      // // Then create a local URL for that image and print it 
      // bloburl_tmp = URL.createObjectURL(images)
      // // alert(bloburl)
      // console.log(bloburl_tmp)
      // setBloburl(bloburl_tmp)


      const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = "ゼロ９システム料振替分.xlsx";
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(images);
        // ダウンロード開始
        a.click();

  });
    // .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    // .catch(error => ({ error }));
    // .then(response => response.json())
    // .then(data => {
    //   setDataList(data)
    //   console.log(data)
    // })
    // .catch(error => console.error(error));

  }

  //新規
  const handleNewDetail = async () => {

    setVendorDetail({owner_id: "", taxable:0, is_dm: true, tantou_section_id: user_detail.am_section_id, tantou_user_id: user_detail.id })
    setVendorDetailDisp(true)

  }

  //詳細取得
  const handleDetail = async (owner_id) => {

    getDetailData(owner_id)
    // setVendorDetail({owner_id: "", taxable:0 })
    setVendorDetailDisp(true)

  }


  const getDetailData = async (owner_id) => {

    setDetailLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendordetail/`+owner_id, { 
      method: 'get', 
      // body: JSON.stringify(form),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {

      var detail = data.tm_owner[0]
      if(data.t_owner_renrakusaki.length > 0){
        for(let i=0; i < data.t_owner_renrakusaki.length; i++){
          if(data.t_owner_renrakusaki[i].shubetsu == "メール"){
            detail.mail = data.t_owner_renrakusaki[i].tel_mail
          }else{
            detail.renrakusaki_shubetsu = data.t_owner_renrakusaki[i].shubetsu
            detail.tel_mail = data.t_owner_renrakusaki[i].tel_mail
            detail.renrakusaki_bikou = data.t_owner_renrakusaki[i].bikou
          }
        }
      }
      if(data.t_owner_address.length > 0){
        detail.zip = data.t_owner_address[0].zip_1 != "" && data.t_owner_address[0].zip_2 != "" ? data.t_owner_address[0].zip_1 + "-" + data.t_owner_address[0].zip_2 : ""
        detail.address = data.t_owner_address[0].todoufuken + data.t_owner_address[0].shikuchoson + data.t_owner_address[0].choiki + data.t_owner_address[0].other_address
        detail.address_shubetsu = data.t_owner_address[0].shubetsu
        detail.is_dm = data.t_owner_address[0].is_dm
      }

      setVendorDetail(detail)
      setVendorDetailDisp(true)


      // console.log("detaill", detail)
    })
    .catch(error => console.error(error));

    setDetailLoadingDisp(false)
  }

  //詳細変更
  const onChangeVendorDetail = async (e) => {
// alert(e.target.value)

      var value = e.target.value
      if(e.target.name == "zip"){
        value = outoZip(value)
      }
      
      if(e.target.name == "is_dm"){
        var newDetail = {...vendor_detail, [e.target.name]: e.target.checked}
        setVendorDetail(newDetail)
      }else{
        var newDetail = {...vendor_detail, [e.target.name]: value}
        setVendorDetail(newDetail)
      }


  }
  //郵便番号検索
  const outoZip = (value) => {
    // alert(value)
    // 入力された値からハイフンを除去 全角除去
    const formattedPostalCode = value.replace(/-/g, '').replace(/[^\d-]/g, '');
    
    // ハイフンを挿入した形式にフォーマット
    let formattedValue = '';
    if (formattedPostalCode.length > 3) {
      formattedValue = `${formattedPostalCode.slice(0, 3)}-${formattedPostalCode.slice(3)}`;
    } else {
      formattedValue = formattedPostalCode;
    }

    // フォーマットされた郵便番号をセット
    return formattedValue;
  };
  //担当変更
  const onChangeVendorDetailUserId = async (e, value) => {

  // alert(value.id)
        var newDetail = {...vendor_detail, tantou_user_id: value ? value.id : 0}
        setVendorDetail(newDetail)

  }
  
  //登録ボタン
  const onClickUpdate = async () => {

    if(vendor_detail.owner_id == ""){
      onClickInsertTmOwner()
    }else{
      onClickUpdateTmOwner()
    }

  }
  //新規追加
  const onClickInsertTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorinsert`, { 
      method: 'post', 
      body: JSON.stringify(vendor_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      // setTatemonoList((prevState) => ([ ...prevState, new_data ]).sort(function (a: any, b: any) {
      //   if (a.bk_name > b.bk_name) return 1;
      //   if (a.bk_name < b.bk_name) return -1;
      //   return 0;
      // }));
      var insertdata = data
      // console.log("insertdata:", data)
   
      insertdata.section_name = vendor_detail.tantou_section_id > 0 ? section_list.filter(a=>a.tenpo_no == vendor_detail.tantou_section_id)[0].tenpo_name : ""
      insertdata.address = vendor_detail.address
      insertdata.tel = (vendor_detail.renrakusaki_shubetsu == "自宅電話" || vendor_detail.renrakusaki_shubetsu == "携帯電話" || vendor_detail.renrakusaki_shubetsu == "電話" || vendor_detail.renrakusaki_shubetsu == "電話&FAX") ? vendor_detail.tel_mail : ""
      // insertdata.mail = (vendor_detail.renrakusaki_shubetsu == "メール") ? vendor_detail.tel_mail :""
      insertdata.mail = vendor_detail.mail


      setVendorList((prevState) => ([ insertdata, ...prevState ]));

      // console.log("vendorinsert",data)

      setVendorDetailDisp(false)
    })
    .catch(error => console.error(error));
    

  }
  //更新
  const onClickUpdateTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendorupdate`, { 
      method: 'post', 
      body: JSON.stringify(vendor_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      var updatedata = data
      // console.log("insertdata:", data)
   
      updatedata.section_name = vendor_detail.tantou_section_id > 0 ? section_list.filter(a=>a.tenpo_no == vendor_detail.tantou_section_id)[0].tenpo_name : ""
      updatedata.address = vendor_detail.address
      updatedata.tel = (vendor_detail.renrakusaki_shubetsu == "自宅電話" || vendor_detail.renrakusaki_shubetsu == "携帯電話" || vendor_detail.renrakusaki_shubetsu == "電話" || vendor_detail.renrakusaki_shubetsu == "電話&FAX") ? vendor_detail.tel_mail : ""
      // updatedata.mail = (vendor_detail.renrakusaki_shubetsu == "メール") ? vendor_detail.tel_mail :""
      updatedata.mail = vendor_detail.mail

      // setVendorList((prevState) => ([ insertdata, ...prevState ]));

      setVendorList((prevDetail) => prevDetail.map((value, index) => (value.owner_id == vendor_detail.owner_id ? updatedata : value)));

      // console.log("vendorinsert",data)

      setVendorDetailDisp(false)
    })
    .catch(error => console.error(error));
    

  }

  //削除
  const onClickDeleteTmOwner = async () => {

    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/vendordelete`, { 
      method: 'post', 
      body: JSON.stringify(vendor_detail),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      
      setVendorList((prevDetail) => prevDetail.filter((value, index) => (value.owner_id != vendor_detail.owner_id)));

      setVendorDetailDisp(false)
      setDeleteDisp(false)
    })
    .catch(error => console.error(error));
    

  }
  

  return (
    <>
    <div>
      <div style={{display:"flex"}}>
        <Box sx={{backgroundColor:"white", p:1, minWidth: "200px", maxWidth: "200px", height: window.innerHeight - 70}}>

          <Grid container spacing={{lg:2,xs:1,}} justifyContent="center">
            <Grid item xs={12}>
              <Button variant="contained" sx={{}} size='small' onClick={handleNewDetail} startIcon={<AddCircleOutlineIcon />} >協力業者新規登録</Button>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <InputLabel size='small' id="From-select-label">表示件数</InputLabel>
                <Select
                  labelId="From-select-label"
                  name="count"
                  value={form.count}
                  label="表示件数"
                  size="small"
                  onChange={(e)=>handleChangeSearch(e)}
                >
                  {/* <MenuItem value={5}>5件</MenuItem>
                  <MenuItem value={10}>10件</MenuItem>
                  <MenuItem value={15}>15件</MenuItem> */}
                  <MenuItem value={50}>50件</MenuItem>
                  <MenuItem value={100}>100件</MenuItem>
                  <MenuItem value={500}>500件</MenuItem>
                  <MenuItem value={1000}>1000件</MenuItem>
                  <MenuItem value={2000}>2000件</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} style={{paddingLeft:10}}>
              <InputLabel style={{fontSize:10}}>合計</InputLabel>
              {vendor_list_count}件
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={()=>handleSearch(form)} style={{backgroundColor:"#007bff"}} size='small' color="primary" startIcon={<SearchIcon />} >検索</Button>
              {/* <Button variant="contained" style={{backgroundColor:grey['300'], marginLeft:10, color:"black"}} size='small' color="secondary" startIcon={<DownloadIcon />} >CSV</Button> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='vendor_name'
                value={form.vendor_name}
                onChange={(e)=>handleChange(e)}
                label="業者名"
                size="small"
                // style={{marginLeft:5}}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                      // エンターキー押下時の処理
                      handleSearch(form)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size='small' id="From-select-label">インボイス課税区分</InputLabel>
                <Select
                  labelId="From-select-label"
                  name="taxable"
                  value={form.taxable}
                  label="インボイス課税区分"
                  size="small"
                  onChange={(e)=>handleChangeSearch(e)}
                >
                  <MenuItem value={null}>-</MenuItem>
                  <MenuItem value={0}>未確認(問い合わせ中)</MenuItem>
                  <MenuItem value={1}>課税事業者</MenuItem>
                  <MenuItem value={2}>免税事業者</MenuItem>
                  <MenuItem value={3}>課税事業者で簡易課税制度</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size='small' id="From-select-label">GL担当部署</InputLabel>
                <Select
                  labelId="From-select-label"
                  name="tantou_section_id"
                  value={form.tantou_section_id}
                  label="GL担当部署"
                  size="small"
                  onChange={(e)=>handleChangeSearch(e)}
                >
                  <MenuItem value={0}>-</MenuItem>
                  {section_list.length > 0 && section_list.map((value,index) => <MenuItem value={value.tenpo_no}>{value.tenpo_name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={form.annai} 
                      onChange={(e)=>handleChangeSearch(e)}
                      name="annai" />
                    } label="案内送付先登録あり" />
              </FormGroup>
            </Grid>
          </Grid>

        </Box>
        <Box sx={{ml:1,backgroundColor:"white", p:1, flexGrow:1, height: window.innerHeight - 70}}>
          {vendor_list && <Box sx={{mt: 1, p:0, backgroundColor:"white"}}>
          {loading_disp ? <Loading />: <VendorGrid data_list={vendor_list} handleDetail={handleDetail} />}
          
          </Box>} 
        </Box>
      </div>
    </div>

      <Dialog
        open={vendor_detail_disp}
        // onClose={()=>{setVendorDetailDisp(false);setVendorDetail({owner_id: "", taxable:0 })}}
        fullWidth={true}
        maxWidth="xl"
      >

        <DialogTitle id="alert-dialog-title">
          詳細
          <IconButton aria-label="close" style={{position:'absolute', right:10, top:15}} onClick={()=>{setVendorDetailDisp(false);setVendorDetail({owner_id: "", taxable:0 })}} >
                            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogActions>

        {!detail_loading_disp && <Button 
          variant="contained"  
          style={{marginRight: 20}} 
          startIcon={<SaveAltIcon/>}
          onClick={onClickUpdate}
          disabled = {(vendor_detail.owner_name == "" || vendor_detail.owner_name == undefined)}
          size="small"
        >登録</Button>}

        {!detail_loading_disp && vendor_detail.owner_id > 0 && <Button 
          variant="contained"  
          style={{marginRight: 20, backgroundColor:pink['500']}} 
          startIcon={<HighlightOffIcon/>}
          onClick={()=>setDeleteDisp(true)}
          size="small"
          
        >削除</Button>}

        {/* <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} >閉じる</Button> */}

        </DialogActions>
        <DialogContent >
          <VendorDetail vendor_detail={vendor_detail} onChangeVendorDetail={onChangeVendorDetail} onChangeVendorDetailUserId={onChangeVendorDetailUserId} setVendorDetail={setVendorDetail} detail_loading_disp={detail_loading_disp} />

        </DialogContent>
        <DialogActions>

          {/* <Button 
            variant="contained" 
            disabled={new_data.tatemono_id == "" || (tatemono_list.filter(a=>a.bk_no == new_data.bk_no && a.tatemono_id == new_data.tatemono_id).length > 0)} 
            style={{marginRight: 10}} 
            startIcon={<AddCircleIcon/>}
            onClick={onClickInsertConvTk}
          >追加</Button>

          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:grey['300'], color:"black"}} onClick={(e) => { setNewModal(false) }} startIcon={<HighlightOffIcon/>}>閉じる</Button> */}

        </DialogActions>
      </Dialog>

      <Dialog
        open={delete_disp}
        onClose={()=>setDeleteDisp(false)}
        // fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle id="alert-dialog-title">
          本当に削除してよろしいですか？
        </DialogTitle>
        <Divider />
        
        <DialogActions>

          
          <Button variant="contained" style={{ marginLeft: 3, backgroundColor:pink['600']}} onClick={onClickDeleteTmOwner} startIcon={<DeleteForeverIcon/>}>削除</Button>

          <Button variant="contained" style={{ marginLeft: 6, backgroundColor:grey['300'], color:"black"}} onClick={()=>setDeleteDisp(false)} startIcon={<HighlightOffIcon/>}>閉じる</Button>

        </DialogActions>
      </Dialog>
      

      
    </>


  );
}



export default Index;
