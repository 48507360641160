import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment, { weekdays } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };

type Post = {


  ky_date: number;
  kai_ymd: number;
  keirui_name: string;
  kys_name: string;
  nkin_ymd: number;
  sq_gak: string;
  sq_zeigak: string;


  bk_no: number;
  bk_name: string;

  tatemono_id: number;
  tatemono_name: string;


  
  
};

function Grid(props) {
  // console.log(props,"props")
  const [posts, setPosts] = useState<Post[]>([]);

  const columnHelper = createColumnHelper<Post>();


  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange')

  const [sorting, setSorting] = useState<SortingState>([]);


    // 左サイドに固定されている列の幅
    var header_width1 = 0;
    var header_width2 = 0;
    var header_width3 = 0;
    var header_width4 = 0;
    var header_width5 = 0;

    var cell_width1 = 0;
    var cell_width2 = 0;
    var cell_width3 = 0;
    var cell_width4 = 0;
    var cell_width5 = 0;
    var footer_width1 = 0;
    var footer_width2 = 0;
    var footer_width3 = 0;
    var footer_width4 = 0;
    var footer_width5 = 0;
  

  useEffect(() => {
    setPosts(props.data_list)
  }, [props.data_list]);


  // テーブルセルのカスタマイズ
  const columns = [

    columnHelper.accessor('bk_no', {
      header: '物件No',
      id:'bk_no',
      // sortDescFirst: true, //  最初のクリックで降順(デフォルトは昇順)
      enableSorting: true, // 列のソート無効
      size: 270,
      // maxSize: 900,
    }),

    columnHelper.accessor('bk_name', {
      header: '物件名',
      id:'bk_name',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 70,
      
    }),

    columnHelper.accessor('kys_name', {
      header: '契約者',
      id:'kys_name',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 130,
    }),
    columnHelper.accessor('ky_date', {
      header: '契約日',
      id:'ky_date',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 135,
      cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    columnHelper.accessor('kai_ymd', {
      header: '解約日',
      id:'kai_ymd',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 135,
      cell: (props) => props.getValue() && moment(props.getValue()).format("YYYY年MM月DD日"),
    }),
    columnHelper.accessor('keirui_name', {
      header: '契約形態',
      id:'keirui_name',
      // enableResizing: false, 
      enableSorting: true, // 列のソート無効
      size: 120,
    }),

  


  ];


  const table = useReactTable({
    data: posts,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(), //  data, columns, getCoreRowModelは必須
    // getPaginationRowModel: getPaginationRowModel(), //  ページネーション：デフォルトでは10件表示
    getSortedRowModel: getSortedRowModel(), // 並び替えに必要
    columnResizeMode, // 列幅を可変に。
    // columnResizeDirection,
    
  });

  function headerLeft1(size) {
    header_width1 = size;
    return 0;
  }
  function headerLeft2(size) {
    header_width2 = size;
    return header_width1;
  }
  function headerLeft3(size) {
    header_width3 = size;
    return header_width1 + header_width2;
  }
  function headerLeft4(size) {
    header_width4 = size;
    return header_width1 + header_width2 + header_width3;
  }
  function headerLeft5(size) {
    header_width5 = size;
    return header_width1 + header_width2 + header_width3 + header_width4;
  }
  function headerLeft6() {
    return header_width1 + header_width2 + header_width3 + header_width4 + header_width5;
  }

  function headerBaseLeft(size) {
    return 0;
  }

  function cellLeft1(size) {
    cell_width1 = size;
    return 0;
  }
  function cellLeft2(size) {
    cell_width2 = size;
    return cell_width1;
  }
  function cellLeft3(size) {
    cell_width3 = size;
    return cell_width1 + cell_width2;
  }
  function cellLeft4(size) {
    cell_width4 = size;
    return cell_width1 + cell_width2 + cell_width3;
  }
  function cellLeft5(size) {
    cell_width5 = size;
    return cell_width1 + cell_width2 + cell_width3 + cell_width4;
  }
  function cellLeft6() {
    return cell_width1 + cell_width2 + cell_width3 + cell_width4 + cell_width5;
  }

  function footerLeft1(size) {
    footer_width1 = size;
    return 0;
  }
  function footerLeft2(size) {
    footer_width2 = size;
    return footer_width1;
  }
  function footerLeft3(size) {
    footer_width3 = size;
    return footer_width1 + footer_width2;
  }
  function footerLeft4(size) {
    footer_width4 = size;
    return footer_width1 + footer_width2 + footer_width3;
  }
  function footerLeft5(size) {
    footer_width5 = size;
    return footer_width1 + footer_width2 + footer_width3 + footer_width4;
  }
  function footerLeft6() {
    return footer_width1 + footer_width2 + footer_width3 + footer_width4 + footer_width5;
  }
  function footerBaseLeft(size) {
    return 0;
  }
  
  return (
    <div>

      <div className='tablebox' style={{height: window.innerHeight-50-105-60}}>
        <div>
        <table className='searchList'
          style={{borderSpacing: 0, width:'max-content',fontSize:"15px", }}
        >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th  colSpan={header.colSpan}
                  {...{
                    key: header.id,
                    // colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),  // 列幅を反映
                      // position: (header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date") ? 'sticky' : 'relative',
                      left: header.id === "1_base_bk_name" ? headerBaseLeft(header.getSize()) : header.id === "bk_name" ? headerLeft1(header.getSize()) : header.id === "hy_no" ? headerLeft2(header.getSize()) : header.id === "kys_name" ? headerLeft3(header.getSize()) : header.id === "ky_date" ? headerLeft4(header.getSize()) : header.id === "kai_ymd" ? headerLeft5(header.getSize()) : header.id === "keirui_name" ? headerLeft6() : '',
                      backgroundColor: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 'white' : 'none',
                      zIndex: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name" || header.id === "base") ? 2 : 1,
                    },
                    
                  }}
                  >

                    {/* {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                      
                    )} */}
                    
                    <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                      asc: ' ▲',   // 昇順
                      desc: ' ▼',  // 降順
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    
                    {/* <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        // onClick: () => header.column.getToggleSortingHandler(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }`,
                      }}
                    />
                    {header.column.getCanSort() ? <button onClick={header.column.getToggleSortingHandler()}>
              
                    {{
                      asc: '▲',   // 昇順
                      desc: '▼',  // 降順
                    }
                      // 並び替えが行われてない場合
                      [header.column.getIsSorted() as string] ?? ''
                    }
                    </button> : "" }
                     */}


                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* sliceで表示する行数の設定
            {table.getRowModel().rows.slice(0, 5).map((row) => ( */}
            {table.getRowModel().rows.map((rowValue, rowIndex) => (

              



              <tr 
                {...{
                  key: rowValue.id,
                  style: {
                    // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                    // backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f0'
                  },
                }}
              >
                {rowValue.getVisibleCells().map((value, index) => (
                  <td
                    {...{
                      key: value.id,
                      style: {
                        width: value.column.getSize(),
                        textAlign: (value.id.includes("Tatemono_Name") || value.id.includes("Address"))?"left":"center",
                        position: value.id.includes("bk_name") || value.id.includes("hy_no") || value.id.includes("kys_name") || value.id.includes("ky_date") || value.id.includes("kai_ymd") || value.id.includes("keirui_name") ? 'sticky' : 'unset',
                        left: value.id.includes("_bk_name") ? cellLeft1(value.column.getSize()) : value.id.includes("_hy_no") ? cellLeft2(value.column.getSize()) : value.id.includes("_kys_name") ? cellLeft3(value.column.getSize()) : value.id.includes("_ky_date") ? cellLeft4(value.column.getSize()) : value.id.includes("_kai_ymd") ? cellLeft5(value.column.getSize()) : value.id.includes("keirui_name") ? cellLeft6() : '',
                        // backgroundColor: index % 2 == 0 ? 'white' : 'yellow',
                        // backgroundColor:'white'
                        backgroundColor: rowIndex % 2 === 0 ? 'white' : '#e2f0f3'
                      },
                    }}
                  >
                    {flexRender(value.column.columnDef.cell, value.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}
                {...{
                  style: {
                    height: 50,
                    // position: (header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date") ? 'sticky' : 'relative',
                    left: header.id === "1_base_bk_name" ? footerBaseLeft(header.getSize()) : header.id === "bk_name" ? footerLeft1(header.getSize()) : header.id === "hy_no" ? footerLeft2(header.getSize()) : header.id === "kys_name" ? footerLeft3(header.getSize()) : header.id === "ky_date" ? footerLeft4(header.getSize()) : header.id === "kai_ymd" ? footerLeft5(header.getSize()) : header.id === "keirui_name" ? footerLeft6() : '',
                    backgroundColor: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 'white' : 'none',
                    zIndex: (header.id === "1_base_bk_name" || header.id === "bk_name" || header.id === "hy_no" || header.id === "kys_name" || header.id === "ky_date" || header.id === "kai_ymd" || header.id === "keirui_name") ? 2 : 1,
                  },
                }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
        </table>
        </div>
      </div>


    </div>


  );
}



export default Grid;
