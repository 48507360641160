import React from 'react';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode, SortingState} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { GetAccessToken } from '../../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// import logo from './logo.svg';
import './Css/SearchList.css';



// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };


const url = `https://go.homestation.jp`
// const url = `http://localhost:8080`

function Tatemono(props) {

  const { instance, inProgress, accounts } = useMsal();

  const [detail, setDetail] = useState({});//
  const [loading_disp, setLoadingDisp] = useState(false);//
  const [hy_list, setHyList] = useState([]);//
  const [tm_parking_area_list, setTmParkingAreaList] = useState([]);//



  const getConvRoomData = async (detail) => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 
// alert(detail.bk_no)
    await fetch(url+`/convtkhyplist`, { 
      method: 'post', 
      body: JSON.stringify({bk_no: detail.bk_no, tatemono_id: detail.tatemono_id}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setHyList(data)
      // alert(data.length)
      console.log("setHyList",data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }

  const getHsParkingAreaData = async (detail) => {

    setLoadingDisp(true)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 
// alert(detail.bk_no)
    await fetch(url+`/convtktmparkinglist`, { 
      method: 'post', 
      body: JSON.stringify({tatemono_id: detail.tatemono_id}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setTmParkingAreaList(data)
      console.log(data)
    })
    .catch(error => console.error(error));

    setLoadingDisp(false)
  }

  useEffect(() => {
    console.log("detaillllllllllllllll", detail)
    setDetail(props.detail)
    getConvRoomData(props.detail)
    getHsParkingAreaData(props.detail)
  }, [props.detail]);

  //ここから
  const ConvDelete = async (hy_no, room_id) => {
    var token = await GetAccessToken({ instance, inProgress, accounts }); 
// alert(detail.bk_no)
// alert(hy_no)
// alert(detail.tatemono_id)
// alert(room_id)
    await fetch(url+`/convtkhydelete`, { 
      method: 'put', 
      body: JSON.stringify({bk_no: detail.bk_no, hy_no: hy_no, tatemono_id: detail.tatemono_id, room_id: room_id}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setHyList((prevDetail) => prevDetail.map((value, index) => (value.bk_no == detail.bk_no && value.hy_no == hy_no ? {...value, tatemono_id:"", room_id:"", room_name:""} : value)));
      console.log(data)
    })
    .catch(error => console.error(error));
  }

  const ReConv = async (hy_no, room_id) => {
    // alert(room_id)
    var token = await GetAccessToken({ instance, inProgress, accounts }); 

    await fetch(url+`/convtkhyinsert`, { 
      method: 'put', 
      body: JSON.stringify({bk_no: detail.bk_no, hy_no: hy_no, tatemono_id: detail.tatemono_id, room_id: room_id}),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
    .then(response => response.json())
    .then(data => {
      setHyList((prevDetail) => prevDetail.map((value, index) => (value.bk_no == detail.bk_no && value.hy_no == hy_no ? {...value, tatemono_id:data.tatemono_id, room_id:data.room_id, room_name:""} : value)));
      console.log(data)
    })
    .catch(error => console.error(error));
    
  }
  return (
    <div>
{/* 
    {tatemono_list.length > 0 && tatemono_list.map((detail,index) =>  */}

      <table className='conv_table'>
        {/* <caption>Sample Table</caption> */}
        <colgroup style={{ width: "20%" }} />
        <colgroup style={{ width: "40%" }} />
        <colgroup style={{ width: "40%" }} />
        <thead>
          <tr>
            <th ></th>
            <th className='tinkaku_title'>賃貸革命</th>
            <th className='hs_title'>HomeStation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='conv_table_title'>物件ID</td>
            <td>{detail.bk_no}</td>
            <td>{detail.tatemono_id}</td>
          </tr>
          <tr>
            <td className='conv_table_title'>物件名</td>
            <td>{detail.bk_name}</td>
            <td>{detail.tatemono_name}</td>
          </tr>
          <tr>
            <td className='conv_table_title'>物件カナ</td>
            <td>{detail.bk_kana}</td>
            <td>{detail.tatemono_kana}</td>
          </tr>
          <tr>
            <td className='conv_table_title'>住所</td>
            <td>{detail.post} {detail.add_cyo}{detail.add_banti}</td>
            <td>{detail.zip_1}{detail.zip_2} {detail.choiki}{detail.other_address}</td>
          </tr>

        </tbody>
      </table>


      <table className='conv_table' style={{marginTop:10}}>
          <colgroup style={{ width: "20%" }} />
          <colgroup style={{ width: "20%" }} />
          <colgroup style={{ width: "60%" }} />

          <thead>
            <tr>
              <th className='tinkaku_title'>部屋名</th>
              <th className='hs_title'>HS　部屋名</th>
              <th className='tinkaku_title'>間取り</th>
              {/* <th style={{backgroundColor:"#df3f5a", color:"white" }}>間取り詳細</th>
              <th style={{backgroundColor:"#df3f5a", color:"white" }}>面積</th> */}
            </tr>
          </thead>
          <tbody>

          {hy_list.length > 0 && hy_list.filter(a=>a.crui_no == 19).map((hy,index) =>

              <tr>
                <td>{hy.hy_no}</td>
                <td style={{display: "flex", backgroundColor: hy.room_id && tm_parking_area_list.length > 0 && tm_parking_area_list.filter(a => a.pa_id == hy.room_id).length > 0 && hy.hy_no.trim() == tm_parking_area_list.filter(a => a.pa_id == hy.room_id)[0].pa_name ? "#fffce0" : ""}}>
                  {tm_parking_area_list.length > 0 ?
                  <>
                  {hy.room_id !== "" &&<IconButton onClick={()=>ConvDelete(hy.hy_no, hy.room_id)}><HighlightOffIcon /></IconButton>}
                  {/* {hy.room_name} */}
                  <FormControl>
                    {/* <InputLabel id="From-select-label">地方</InputLabel> */}
                    <Select
                      labelId="From-select-label"
                      id="From-select"
                      value={hy.room_id}
                      label="From"
                      size="small"
                      onChange={(e)=>ReConv(hy.hy_no, e.target.value,)}
                    >
                      {tm_parking_area_list.map((value,index) => <MenuItem value={value.pa_id}>{value.pa_name}</MenuItem>)}
                      

                    </Select>
                  </FormControl>
                  </>:""}
                </td>

                <td>{hy.madori}</td>
                {/* <td>{hy.madoriu_all}</td>
                <td>{hy.men}</td> */}
              </tr>

          )}
        </tbody>

      </table>


    </div>


  );
}



export default Tatemono;
